import { DestroyRef, inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AudioCallComponent } from './audio-call.component';
import { Chat } from 'app/modules/admin/apps/chat/chat.types';
import { BehaviorSubject, Observable, map, take, tap, takeUntil, filter } from 'rxjs';
import { Ack, Bye, Cancel, Invitation, InvitationAcceptOptions, Inviter, InviterOptions, Registerer, Session,
    SessionState, UserAgent, UserAgentOptions, Web, } from 'sip.js';
import { IncomingRequestMessage } from 'sip.js/lib/core';
import {
    AudioCallState,
    AudioCallType,
    CallActivity,
    CallContact,
    CallMediaStreams,
    ContactResponse,
} from './audio-call.types';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@environments/environment';
import { HttpClientService } from '../../services/http-client.service';
import { DateTime } from 'luxon';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IntegrationsService } from 'app/modules/admin/apps/developers/integrations/integrations.service';
import {
    Integration,
    IntegrationSubType,
    IntegrationType,
} from 'app/modules/admin/apps/developers/integrations/integrations.types';
import { WsMessagesService } from '../../services/ws-messages.service';
import { BinotelWSResponseType, WsIncomeMessage } from '../../global.types';
import { Overlay } from '@angular/cdk/overlay';
import _ from 'lodash';


@Injectable({ providedIn: 'root' })
export class AudioCallService {
    user: User;
    callStartTime: number
    callEndTime: number
    userTelephonyIntegration: Integration | null = null
    private _userAgent: UserAgent;
    private _incomingSession: Invitation = {} as Invitation;
    private _outgoingSession: Inviter;
    private _callStatus: BehaviorSubject<SessionState> = new BehaviorSubject(SessionState.Initial);
    private _callSession = new BehaviorSubject<Invitation | Inviter>(null);
    private _callStreams = new BehaviorSubject<CallMediaStreams>(null);
    private _contact = new BehaviorSubject<CallContact>(null);
    private _phoneRingAudio: HTMLAudioElement;
    private _outgoingToneAudio: HTMLAudioElement;
    private destroyRef = inject(DestroyRef);

    get telephonyIntegrationType() {
        return this.userTelephonyIntegration?.integrationSubType
    }

    get callSession$(): Observable<Session> {
        return this._callSession.asObservable();
    }

    get callStreams$(): Observable<CallMediaStreams> {
        return this._callStreams.asObservable();
    }

    get contact$(): Observable<CallContact> {
        return this._contact.asObservable();
    }

    get callStatus$(): Observable<AudioCallState> {
        return this._callStatus.asObservable().pipe(
            map(status => {
                switch (status) {
                    case SessionState.Initial:
                        return 'initialCallState';
                    case SessionState.Establishing:
                        return 'establishingCallState';
                    case SessionState.Established:
                        return 'establishedCallState';
                    case SessionState.Terminating:
                        return 'terminatingCallState';
                    case SessionState.Terminated:
                        return 'terminatedCallState';
                }
            }),
        );
    }

    constructor(
        private _dialog: MatDialog,
        private _overlay: Overlay,
        private _fuseConfirmationService: FuseConfirmationService,
        private _transloco: TranslocoService,
        private _httpClient: HttpClientService,
        private _userService: UserService,
        private _integrations: IntegrationsService,
        private _wsService: WsMessagesService
    ) {
        this._userService.user$
            .pipe((takeUntilDestroyed(this.destroyRef)))
            .subscribe((user: User) =>
                {
                    this.user = user;
                    this._integrations.getIntegrations$(IntegrationType.telephony).subscribe( integrations => {

                        this.userTelephonyIntegration = integrations
                            .find(integration => (integration.access.findIndex(accessUser => String(accessUser.id) === String(user.id)) !== -1));

                        switch (this.telephonyIntegrationType) {
                            case IntegrationSubType.binotel:
                                this.initBinotel();
                                break;
                            case IntegrationSubType.asterisk:
                                this.getIPConnection(this.userTelephonyIntegration.id).subscribe(() => {
                                    this.initAsterisk();
                                })
                                break
                        }
                    })
                });
    }

    set contact(contact: CallContact)
    {
        this._contact.next(contact)
    }

    getContactByPhoneNumber(phoneNumber: string): Observable<ContactResponse>
    {
        const originURL = window.location.origin;
        return this._httpClient.get<ContactResponse>('/api/getInfoByPhone', {phone: phoneNumber , url: originURL})
            .pipe(
                tap((contact) =>
                {
                    this._contact.next({
                        id: contact?.customerId,
                        email: contact?.customerMail,
                        name: contact?.customerName,
                        phoneNumber: contact.customerPhone || phoneNumber,
                        type: contact?.customerType,
                        lastCallDate: contact?.lastCallDate,
                        callCount: contact?.callCount,
                        transactionCount: contact?.transactionCount,
                        transactionSum: contact?.transactionSum,
                        manager: _.isEmpty(contact?.manager) ? undefined : contact.manager,
                    });
                }),
            );
    }

    binotelMakeCall(phoneNumber: string): Observable<any>
    {
        return this._httpClient.post<any>('/api/binotel/make-call', {phone: phoneNumber, internal: this.userTelephonyIntegration.internalNumber })
            .pipe(
                tap((response) =>
                {
                    console.log('~~~~~~~~Binotel make call response',response);
                }),
            );
    }

    saveCallActivity(typeOfCall: AudioCallType, status: 'missed' | 'successful'): Observable<any>
    {
        const contact =  this._contact.getValue()

        const date = DateTime.now();
        const data: CallActivity = {
            callDate: date.toFormat('yyyy-MM-dd'),
            companyId: contact?.id,
            companyType: contact?.type,
            phoneNumber: contact?.phoneNumber,
            status,
            timeOfCall: this.callEndTime - this.callStartTime,
            typeOfCall: typeOfCall,
            userId: this.user.id
        }
        return this._httpClient.post<any>('/api/saveCallActivity', data)
            .pipe(
                tap((contact) =>
                {
                    console.log(contact);

                }),
            );
    }

    private initBinotel() {
        //Subscribe for incoming calls
        if (!this.userTelephonyIntegration) return

        this._wsService.ws$.pipe(filter(message => Boolean(message.binotel))).subscribe((message: WsIncomeMessage) => {
            switch (message.binotel.requestType) {
                case BinotelWSResponseType.receivedTheCall:
                    this.getContactByPhoneNumber(message.binotel.externalNumber).pipe(take(1)).subscribe();
                    this.openIncomingCallDialog(message.binotel.externalNumber);
                    return;
                case BinotelWSResponseType.hangupTheCall:
                    this.endCall()
            }

        })
    }

    private initAsterisk() {
        if (!this.userTelephonyIntegration) return

        this._userAgent = new UserAgent(this.getUAConfig());
        const registerer = new Registerer(this._userAgent);
        this._userAgent
            .start()
            .then(() => registerer.register())
            .catch(e => {
                console.log('Error start SIP connection', e);
            });

        this._outgoingToneAudio = new Audio('assets/sounds/telephone_outgoing_tone.aac');
        this._outgoingToneAudio.loop = true;

        this._phoneRingAudio = new Audio('assets/sounds/telephone_ring.aac');
        this._phoneRingAudio.loop = true;
    }

    getUAConfig(): UserAgentOptions {
        const { password, host, channel, userName} = this.userTelephonyIntegration

        const uri = UserAgent.makeURI(`sip:${userName}@${host}`);
        return {
            uri,
            authorizationPassword: password,
            transportOptions: {
                server: channel,
            },
            delegate: {
                onInvite: invitation =>
                    environment.disableIncomingSIPCalls
                        ? console.log(`~~~Incoming call from ${invitation.remoteIdentity.displayName}~~~`, { invitation })
                        : this.onInvite(invitation),
            },
            logLevel: 'error'
        };
    }

    onInvite(invitation: Invitation) {
        this.clearCallTime()
        // An Invitation is a Session
        this._callStatus.next(SessionState.Initial);
        this._incomingSession = invitation;
        this._phoneRingAudio.play();
        // Setup incoming session setup
        this._incomingSession.delegate = {
            onAck(ack: Ack) {
                console.log('~~~~~~~~~~',{ ack });
            },
            onInvite(request: IncomingRequestMessage, response: string, statusCode: number) {
                console.log('~~~~~~~~~~',{ request });
            },
            onCancel(cancel: Cancel) {
                console.log('~~~~~~~~~~',{ cancel });
            },
            onBye(bye: Bye) {
                console.log('~~~~~~~~~~',{ bye });
            },
        };

        // Handle incoming session state changes.
        this._incomingSession.stateChange.addListener((newState: SessionState) => {
            this._callStatus.next(newState);
            console.log('~~~~~~~~~~INCOMING STATE CHANGE~~~~~~~~~~~', newState);
            switch (newState) {
                case SessionState.Establishing:
                    // Session is establishing.
                    break;
                case SessionState.Established:
                    // Session has been established.
                    this.callStartTime = Date.now();
                    this._phoneRingAudio.pause();
                    const sessionDescriptionHandler = this._incomingSession.sessionDescriptionHandler;
                    if (!sessionDescriptionHandler || !(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
                        throw new Error('Invalid session description handler.');
                    }
                    this._callStreams.next({
                        localStream: sessionDescriptionHandler.localMediaStream,
                        remoteStream: sessionDescriptionHandler.remoteMediaStream,
                    });
                    break;
                case SessionState.Terminated:
                    // Session has terminated.
                    this.callEndTime = Date.now();
                    this._phoneRingAudio.pause();
                    this._dialog.closeAll();
                    this._callStreams.next(null)
                    this.saveCallActivity('incoming', 'successful').pipe(take(1)).subscribe()
                    break;
                default:
                    this._phoneRingAudio.pause();
                    break;
            }
        });

        this._callSession.next(this._incomingSession);
        this.getContactByPhoneNumber(this._incomingSession.remoteIdentity.displayName).pipe(take(1)).subscribe()
        this.openIncomingCallDialog( this._incomingSession.remoteIdentity.displayName);
    }

    makeCall(number: string) {
        this.clearCallTime()

        switch (this.telephonyIntegrationType) {
            case IntegrationSubType.binotel:
                this.binotelMakeCall(number).pipe(take(1)).subscribe(_ => {
                    this._dialog.closeAll();
                })
                break;
            case IntegrationSubType.asterisk:
                // this.asteriskMakeCall('*43') // Test call recording number
                this.asteriskMakeCall(number)
                break
        }
    }

    asteriskMakeCall(number: string) {
        const { host } = this.userTelephonyIntegration
        // Send an outgoing INVITE request
        this._callStatus.next(SessionState.Initial);
        const target = UserAgent.makeURI(`sip:${number}@s${host}`);
        if (!target) {
            throw new Error('Failed to create target URI.');
        }

        // Create a new Inviter
        const inviterOptions: InviterOptions = {
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: true,
                    video: false,
                },
            },
        };
        const inviter = new Inviter(this._userAgent, target, inviterOptions);

        // An Inviter is a Session
        this._outgoingSession = inviter;

        // Handle outgoing session state changes.
        this._outgoingSession.stateChange.addListener((newState: SessionState) => {
            console.log('~~~~~~~~~~_outgoing STATE CHANGE~~~~~~~~~~~', newState);
            this._callStatus.next(newState);
            switch (newState) {
                case SessionState.Establishing:
                    this._outgoingToneAudio.play();
                    break;
                case SessionState.Established:
                    this.callStartTime = Date.now();
                    this._outgoingToneAudio.pause();
                    const sessionDescriptionHandler = this._outgoingSession.sessionDescriptionHandler;
                    if (!sessionDescriptionHandler || !(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
                        throw new Error('Invalid session description handler.');
                    }
                    this._callStreams.next({
                        localStream: sessionDescriptionHandler.localMediaStream,
                        remoteStream: sessionDescriptionHandler.remoteMediaStream,
                    });
                    break;
                case SessionState.Terminated:
                    this.callEndTime = Date.now();
                    this._outgoingToneAudio.pause();
                    this._dialog.closeAll();
                    this._callStreams.next(null)
                    this.saveCallActivity('outgoing', 'successful').pipe(take(1)).subscribe()
                    break;
                default:
                    this._outgoingToneAudio.pause();
                    break;
            }
        });

        this._callSession.next(this._outgoingSession);

        // Send the INVITE request
        inviter.invite().catch((error: Error) => {
            console.log('Error SIP inviter request...', error);
        });
    }

    endCall() {
        switch (this.telephonyIntegrationType) {
            case IntegrationSubType.binotel:
                this._dialog.closeAll();
                break;
            case IntegrationSubType.asterisk:
                this._callSession.pipe(take(1)).subscribe(session => {
                    switch (session.state) {
                        case SessionState.Initial:
                        case SessionState.Establishing:
                            if (session instanceof Inviter) {
                                session.cancel();
                            } else {
                                session.reject();
                            }
                            break;
                        case SessionState.Established:
                            session.bye();
                            break;
                        case SessionState.Terminating:
                        case SessionState.Terminated:
                            // Cannot terminate a session that is already terminated
                            this._dialog.closeAll();
                            break;
                    }
                });
                break
        }

    }

    acceptCall() {
        this._incomingSession.accept();
    }

    openCallDialog(phoneNumber: string) {
        console.log({integration: this.userTelephonyIntegration, phoneNumber});
        if (!phoneNumber || !this.userTelephonyIntegration) {
            const errorDialog = this._fuseConfirmationService.open({
                title: this._transloco.translate('telephoneCall.emptyPhoneNumberTitle'),
                message: this._transloco.translate(!this.userTelephonyIntegration ? 'telephoneCall.emptyUserTelephonyIntegrationMessage' : 'telephoneCall.emptyPhoneNumberMessage'),
                actions: {
                    confirm: {
                        show: false,
                    },
                    cancel: {
                        show: false,
                    },
                },
                icon: {
                    show: true,
                    name: 'heroicons_outline:exclamation-triangle',
                    color: 'warn',
                },
                dismissible: true,
            });
            return;
        }

        const dialogRef = this._dialog.open(AudioCallComponent, {
            hasBackdrop: false,
            panelClass: 'audio-modal-dialog',
            data: {
                phoneNumber,
                type: 'outgoing',
            },
            scrollStrategy: this._overlay.scrollStrategies.noop()
        });
        dialogRef.afterOpened().subscribe(_ => this.makeCall(phoneNumber));
        dialogRef.afterClosed().subscribe(result => {
            // Todo: show statistic notification
        });
    }

    openIncomingCallDialog(phoneNumber: string) {
       if (!this.userTelephonyIntegration) return

        const dialogRef = this._dialog.open(AudioCallComponent, {
            hasBackdrop: false,
            panelClass: 'audio-modal-dialog',
            data: {
                type: 'incoming',
                phoneNumber,
            },
            scrollStrategy: this._overlay.scrollStrategies.noop()
        });
        dialogRef.afterClosed().subscribe(result => {
            // Todo: show statistic notification
        });
    }

    clearCallTime() {
        this.callStartTime = 0
        this.callEndTime = 0
    }

    getIPConnection(connectionId: number | string) {
        return this._httpClient.get<any>('/api/getIPConnection/' + connectionId)
            .pipe(
                tap((response) =>
                {
                    this.userTelephonyIntegration.password = response.password
                }),
            );
    }
}
