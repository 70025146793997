import { Injectable } from "@angular/core";
import { OracleResponse, OracleStatusCode } from "app/modules/share/global.types";
import { delay, Observable, of } from "rxjs";
import { HttpClientService } from "../share/services/http-client.service";
import { CompanyInitData } from "./company-init.types";

@Injectable({providedIn: 'root'})
export class CompanyInitService {

    constructor (private _http: HttpClientService) {}

    checkUserEmail (email: string) : Observable<any>
    {
        return this._http.get('/api/checkUser', { email });
    }

    registerCompany (companyInitData: CompanyInitData) : Observable<OracleResponse>
    {
        //return of({ StatusCode: OracleStatusCode.Success }).pipe(delay(3000));
        return this._http.post<OracleResponse>('/api/AddCompany', companyInitData);
    }
}
