import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { IntegrationSubType } from '../../integrations.types';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';

@Component({
    selector: 'app-binotel-dialog',
    standalone: true,
    imports: [
      CommonModule,
      TranslocoModule,
      FormsModule,
      ReactiveFormsModule,

      MatFormFieldModule,
      MatInputModule,
      MatIconModule,

      DialogTemplateComponent
    ],
    templateUrl: './binotel-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BinotelDialogComponent extends BaseDialogComponent<BinotelDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.binotel;
    }

    constructor()
    {
        super();

        this.form = this._formBuilder.group({
            name          : [this.data?.name, this.withRequiredValidator()],
            internalNumber: [this.data?.internalNumber, this.withRequiredValidator()],
            companyId     : [this.data?.companyId, this.withRequiredValidator()],
            key           : [this.data?.key, this.withRequiredValidator()],
            secret        : [this.data?.secret, this.withRequiredValidator()],
        });
    }

}
