<div
    *ngIf="parentMessage?.text"
    class="flex items-center mb-3 p-1 pl-3 text-sm rounded-md border border-accent-400 bg-accent-100 cursor-pointer"
    (click)="scrollToComment(parentMessage.id)"
>
    <mat-icon class="icon-size-5 text-green-500">reply</mat-icon>

    <div
        class="whitespace-pre-wrap flex-auto px-4 ml-3 mr-1 border-x h-full"
        [ngStyle]="{'tab-size': 6}"
        [innerHTML]="parentMessage.text"
    ></div>

    <button mat-icon-button (click)="clearMessageState()" color="warn">
        <mat-icon svgIcon="heroicons_outline:x-mark"></mat-icon>
    </button>

</div>

<div class="flex flex-0 gap-0 items-center" [ngClass]="{'flex-wrap': quickMode}">

    <div
        class="flex items-center justify-start h-11 my-px"
        [ngClass]="{'w-1/2': quickMode}"
        [ngStyle]="{'order': quickMode ? 2 : 'initial'}"
    >
        <app-emoji-picker
            (onEmojiSelect)="addEmojiToMessage($event)">
        </app-emoji-picker>

        <app-file-upload
            iconClass=""
            [maxFileSizeMb]="CHAT_MAX_FILE_SIZE_MB"
            (onFileSelected)="setFile($event)">
        </app-file-upload>

    </div>

    <div
        class="flex-auto w-full -mx-1"
        [ngClass]="{'mx-2': quickMode}"
        [ngClass]="{'ml-4': !quickMode}"
    >
        <mat-form-field
            #formFieldMessageInput
            subscriptSizing="dynamic"
            class="fuse-mat-dense rounded-lg fuse-mat-bold w-full"
        >
            <textarea
                #messageTextArea
                matInput
                cdkTextareaAutosize
                cdkFocusInitial
                rows="2"
                style="border: 1px !important;"
                [(ngModel)]="messageText"
                (keydown)="onKeyDown($event)"
                (paste)="onPaste($event)"
            ></textarea>
            <app-contact-picker
                #contactPicker
                [connectedTo]="formFieldMessageInput._elementRef"
                [contacts]="contacts"
                [multiselect]="false"
                (selectEvent)="onContactSelected($event)"
                (closeEvent)="onContactPickerClose()"
            ></app-contact-picker>
        </mat-form-field>
    </div>

    <div
        class="flex flex-0 justify-end"
        [ngClass]="{'w-1/2': quickMode}"
        [ngStyle]="{'order': quickMode ? 3 : 'initial'}"
    >
        <div class="flex items-center h-11 my-px ml-2 ">
            <button mat-icon-button (click)="sendMessage()" [disabled]="loading$ | async">
                <mat-icon [svgIcon]="'heroicons_outline:paper-airplane'"></mat-icon>
            </button>
        </div>
        <div class="flex items-center h-11 my-px" *ngIf="editMode">
            <button mat-icon-button (click)="clearMessageState()" color="warn">
                <mat-icon svgIcon="heroicons_outline:x-mark"></mat-icon>
            </button>
        </div>
    </div>
</div>

<div
    *ngIf="!!file"
    class="flex"
    [ngClass]="{
        'pt-4 px-4': !quickMode,
        'pt-2': quickMode
    }"
>
    <img
        *ngIf="isMessageFileImage"
        class="sm:h-50 w-min cursor-pointer"
        [src]="file.base64Data"
        (click)="imagePopup.open({ data: file.base64Data, isUrl: true })"
    >

    <div *ngIf="!isMessageFileImage" class="mt-3">{{ file.file.name }}</div>

    <button mat-icon-button class="ml-2" (click)="file=null" *ngIf="!quickMode">
        <mat-icon
            [svgIcon]="'heroicons_outline:x-mark'"
        ></mat-icon>
    </button>
</div>

<app-image-review-popup #imagePopup></app-image-review-popup>
