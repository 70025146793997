<div class="flex flex-col items-center">
    <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
        <ng-container *ngIf="contact?.avatar">
            <img
                class="object-cover w-full h-full"
                [src]="contact.avatar"
                [title]="contact.fullName"
                alt="Contact avatar"/>
        </ng-container>
        <ng-container *ngIf="!contact?.avatar">
            <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                {{ contact.fullName.charAt(0) }}
            </div>
        </ng-container>
    </div>
    <div *ngIf="showName">
        {{ contact?.fullName?.split(',')[0] }}
    </div>
</div>
