import { Notification } from 'app/layout/common/notifications/notifications.types';
import { Chat } from '../admin/apps/chat/chat.types';
import { HttpStatusCode } from '@angular/common/http';

export type LogoType = 'mainLogoLight' | 'mainLogoDark' | 'secondaryLogoLight' | 'secondaryLogoDark';

export type OracleResponse = {
    StatusCode?: HttpStatusCode,
    Status?: string,
    data?: any
}

export type ImageFileData = {
    name?: string;
    src?: string;
    imageBase64?: ArrayBufferLike;
    fileType?: 'image' | 'video' | string;
    type?: string ;
    location?: string;
}

export type FlexibleField = {
    filterCol: string;
    filterName: string;
    filtersValues: string[];
    filterTable: string;
}

export type EmployeeBriefly =
{
    id?: string;
    login?: string;
    avatar?: string;
    fullName?: string;
    phoneNumber?: string;
}

export type WsIncomeMessage = {
    chats?: Chat[];
    notifications?: Notification[];
    binotel?: {
        externalNumber?: string,
        companyID?: string,
        requestType: BinotelWSResponseType,
    }
}

export enum BinotelWSResponseType {
    receivedTheCall = 'receivedTheCall',
    hangupTheCall = 'hangupTheCall',
    answeredTheCall = 'answeredTheCall',
    apiCallCompleted = 'apiCallCompleted',
}

export enum RequestType {
    lead = 'XXRC_RANOK24_LEADS',
    customer = 'XXRC_RANOK24_CUSTOMERS',
    product = 'XXRC_RANOK24_ITEMS',
    order = 'XXRC_RANOK24_ITEM_TRANSACTIONS',
    payment = 'XXRC_RANOK24_RECEIPTS',
}

export enum CrmEntity {
    lead = 'lead',
    client = 'client',
    product = 'product',
    order = 'order',
    payment = 'payment',
    contract = 'contract',
    offer = 'offer',
    bill = 'bill',
}

export type Pagination = {
    currentPage?: number;
    totalPages?: number;
    itemsPerPage?: number;
    totalItems?: number;
}

export type CommonComment =
{
    date?: string;
    text?: string;
    owner?: string;
    avatar?: string;
    isMine?: boolean;
    file?: string;
    fileName?: string;
    answerToMessage?: CommonComment
}
