<ng-container *ngFor="let message of messages; let i = index; let first = first; let last = last; trackBy: trackByFn">
    <ng-container *ngIf="first || (messages[i - 1].createdAt | date:'d') !== (message.createdAt | date:'d')">
        <div class="flex items-center justify-center my-2 -mx-6">
            <div class="flex-auto border-b"></div>
            <div class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                {{ message.createdAt | date: DATE_TIME_FORMAT }}
            </div>
            <div class="flex-auto border-b"></div>
        </div>
    </ng-container>
    <div
        class="flex"
        [ngClass]="{ 'border-accent-600 bg-accent-100 border border-dashed': message.isMine && last && lastEditDisabled }">

        <div class="flex flex-col flex-grow"
            [ngClass]="{
                'items-end': message.isMine,
                'items-start': !message.isMine,
                'mt-0.5': i > 0 && messages[i - 1].isMine === message.isMine,
                'mt-3': i > 0 && messages[i - 1].isMine !== message.isMine
            }">
            <!-- Bubble -->
            <div
                class="flex items-end max-w-3/4"
                [ngClass]="{ 'flex-row-reverse': message.isMine }"
            >
                <app-avatar-image
                    [contact]="message.contact"
                    [ngClass]="{ 'ml-2': message.isMine, 'mr-2': !message.isMine }"
                ></app-avatar-image>
                <div
                    class="relative px-3 py-2 rounded-lg"
                    [ngClass]="{'bg-blue-500 text-blue-50': message.isMine,
                                'bg-gray-200 text-gray-700': !message.isMine}">
                    <!-- Speech bubble tail -->
                    <!-- <ng-container *ngIf="last || messages[i + 1].isMine !== message.isMine"> -->
                    <ng-container>
                        <div
                            class="absolute bottom-0 w-3"
                            [ngClass]="{'text-blue-500 -right-1 -mr-px mb-px': message.isMine,
                                        'text-gray-200 -left-1 -ml-px mb-px -scale-x-1': !message.isMine}">
                            <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                        </div>
                    </ng-container>

                    <div class="text-xs font-bold">
                        {{ message.contact?.fullName }}
                    </div>

                    <!-- Message -->
                    <div class="min-w-4 leading-5" *ngIf="message.value"
                        [innerHTML]="message.value.replaceAll('\n', '<br>')"
                    ></div>

                    <!-- Message File -->
                    <ng-container *ngIf="message.file">
                        <!-- Message Image -->
                        <div
                            *ngIf="isImage(message.fileType)"
                            class="min-w-4 leading-5 cursor-pointer"
                            (click)="imagePopup.open({ data: message.file, isUrl: true })"
                        >
                            <img [src]="message.file">
                        </div>
                        <!-- Message Not Image -->
                        <div
                            *ngIf="!isImage(message.fileType)"
                            class="min-w-4 leading-5 cursor-pointer"
                        >
                            <div
                                class="text-primary-600 cursor-pointer hover:underline"
                                (click)="download(message.file, message.fileName)"
                            >
                                {{ message.fileName || 'Unknown' }}
                            </div>
                        </div>
                    </ng-container>

                </div>

            </div>

            <!-- Time -->
            <ng-container
                *ngIf="first
                   || last
                   || messages[i + 1].isMine !== message.isMine
                   || messages[i + 1].createdAt !== message.createdAt">
                <div
                    class="my-0.5 text-sm font-medium text-secondary"
                    [ngClass]="{'mr-3': message.isMine,
                                'ml-3': !message.isMine}">
                    {{ message.createdAt | date:'HH:mm' }}
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="canEditLast && message.isMine && last">
            <div class="flex flex-col ml-1 -mr-3 -mt-2">
                <button mat-icon-button (click)="onMessageEdit.emit(message)" color="primary" [disabled]="lastEditDisabled || (loading$ | async)">
                    <mat-icon class="icon-size-5">edit</mat-icon>
                </button>
                <button mat-icon-button class="-m-1" (click)="onMessageDelete.emit(message.id)" color="warn" [disabled]="lastEditDisabled || (loading$ | async)">
                    <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash"></mat-icon>
                </button>
            </div>
        </ng-container>

    </div>

    <!-- Speech bubble tail SVG -->
    <!-- @formatter:off -->
    <ng-template #speechBubbleExtension>
        <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z" fill="currentColor" fill-rule="nonzero"></path>
            </g>
        </svg>
    </ng-template>
    <!-- @formatter:on -->

</ng-container>

<app-image-review-popup #imagePopup></app-image-review-popup>
