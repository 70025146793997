import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseLoadingService } from '@fuse/services/loading';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoService } from '@ngneat/transloco';
import { ChatService } from 'app/modules/admin/apps/chat/chat.service';
import { Chat, ChatMessage, ChatType } from 'app/modules/admin/apps/chat/chat.types';
import { MessageEdit } from 'app/modules/share/components/messages/messages.types';
import { ContactPickerComponent } from 'app/modules/share/components/picker/contact-picker/contact-picker.component';
import { CHAT_MAX_FILE_SIZE_MB, CHAT_NOTIFICATION_BADGE_CLASS } from 'app/modules/share/global.constants';
import { EmployeeBriefly } from 'app/modules/share/global.types';
import { EmployeesBrieflyService } from 'app/modules/share/services/employees-briefly.service';
import { cloneDeep } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { FilesHelperService } from '../../tasks/files-helper.service';

@Component({
    selector: 'app-abstract-conversation',
    standalone: true,
    imports: [],
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class AbstractChatConversationComponent implements OnInit, OnDestroy
{
    @ViewChild('messageTextArea') messageTextAreaRef: ElementRef;
    @ViewChild('contactPicker') contactPicker: ContactPickerComponent;

    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = false;

    chat: Chat;
    originalMessage: ChatMessage;
    messageText = '';

    CHAT_NOTIFICATION_BADGE_CLASS = CHAT_NOTIFICATION_BADGE_CLASS;
    CHAT_MAX_FILE_SIZE_MB = CHAT_MAX_FILE_SIZE_MB;

    protected abstract _chatService: ChatService;

    loading$ = inject(FuseLoadingService).show$;

    protected _transloco = inject(TranslocoService);
    protected _employeesService = inject(EmployeesBrieflyService);
    protected _changeDetectorRef = inject(ChangeDetectorRef);
    protected _fileHelper = inject(FilesHelperService);
    protected _fuseConfirmationService = inject(FuseConfirmationService);
    protected _fuseMediaWatcherService = inject(FuseMediaWatcherService);
    protected _destroyRef = inject(DestroyRef);

    get isInternalChat(): boolean
    {
        return [ChatType.single, ChatType.messenger].includes(this.chat.type);
    }

/*     get isMessageFileImage(): boolean
    {
        return !!this.file && IMAGE_MIME_TYPES.includes(this.file.file.type);
    }
 */
    constructor()
    {
    }

    ngOnInit(): void
    {
        this._chatService.chat$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(chat =>
            {
                this.chat = chat;
                this.clearMessageState();
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(({matchingAliases}) => {
                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                } else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy(): void
    {
        this._chatService.resetChat();
    }

    openDrawer(): void
    {
        this.drawerOpened = true;
    }

    closeDrawer(): void
    {
        this.drawerOpened = false;
    }

    toggleDrawer(): void
    {
        this.drawerOpened = !this.drawerOpened;
    }

    resetChat(): void
    {
        this._chatService.resetChat();

        // Close the contact info in case it's opened
        this.drawerOpened = false;

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    async onSendMessage(chatMessage: MessageEdit)
    {
        let message = chatMessage.members.reduce((acc, user) =>
            acc.replaceAll(user.fullName, `[USER]${ user.id }[/USER]`)
        , chatMessage.text);

        //message = message.replace( /(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" class="text-primary-700">$1</a>');

        const observable = this.originalMessage
            ? this._chatService.updateMessage(this.originalMessage.id, message)
            : this._chatService.sendMessage(this.chat, message, chatMessage.file);

        this._chatService.chat = {
            ...this._chatService.chat,
            messages: await firstValueFrom(observable)
        };

        this._refreshChatState(chatMessage.text, (new Date()).toISOString());
    }

    onRecipientsSelected(event: EmployeeBriefly[]): void
    {
        this.chat.contacts = event;
    }

    onMessageDelete(id: string)
    {
        this._fuseConfirmationService.open(
            {
                title      : this._transloco.translate('chats.confirmRemoveMessage'),
                message    : '',
                actions    : {
                    confirm: {
                        label: this._transloco.translate('confirm')
                    },
                    cancel : {
                        label: this._transloco.translate('cancel'),
                    },
                }
            }
        ).afterClosed().subscribe(async result =>
            {
                if (result === 'confirmed') {
                    await firstValueFrom(this._chatService.removeMessage(id));
                    const messages = await firstValueFrom(this._chatService.getMessages(this.chat.id));
                    this.chat.messages = messages;
                    const lastMessage = messages[messages.length - 1];

                    this._refreshChatState(lastMessage.value, lastMessage.createdAt);
                }
            });
    }

    onMessageEdit(message: ChatMessage)
    {
        this.originalMessage = cloneDeep(message);
        this.messageText = message.value.replace(/<a\s+href=['"]([^'"]+)['"][^>]*>.*?<\/a>/g, '$1');
        this.messageTextAreaRef?.nativeElement.focus();
    }

    clearMessageState()
    {
        this.originalMessage = null;
        this.messageText = '';
    }

    async download(fileUrl: string, fileName: string)
    {
        this._fileHelper.download(fileUrl, fileName);
    }

    protected _refreshChatState(lastMessage: string, lastMessageAt: string)
    {
        this.chat.lastMessage = lastMessage;
        this.chat.lastMessageAt = lastMessageAt;

        this.clearMessageState();
        this._chatService.refreshChat();
        this._chatService.sortChats()
        //this._chatService.refreshChats();
        this.originalMessage = null;
        this._changeDetectorRef.markForCheck();
    }

}
