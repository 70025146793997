import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-policy-dialog',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,

        MatDialogModule,
        MatButtonModule,
        MatDividerModule,
    ],
    styleUrls: ['policy-dialog.component.scss'],
    templateUrl: './policy-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyDialogComponent
{

}
