export const MIME_TYPES: { [key: string]: string } = {
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'pdf': 'application/pdf',
    'jpg': 'image/jpg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'bmp': 'image/bmp',
    'webp': 'image/webp',
    'txt': 'text/plain',
    'html': 'text/html',
    'csv': 'text/csv',
    'mp3': 'audio/mpeg',
    'mp4': 'video/mp4',
    'avi': 'video/x-msvideo',
    'zip': 'application/zip',
    'rar': 'application/vnd.rar',
    // Расширяйте список по мере необходимости
};

export const IMAGE_MIME_TYPES = [MIME_TYPES.gif, MIME_TYPES.jpeg, MIME_TYPES.jpg, MIME_TYPES.png, MIME_TYPES.bmp, MIME_TYPES.webp,];
export const IMAGE_TYPES = ['gif', 'jpeg', 'jpg', 'png', 'bmp', 'webp'];
