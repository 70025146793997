import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ContactsPanelComponent } from 'app/modules/share/components/contacts-panel/contacts-panel.component';
import { EmployeeBriefly } from 'app/modules/share/global.types';
import { DateTime } from 'luxon';
import { EVENT_DATE_FORMAT, EVENT_DATE_TIME_FORMAT, EVENT_TIME_FORMAT } from '../project-constants';
import { ProjectEvent } from '../project.types';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ProjectService } from '../project.service';
import { firstValueFrom } from 'rxjs';
import { HelperService } from 'app/modules/share/services/helper.service';

@Component({
    selector: 'app-event-dialog',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        ReactiveFormsModule,

        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatNativeDateModule,
        MatSlideToggleModule,

        ContactsPanelComponent,
        NgxMaterialTimepickerModule,
    ],
    templateUrl: './event-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDialogComponent implements OnInit
{
    cancelSendingBtnDisabled = false;
    form: UntypedFormGroup;

    get f(): any
    {
        return this.form?.controls;
    }

    get canDelete()
    {
        return this.data.id && this.data.end && !this.data.jobId
            && this.data.end.getTime() > new Date().getTime();
    }

    constructor(
        private _dialogRef: MatDialogRef<EventDialogComponent>,
        private _formBuilder: FormBuilder,
        private _transloco: TranslocoService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _projectService: ProjectService,
        private _helper: HelperService,
        @Inject(MAT_DIALOG_DATA) public data: ProjectEvent
    )
    {
    }

    ngOnInit(): void
    {
        this.form = this._formBuilder.group(
        {
            id:                [this.data.id],
            title:             [this.data.title, Validators.required],
            allDay:            [this.data.allDay],
            start:             [this.data.start, Validators.required],
            startTime:         [this._getTimeStr(this.data.start), Validators.required],
            end:               [this.data.end, Validators.required],
            endTime:           [this._getTimeStr(this.data.end), Validators.required],
            participants:      [this.data.participants],
            emailNotification: [this.data.emailNotification]
        });
    }

    onContactSelected(event: EmployeeBriefly[])
    {
        this.form.patchValue({ members: event });
    }

    close(value?: any)
    {
        this._dialogRef.close(value);
    }

    save()
    {
        const projectEvent: ProjectEvent = {
            id: this.f.id.value,
            title: this.f.title.value,
            allDay: this.f.allDay.value,
            start: this._addTime(this.f.start.value, this.f.startTime.value),
            end: this._addTime(this.f.end.value, this.f.endTime.value),
            emailNotification: this.f.emailNotification.value,
            participants: this.f.participants.value
        };

        this.close({
            action: 'save',
            projectEvent: projectEvent
        });
    }

    delete()
    {
        const dialogRef = this._fuseConfirmationService.open({
            title: this._transloco.translate('event') + ' ' + this.data.title,
            message: this._transloco.translate('eventDeleteConfirmation')
        });

        dialogRef.afterClosed().subscribe((result) =>
        {
            if(result === 'confirmed')
            {
                this.close({
                    action: 'delete',
                    id: this.f.id.value
                });
            }
        });
    }

    async cancelSending()
    {
        const response = await firstValueFrom(this._projectService.cancelSending(this.data.jobId));
        this._helper.showInfoMessage(response.message);
        this.cancelSendingBtnDisabled = true;
    }

    private _getTimeStr(date?: Date | string): string
    {
        let _date = date || new Date();
        if(typeof _date === 'string')
        {
            _date = DateTime.fromFormat(_date, EVENT_DATE_TIME_FORMAT).toJSDate();
        }
        return DateTime.fromJSDate(_date).toFormat(EVENT_TIME_FORMAT);
    }

    private _getFormattedString(dateTime: DateTime, time: string): string
    {
        return dateTime.toFormat(EVENT_DATE_FORMAT) + ' ' + time;
    }

    private _addTime(date: any, time: string): Date
    {
        const _date = date.isLuxonDateTime ? date : DateTime.fromJSDate(date);
        const dateTimeStr = this._getFormattedString(_date, time);
        return DateTime.fromFormat(dateTimeStr, EVENT_DATE_TIME_FORMAT).toJSDate();
    }

}
