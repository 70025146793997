<!-- <h1 mat-dialog-title>
    {{ 'project.eventDialogTitle' | transloco }}
</h1> -->
<div mat-dialog-content>

    <form [formGroup]="form">
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-4">
            <!-- Start Date -->
            <div class="sm:col-span-4">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>
                        {{ 'project.eventTitle' | transloco }}
                    </mat-label>
                    <textarea matInput formControlName="title"></textarea>
                </mat-form-field>
            </div>
        </div>

<!--         <div class="grid sm:grid-cols-4 gap-6 w-full mt-4">
            <div class="sm:col-span-2">
                <mat-slide-toggle class="my-4" formControlName="allDay" color="primary">
                    {{ 'allDay' | transloco }}
                </mat-slide-toggle>
            </div>
        </div> -->

        <div class="grid sm:grid-cols-4 gap-6 w-full mt-4">
            <!-- Start Date -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>
                        {{ 'startDate' | transloco }}
                    </mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="start" [placeholder]="'startDate' | transloco">
                        <input matEndDate formControlName="end" [placeholder]="'endDate' | transloco">
                    </mat-date-range-input>
                    <mat-hint>
                        MM/DD/YYYY – MM/DD/YYYY
                    </mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    <mat-error *ngIf="f.start.hasError('matStartDateInvalid')">
                        {{ 'error.invalidStartDate' | transloco }}
                    </mat-error>
                    <mat-error *ngIf="f.end.hasError('matEndDateInvalid')">
                        {{ 'error.invalidEndDate' | transloco }}
                    </mat-error>
                    <mat-error *ngIf="f.start.hasError('required') || f.end.hasError('required')">
                        {{ 'requiredFieldError' | transloco }}
                    </mat-error>

                </mat-form-field>
            </div>

            <!--  *ngIf="!f.allDay.value" -->
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>
                        {{ 'startTime' | transloco }}
                    </mat-label>
                    <!-- <input matInput type="time" formControlName="startTime" [placeholder]="'startTime' | transloco"> -->
                    <input
                        matInput
                        formControlName="startTime"
                        [format]="24"
                        [placeholder]="'startTime'"
                        [ngxTimepicker]="startTimePicker"
                    >
                    <ngx-material-timepicker #startTimePicker [ngStyle]="{'z-index': 1001}"></ngx-material-timepicker>
                </mat-form-field>
            </div>

            <div class="sm:col-span-1">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>
                        {{ 'endTime' | transloco }}
                    </mat-label>
                    <!-- <input matInput type="time" formControlName="endTime" [placeholder]="'endTime' | transloco"> -->
                    <input
                        matInput
                        formControlName="endTime"
                        [format]="24"
                        [placeholder]="'endTime'"
                        [ngxTimepicker]="endTimePicker"
                    >
                    <ngx-material-timepicker #endTimePicker [ngStyle]="{'z-index': 1001}"></ngx-material-timepicker>
                </mat-form-field>
            </div>
        </div>

        <mat-slide-toggle class="mt-4" formControlName="emailNotification" color="primary">
            {{ 'emailNotification' | transloco }}
        </mat-slide-toggle>

        <div class="grid w-full mt-4">
            <app-contacts-panel
                class="w-full"
                [labelText]="'participants' | transloco"
                [contactsSelected]="f.participants.value"
                (onSelect)="onContactSelected($event)"
            ></app-contacts-panel>
        </div>

    </form>

<div mat-dialog-actions class="mt-2 justify-between">

    <button mat-raised-button (click)="cancelSending()" *ngIf="!!data.jobId" [disabled]="cancelSendingBtnDisabled">
        {{ 'project.cancelSending' | transloco }}
    </button>

    <div class="justify-end">
        <button mat-raised-button (click)="close()">
            {{ 'noThanks' | transloco }}
        </button>
        <button mat-raised-button (click)="delete()" color="warn" *ngIf="canDelete">
            {{ 'delete' | transloco }}
        </button>
        <button mat-raised-button (click)="save()" color="primary" [disabled]="!canDelete && !!data.id || form.invalid">
            {{ 'save' | transloco }}
        </button>
    </div>
</div>
