import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ExternalChatService } from 'app/layout/common/quick-chat/external-chat.service';
import { IQuickChatService } from 'app/layout/common/quick-chat/IQuickChatService';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { WsIncomeMessage } from 'app/modules/share/global.types';
import { WsMessagesService } from 'app/modules/share/services/ws-messages.service';
import { keyBy, mapValues, sum } from 'lodash';
import { filter, firstValueFrom } from 'rxjs';
import { ChatService } from './chat.service';
import { Chat } from './chat.types';

@Injectable({
    providedIn: 'root'
})
export class ChatWsService {

/*     private _unreadCount = new BehaviorSubject(0);
    private _hasNewMessages = new BehaviorSubject<Chat[]>([]);

    get hasMessagesToNotify$()
    {
        return this._hasNewMessages.pipe(
            map(chats => chats.filter(chat => !chat.muted)),
            filter(chats => chats.length > 0)
        );
    }

    get unreadCount$()
    {
        return this._unreadCount.asObservable();
    }

    get unreadCount()
    {
        return this._unreadCount.getValue();
    }

    set unreadCount(count: number)
    {
        this._unreadCount.next(count)
    } */

    private _chatServices: IQuickChatService[] = [];

    constructor(
        private _wsService: WsMessagesService,
        private _chatService: ChatService,
        private _quickChatService: QuickChatService,
        private _externalChatService: ExternalChatService,
    )
    {
        this._chatServices = [
            this._chatService,
            this._quickChatService,
            this._externalChatService
        ];

        this._chatServices.forEach(chatService => {
            chatService.chats$
                .pipe(takeUntilDestroyed())
                .subscribe(chats =>
                    chatService.unreadCounter.unreadCount = this._getUnreadCount(chats)
            );
        });

        this._wsService.ws$
            .pipe(
                takeUntilDestroyed(),
                filter(message => Boolean(message.chats))
            )
            .subscribe(async (message: WsIncomeMessage) =>
            {
                if(!message.chats.length) {
                    return
                };

                this._chatServices.forEach(async chatService =>
                {
                    const wsChats = message.chats.filter(chatService.chatFilter);

                    const unreadCounter = chatService.unreadCounter;
                    const newUnreadCount = this._getUnreadCount(wsChats);

                    if(unreadCounter.unreadCount !== newUnreadCount)
                    {
                        unreadCounter.unreadCount = newUnreadCount;

                        const chatsMap = mapValues(keyBy(chatService.chats, 'id'));
                        wsChats.forEach(chat =>
                        {
                            chatsMap[chat.id] && this._updateChat(chatsMap[chat.id], chat);
                        });

                        unreadCounter.hasNewMessages = wsChats;
                        const newSelectedChat = chatService.chat && chatsMap[chatService.chat.id];

                        if(newSelectedChat)
                        {
                            await this._updateChatMessages(chatService, newSelectedChat);
                            this._updateChatState(chatService, chatService.chat);
                        }

                    }
                });

            });
    }

    updateChatMessages(chatService: IQuickChatService = this._chatService)
    {
        chatService.chat && this._updateChatMessages(chatService, chatService.chat);
    }

    async _updateChatState(chatService: IQuickChatService, selectedChat: Chat)
    {
        const chats = this._chatService.allChats;
        const currentSelectedChat = chatService.chat
        const newSelectedChat = currentSelectedChat && chats.find(c => c.id === currentSelectedChat.id);
        if(newSelectedChat)
        {
            if(newSelectedChat.id === selectedChat?.id)
            {
                chatService.chat = newSelectedChat;
            } else
            {
                chatService.opened
                    && await this._updateChatMessages(chatService, newSelectedChat);
            }
        }
    }

    private async _updateChatMessages(chatService: IQuickChatService, chat: Chat)
    {
        const messages = await firstValueFrom(this._chatService.getMessages(chat.id));
        if(chat.unreadCount)
        {
            await firstValueFrom(this._chatService.setMessagesRead(chat.id));
        }

        const chats = this._chatService.allChats;
        chatService.unreadCounter.adjustCounter(-chat.unreadCount);

        const chatIdx = chats.findIndex(itm => itm.id === chat.id);
        if(chatIdx >= 0)
        {
            chats[chatIdx] = {
                ...this._mergeChat(chats[chatIdx], chat),
                messages,
                unreadCount: 0,
            };
            this._chatService.allChats = chats;
            chatService.chat = chats[chatIdx];
        } else {
            console.warn('Can\'t find chat in the list', chat);
        }
    }

    private _mergeChat(chat: Chat, newChat: Chat): Chat
    {
        return {
            ...chat,
            unreadCount:  newChat.unreadCount,
            lastMessage: newChat.lastMessage,
            lastMessageAt: newChat.lastMessageAt,
        };
    }

    private _updateChat(chat: Chat, newChat: Chat): void
    {
        chat.unreadCount = newChat.unreadCount;
        chat.lastMessage = newChat.lastMessage;
        chat.lastMessageAt = newChat.lastMessageAt;
    }

    private _getUnreadCount(chats: Chat[]): number
    {
        return sum(chats.map(chat => chat.unreadCount)) || 0;
    }

}
