import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { environment } from '@environments/environment';
import { TranslocoModule } from '@ngneat/transloco';
import { IntegrationSubType } from '../../integrations.types';
import { BaseMessengerDialogComponent } from '../base-messenger-dialog/base-messenger-dialog.component';
import { DialogTemplateComponent } from "../dialog-template/dialog-template.component";

@Component({
    selector: 'app-external-dialog',
    standalone: true,
    templateUrl: './external-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        ClipboardModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,

        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,

        DialogTemplateComponent,
    ]
})
export class ExternalDialogComponent extends BaseMessengerDialogComponent<ExternalDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.external;
    }

    get scriptCode(): string
    {
        return `<script>
    (function(w,d,u){
        var s=d.createElement('script'); s.async=true; s.type="module"; s.src=u+'?'+(Date.now()/60000|0);
        s.setAttribute('data-widget-id', "${this.data?.apiKey}");
        var h=d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s,h);
    })(window,document,'${ environment.helperWidgetUrl }/widget-loader.js');
</script>`;
}

    constructor()
    {
        super();

        this.form = this._formBuilder.group({
            name  : [ this.data?.name, this.withRequiredValidator(), this.nameUniqValidator.bind(this)],
/*
            channel: [ data?.channel, this.withRequiredValidator(Validators.pattern(/^[0-9]*$/))],
*/
        });
    }

}
