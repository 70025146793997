import { BehaviorSubject, filter, map, Subject } from 'rxjs';
import { Chat } from './chat.types';
import { tap } from 'lodash';

export class ChatUnreadCounter {

    private _unreadCount$ = new BehaviorSubject(0);
    private _hasNewMessages$ = new Subject<Chat[]>();

    constructor()
    {
    }

    get hasMessagesToNotify$()
    {
        return this._hasNewMessages$.pipe(
            map(chats => chats.filter(chat => !chat.muted)),
            filter(chats => chats.length > 0),
        );
    }

    get unreadCount$()
    {
        return this._unreadCount$.asObservable();
    }

    get unreadCount()
    {
        return this._unreadCount$.getValue();
    }

    set unreadCount(count: number)
    {
        this._unreadCount$.next(count)
    }

    set hasNewMessages(chats: Chat[])
    {
        this._hasNewMessages$.next(chats);
    }

    adjustCounter(value: number): void
    {
        this._unreadCount$.next(this.unreadCount + value);
    }
}
