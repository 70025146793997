import { IMAGE_TYPES, MIME_TYPES } from 'app/modules/share/mime-types';

export function isFileImage(url: string): boolean
{
    return IMAGE_TYPES.includes(_extension(url));
}

export function resolveFileUrl(url: string): string
{
    const mimeType = MIME_TYPES[_extension(url)];
    const isOfficeDocument = mimeType.includes('application') || mimeType.includes('document');

    return isOfficeDocument ? `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}` : url;
}

function _extension(url: string): string
{
    return url.split('?').shift().split('.').pop().toLowerCase();
}
