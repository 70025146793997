const KB = 1024;
export const MB = KB * 1024;
export const SECOND = 1000;
export const MINUTE = SECOND * 60;

export const NOTIFICATION_SOUND_FILENAME  = 'notification.mp3';
export const HOME_PAGE_URL = '/pages/news';
export const MAINTENANCE_PAGE_URL = '/pages/maintenance';
export const CHAT_MAX_FILE_SIZE_MB = 25;
export const COMPANY_AVATAR_MAX_IMAGE_SIZE = 100 * KB;
export const USER_AVATAR_MAX_IMAGE_SIZE = 1000 * KB;
export const MESSAGE_DURATION_TIME = 3 * SECOND;
export const ERROR_MESSAGE_DURATION_TIME = 6 * SECOND;
export const CHAT_NOTIFICATION_BADGE_CLASS = 'px-2 bg-green-500 text-white rounded-full';
export const MAX_PORT_NUMBER = 65535;

export const REGEX_NUMBERS_PATTERN = /^[0-9]*$/;
export const REGEX_URL_PATTERN = '(https?://)?([\\da-z.-_]+)\\.([a-z.]{2,6})[/\\w .-_]*/?';
export const REGEX_PASSWORD_PATTERN = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/;

export const DATE_FORMAT = 'dd.MM.yyyy';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT;
export const DATE_TIME_FORMAT_ZZ = DATE_FORMAT + ' ' + TIME_FORMAT + 'ZZ';

export const LOGO_DEFAULT_PATH = '/assets/images/logo/';
export const LOGO_DEFAULT_MAIN_LIGHT = LOGO_DEFAULT_PATH + 'logoLight.png';
export const LOGO_DEFAULT_MAIN_DARK = LOGO_DEFAULT_PATH + 'logoDark.png';

export const EXCEL_BLOB_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';

export const DIALOG_SETTINGS = [
    'max-w-full',
    'w-11/12',
    'sm:w-3/5',
    'sm:h-auto',
    'text-white'
];

