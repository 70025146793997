import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { environment } from '../../environments/environment';
import { NavigationGuard } from './core/auth/guards/navigation.guard';
import { TokenGuard } from './core/auth/guards/token.guard';
import { NavigationService } from './core/navigation/navigation.service';
import { AnalyticsService } from './modules/admin/dashboards/analytics/analytics.service';
import { initCompanyRoutes } from './modules/company-init/company-init.routes';
import { HOME_PAGE_URL, MAINTENANCE_PAGE_URL } from './modules/share/global.constants';
import { FlexibleFieldsService } from './modules/share/services/flexible-fields.service';
import { LogoImageService } from './modules/share/services/logo-image.service';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export let appRoutes: Route[] = [];
export let children: Route[] = [];


if (environment.init)
{
    appRoutes = initCompanyRoutes;
}
else
{
    children = [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: environment.onMaintenance ? MAINTENANCE_PAGE_URL : HOME_PAGE_URL,
        },

        {
            path: 'login',
            canActivate: [TokenGuard],
            children: []
        },

        // Redirect signed-in user to the '/dashboards/project'
        //
        // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
        // path. Below is another redirection for that path to redirect the user to the desired
        // location. This is a small convenience to keep all main routes together here on this file.
        { path: 'signed-in-redirect', pathMatch : 'full', redirectTo: HOME_PAGE_URL },

        // Auth routes for guests
        {
            path: '',
            canActivate: [NoAuthGuard],
            canActivateChild: [NoAuthGuard],
            component: LayoutComponent,
            data: {
                layout: 'auth'
            },
            children: [
                { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
                { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
                { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
                { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
                { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') }
            ]
        },

        // Auth routes for authenticated users
        {
            path: '',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            component: LayoutComponent,
            data: {
                layout: 'empty'
            },
            children: [
                { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
                { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
            ]
        },

        // Landing routes
        // {
        //     path: '',
        //     component: LayoutComponent,
        //     data: {
        //         layout: 'empty'
        //     },
        //     children: [
        //         { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
        //     ]
        // },

        // Admin routes
        {
            path: '',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            component: LayoutComponent,
            resolve: {
                initialData: initialDataResolver
            },

            children: [
                // Dashboards
                {
                    path: 'dashboards',
                    canActivate: [NavigationGuard],
                    runGuardsAndResolvers: 'always',
                    children: [
                        {
                            path: 'project',
                            loadChildren: () => import('app/modules/admin/dashboards/project/project.routes')
                        },
                        {
                            path: 'organization',
                            loadChildren: () => import('./modules/admin/dashboards/organization/organization.routes')
                        },
                        { path: 'analytics', loadChildren: () => import('app/modules/admin/dashboards/analytics/analytics.routes') },
                ]},

                // Apps
                {
                    path: 'apps',
                    canActivate: [NavigationGuard],
                    children: [
                        { path: 'academy', loadChildren: () => import('app/modules/admin/apps/academy/academy.routes') },
                        { path: 'chat', loadChildren: () => import('app/modules/admin/apps/chat/chat.routes') },
/*                         {
                            path: 'contacts',
                            pathMatch: 'full',
                            redirectTo: `contacts/(tabPanel:${ContactType.employee})`
                        }, */
                        {
                            path: 'contacts',
                            loadChildren: () => import('app/modules/admin/apps/contacts/contacts.routes')
                        },
                        { path: 'ecommerce', loadChildren: () => import('app/modules/admin/apps/ecommerce/ecommerce.routes') },
                        { path: 'file-manager', loadChildren: () => import('app/modules/admin/apps/file-manager/file-manager.routes') },
                        { path: 'help-center', loadChildren: () => import('app/modules/admin/apps/help-center/help-center.routes') },

                        {
                            path: 'crm',
                            resolve: {
                            },
                            loadChildren: () => import('app/modules/admin/apps/crm/crm.routes')
                        },

                        {
                            path: 'marketing',
                            resolve: {
                                filters: () => inject(FlexibleFieldsService).getFilters(),
                                data: () => inject(AnalyticsService).getData(),
                            },
                            loadChildren: () => import('app/modules/admin/apps/marketing/newsletter.routes')
                        },

                        {
                            path: 'mailbox',
                            runGuardsAndResolvers: 'always',
                            loadChildren: () => import('app/modules/admin/apps/mailbox/mailbox.routes')
                        },
                        { path: 'notes', loadChildren: () => import('app/modules/admin/apps/notes/notes.routes') },
                        { path: 'scrumboard', loadChildren: () => import('app/modules/admin/apps/scrumboard/scrumboard.routes') },
                        {
                            path: 'tasks',
                            loadChildren: () => import('app/modules/admin/apps/tasks/tasks.routes')
                        },
                        {
                            path: 'developers',
                            loadChildren: () => import('app/modules/admin/apps/developers/developers.routes')
                        },
                    ]
                },

                // Pages
                {
                    path: 'pages',
                    children: [

                    // Error
                    {
                        path: 'error',
                        children: [
                            { path: '403', loadChildren: () => import('app/modules/admin/pages/error/error-403/error-403.routes') },
                            { path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.routes') },
                            { path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.routes') }
                        ]
                    },

                    // Maintenance
                    { path: 'maintenance', loadChildren: () => import('app/modules/admin/pages/maintenance/maintenance.routes') },

                    // Profile
                    { path: 'news', loadChildren: () => import('app/modules/admin/pages/news/news.routes') },

                    // Profile
                    { path: 'profile', loadChildren: () => import('app/modules/admin/pages/profile/profile.routes') },

                    // Settings
                    { path: 'settings', loadChildren: () => import('app/modules/admin/pages/settings/settings.routes') },
                ]},

                // 404 & Catch all
                {
                    path: '404-not-found',
                    resolve: {
                        userMenu: () => inject(NavigationService).get()
                    },
                    pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.routes') },
                {
                    path: '**',
                    redirectTo: '404-not-found'
                }
            ]
        }
    ];

    appRoutes = [
        {
            path: '',
            resolve: {
                logos: () => inject(LogoImageService),
            },
            children
        },
    ];

}
