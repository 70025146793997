import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, DestroyRef, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AbstractControl,
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterLink } from '@angular/router';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { PolicyDialogComponent } from 'app/modules/auth/policy-dialog/policy-dialog.component';
import { TermsDialogComponent } from 'app/modules/auth/terms-dialog/terms-dialog.component';
import { REGEX_PASSWORD_PATTERN } from 'app/modules/share/global.constants';
import { filter, firstValueFrom, map, Observable, switchMap, tap, timer } from 'rxjs';
import { CompanyInitService } from '../company-init.service';
import { CompanyInitData } from '../company-init.types';

@Component({
    selector: 'company-init',
    templateUrl: './register.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {showError: true},
         },
    ],
    imports: [
        CommonModule,
        TranslocoModule,
        RouterLink,
        FormsModule,
        ReactiveFormsModule,

        MatButtonModule,
        MatCheckboxModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatStepperModule,
    ],
})

export class RegisterComponent implements OnInit {

    companyInitData: CompanyInitData;
    firstFormGroup: UntypedFormGroup;
    secondFormGroup: UntypedFormGroup;
    showLoader = false;
    isScreenSmall: boolean;
    submitted: boolean = false;
    private _lastEmail = null;

    get finalUrl(): string
    {
        return this.second.companyDomain.value + '.' + window.location.hostname;
    }

    get hostname(): string
    {
        return window.location.hostname;
    }

    constructor(
        private _destroyRef: DestroyRef,
        private _formBuilder: FormBuilder,
        private _companyInitService: CompanyInitService,
        private _translocoService: TranslocoService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _dialog: MatDialog,
    ) {}

    get first()
    {
        return this.firstFormGroup.controls;
    }

    get second()
    {
        return this.secondFormGroup.controls;
    }

    ngOnInit()
    {
        this.firstFormGroup = this._formBuilder.group(
         {
            email: ['',
                [
                    Validators.required,
                    Validators.email
                ],
                this.emailValidator.bind(this)
            ],
            password: ['', [
                Validators.required,
                Validators.pattern(REGEX_PASSWORD_PATTERN)
            ]],
            agreements: [false, Validators.requiredTrue],
        });

        this.secondFormGroup = this._formBuilder.group(
        {
            companyName: ['', Validators.required],
            companyDomain: ['',
                [
                    Validators.required,
                    this.subDomainValidator
                ]
            ],
        });

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(({matchingAliases}) =>
            {
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    async createCompany()
    {
        const companyInitData = {
            ...this.firstFormGroup.value,
            ...this.secondFormGroup.value,
            language: this._translocoService.getActiveLang()
        };

        this.showLoader = true;
        const response = await firstValueFrom(
            this._companyInitService.registerCompany(companyInitData));

        if (response.StatusCode === HttpStatusCode.Found)
        {
            window.location.href = response.data;
        } else
        {
            this.showLoader = false;
            this.companyInitData = companyInitData;
        }
    }

    emailValidator (control: AbstractControl): Observable<ValidationErrors>
    {
        return timer(1000).pipe(
            filter(() => control.value != this._lastEmail),
            switchMap(() => this._companyInitService.checkUserEmail(control.value)),
            map(response =>
                response.StatusCode === HttpStatusCode.InternalServerError ? { usernameAlreadyExists: true } : null
            ),
            tap(() =>  this._lastEmail = control.value),
        );
    }

    subDomainValidator (control: AbstractControl): ValidationErrors
    {
        const invalid =
            control.value.length > 63
            || control.value.startsWith('-')
            || control.value.endsWith('-')
            || !/^[a-zA-Z0-9-]*$/.test(control.value)
            ;
        return invalid ? { invalidDomainName: true } : null;
    }

    openPolicyTermsDialog(dialogType: 'policy' | 'terms')
    {
        const dialogComponent = dialogType === 'policy' ? PolicyDialogComponent : TermsDialogComponent;
        this._dialog.open(
            dialogComponent,
            {
                panelClass:
                [
                    'max-w-full',
                    'w-11/12',
                    'h-auto'
                ]
            }
        );
    }

}
