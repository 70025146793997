import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { chats as chatsData, contacts as contactsData, messages as messagesData, profile as profileData } from 'app/mock-api/apps/chat/data';
import { assign, cloneDeep, omit } from 'lodash-es';
import { ChatService } from 'app/modules/admin/apps/chat/chat.service';
import { combineLatest,take } from 'rxjs';
import { EmployeesBrieflyService } from 'app/modules/share/services/employees-briefly.service';
@Injectable({providedIn: 'root'})
export class ChatMockApi
{
    private _chats: any[] =  []; //= chatsData;
    private _contacts: any[] =  [] ;//= contactsData;
    private _messages: any[] =  [];// = messagesData;
    private _profile: any; //= profileData ;

    /**
     * Constructor
     */
    constructor(
        private _employeesBrieflyService: EmployeesBrieflyService,
        private _fuseMockApiService: FuseMockApiService,
        private _chatService: ChatService,
    )
    {
        // Register Mock API handlers
        this.initializeData();
        this.registerHandlers();


        // Modify the chats array to attach certain data to it

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    private initializeData(): void {
        combineLatest([this._chatService.profile$, this._employeesBrieflyService.employees$, this._chatService.chats$])

            .subscribe(([profile, contacts, chats]) => {


                this._profile = profile;
                this._contacts = contacts;

                this._chats = chats?.map(chat => {
                    const contactForChat = contacts?.find(contact => contact.id === chat.contactId);

                    return {
                        ...chat,
                        contact: contactForChat,
                        messages: this._messages?.map(message => ({
                            ...message,
                            chatId: chat.id,
                            contactId: message.contactId === 'me' && this._profile ? this._profile.id : chat.contactId,
                            isMine: message.contactId === 'me',
                        })),

                    };
                });
                //this._chatService.updateChats(this._chats);

            });
    }
    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Chats - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/chat/chats')
            .reply(() =>
            {
                // Clone the chats
                const chats = cloneDeep(this._chats);

                // Return the response
                return [200, chats];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Chat - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/chat/chat')
            .reply(({request}) =>
            {
                // Get the chat id
                const id = request.params.get('id');

                // Clone the chats
                const chats = cloneDeep(this._chats);

                // Find the chat we need
                if (Array.isArray(chats)) {
                    const chat = chats.find(item => item.id === id);

                    // Return the response
                    return [200, chat];
                }
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Chat - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/chat/chat')
            .reply(({request}) =>
            {
                // Get the id and chat
                const id = request.body.id;
                const chat = cloneDeep(request.body.chat);

                // Prepare the updated chat
                let updatedChat = null;

                // Find the chat and update it
                this._chats.forEach((item, index, chats) =>
                {
                    if ( item.id === id )
                    {
                        // Update the chat
                        chats[index] = assign({}, chats[index], chat);

                        // Store the updated chat
                        updatedChat = chats[index];
                    }
                });

                // Return the response
                return [200, updatedChat];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Contacts - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/chat/contacts')
            .reply(() =>
            {
                // Clone the contacts
                let contacts = cloneDeep(this._contacts);

                // Sort the contacts by the name field by default
                contacts.sort((a, b) => a.name.localeCompare(b.name));

                // Omit details and attachments from contacts
                contacts = contacts.map(contact => omit(contact, ['details', 'attachments']));

                // Return the response
                return [200, contacts];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Contact Details - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/chat/contact')
            .reply(({request}) =>
            {
                // Get the contact id
                const id = request.params.get('id');

                // Clone the contacts
                const contacts = cloneDeep(this._contacts);

                // Find the contact
                const contact = contacts.find(item => item.id === id);

                // Return the response
                return [200, contact];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Profile - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/chat/profile')
            .reply(() =>
            {
                // Clone the profile
                const profile = cloneDeep(this._profile);

                // Return the response
                return [200, profile];
            });
    }
}
