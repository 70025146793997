<!--
<div class="fixed lg:sticky top-0 bottom-0 lg:left-full lg:w-16 lg:h-screen lg:shadow sm:w-96 w-full">
    <div
        class="flex flex-col h-full transition-transform duration-400 ease-drawer bg-card w-full sm:w-96 "
        [ngClass]="{'-translate-x-full sm:-translate-x-96 lg:-translate-x-80 shadow': opened, 'translate-x-0': !opened}">


        style="width: calc(100vw - 280px)"
        'width': opened ? 'calc(100vw - 280px)' : '100%'


                    'transform': openedExternal ? 'translateX(calc(280px + 4rem - 100vw))' : !open ? 'translateX(0)' : '',
            '--tw-translate-x': openedExternal ? 'calc(280px + 4rem - 100vw)' : '',
'width': openedExternal ? 'calc(100vw - 280px)' : '100%',
 -->
<div
    class="wrapper fixed lg:sticky top-0 bottom-0 lg:left-full w-full lg:shadow lg:w-16 lg:h-screen"
>
    <div
        class="container flex flex-col h-full transition-transform duration-500 ease-drawer bg-card relative"
        [ngStyle]="{
            'transform': isOpened ? 'translateX(calc(280px + 4rem - 100vw))' : 'translateX(0)',
            'width': isOpened ? 'calc(100vw - 280px)' : 'w-full',
        }"
        [ngClass]="{ 'shadow' : isOpened }"
    >
        <!-- External Chats Header  -->
        <div
            class="quick-chat-header flex flex-0 items-center justify-start cursor-pointer border-t"
            (click)="toggleExternal()"
            *ngIf="!opened"
        >
            <div class="flex flex-auto items-center justify-center">

                <div class="flex flex-0 items-center justify-center w-16 h-10 relative">
                    <ng-container *ngIf="(externalUnreadCount$ | async) > 0">
                        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
                            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-5 mt-2.5 rounded-full bg-green-500 text-indigo-50 text-xs font-medium">
                                {{ externalUnreadCount$ | async }}
                            </span>
                        </span>
                    </ng-container>
                    <mat-icon class="icon-size-6">forum</mat-icon>
                </div>

                <div class="text-lg font-medium text-secondary">
                    {{ 'chats.externalChats' | transloco }}
                </div>
                <button
                    class="ml-auto mr-4"
                    mat-icon-button>
                    <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>

            </div>
        </div>

        <!-- Quick Chats Header -->
        <div
            class="quick-chat-header flex flex-0 items-center justify-start cursor-pointer border-t"
            *ngIf="!openedExternal"
            (click)="toggle()"
        >
            <!-- Toggle -->
            <ng-container>
                <div class="flex flex-auto items-center justify-center" *ngIf="!opened || !selectedChat">
                    <div class="flex flex-0 items-center justify-center w-16 h-10 relative">
                        <ng-container *ngIf="(quickUnreadCount$ | async) > 0">
                            <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
                                <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-5 mt-2.5 rounded-full bg-green-500 text-indigo-50 text-xs font-medium">
                                    {{ quickUnreadCount$ | async }}
                                </span>
                            </span>
                        </ng-container>
                        <mat-icon
                            class="icon-size-6"
                            [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
                    </div>

                    <div class="text-lg font-medium text-secondary">
                        {{ 'chats.teamChats' | transloco }}
                    </div>
                    <button
                        class="ml-auto mr-4"
                        mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
            </ng-container>

            <!-- Contact info -->
            <ng-container *ngIf="opened && selectedChat">
                <div class="flex flex-auto items-center ml-3">
                    <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                        <ng-container *ngIf="selectedChat.contact?.avatar">
                            <img
                                class="w-full h-full rounded-full object-cover"
                                [src]="selectedChat?.contact?.avatar"
                                alt="Contact avatar"/>
                        </ng-container>
                        <ng-container *ngIf="!selectedChat?.contact?.avatar">
                            <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                {{ selectedChat.name?.charAt(0) }}
                            </div>
                        </ng-container>

                    </div>
                    <div class="ml-4 text-lg font-medium leading-5 truncate">
                        {{ selectedChat.name }}
                    </div>
                    <div class="ml-auto flex items-center">
                        <button
                            *ngIf="selectedChat?.contact?.phoneNumber"
                            class="ml-2"
                            (click)="makeCall($event, selectedChat?.contact?.phoneNumber)"
                            mat-icon-button>
                            <mat-icon [svgIcon]="'heroicons_outline:phone'"></mat-icon>
                        </button>
                        <button
                            class="mr-4"
                            mat-icon-button>
                            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- External Chats -->
        <app-external-chat-list
            class="h-full flex flex-auto border-t overflow-y-auto overflow-x-hidden"
            *ngIf="openedExternal"
        ></app-external-chat-list>

        <!-- Content -->
        <div
            class="h-full flex flex-auto border-t overflow-y-auto overflow-x-hidden"
            *ngIf="!openedExternal"
        >
            <!-- Quick Chat list -->
            <div
                class="flex-0 w-16 h-full overscroll-y-contain overflow-hidden sm:overscroll-y-auto"
                fuseScrollbar
                [fuseScrollbarOptions]="{wheelPropagation: false}">

                <app-chat-list
                    [chat$]="chat$"
                    [chats$]="chats$"
                    [onlyAvatars]="true"
                    (selected)="selectChat($event)"
                ></app-chat-list>

            </div>
            <div class="h-full flex flex-col flex-auto border-l bg-gray-50 dark:bg-transparent items-center">
                <ng-container *ngIf="selectedChat; else selectChatOrStartNew">
                    <div class="w-full flex-auto shrink py-4 px-8 overflow-y-auto">
                        <app-chat-message-list
                            [canEditLast]="true"
                            [messages]="chat.messages"
                            [lastEditDisabled]="!!originalMessage"
                            (onMessageEdit)="onMessageEdit($event)"
                            (onMessageDelete)="onMessageDelete($event)"
                        ></app-chat-message-list>
                    </div>
                    <div class="w-full p-4 border-t bg-gray-50 dark:bg-transparent">
                        <app-chat-message-editor
                            [messageText]="messageText"
                            [editMode]="!!originalMessage"
                            (send)="onSendMessage($event)"
                            (cancel)="clearMessageState()"
                        ></app-chat-message-editor>
                    </div>

                </ng-container>
            </div>

        </div>
    </div>
</div>

<!-- Select chat or start new template -->
<ng-template #selectChatOrStartNew>
    <div *ngIf="isOpened"
        class="flex flex-col flex-auto shrink items-center justify-center w-full h-full p-4 overflow-y-auto overscroll-y-contain"
    >
        <mat-icon
            class="icon-size-24"
            [svgIcon]="'heroicons_outline:chat-bubble-bottom-center-text'"></mat-icon>
        <div class="mt-4 text-xl text-center font-medium tracking-tight text-secondary">
            {{ 'chats.selectChat' | transloco }}
        </div>
    </div>
</ng-template>

<!-- Speech bubble tail SVG -->
<!-- @formatter:off -->
<ng-template #speechBubbleExtension>
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z" fill="currentColor" fill-rule="nonzero"></path>
        </g>
    </svg>
</ng-template>
<!-- @formatter:on -->

<app-image-review-popup #imagePopup></app-image-review-popup>
