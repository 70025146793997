import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule, DOCUMENT, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, inject, Renderer2, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TranslocoModule } from '@ngneat/transloco';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ChatWsService } from 'app/modules/admin/apps/chat/chat-ws.service';
import { Chat, ChatType } from 'app/modules/admin/apps/chat/chat.types';
import { AudioCallService } from 'app/modules/share/components/audio-call/audio-call.service';
import { ChatListComponent } from 'app/modules/share/components/chats/chat-list/chat-list.component';
import { ChatMessageEditorComponent } from 'app/modules/share/components/chats/chat-message-editor/chat-message-editor.component';
import { ChatMessageListComponent } from 'app/modules/share/components/chats/chat-message-list/chat-message-list.component';
import { FileUploadComponent } from 'app/modules/share/components/file-upload/file-upload.component';
import { EmojiPickerComponent } from 'app/modules/share/components/picker/emoji-picker/emoji-picker.component';
import { ImageReviewPopupComponent } from 'app/modules/share/components/popup/image-review-popup/image-review-popup.component';
import { map, Observable, shareReplay, tap } from 'rxjs';
import { AbstractChatConversationComponent } from '../../../modules/admin/apps/chat/conversation/abstract-conversation.component';
import { ExternalChatListComponent } from './external-chat-list/external-chat-list.component';
import { ExternalChatService } from './external-chat.service';

@Component({
    selector     : 'quick-chat',
    templateUrl  : './quick-chat.component.html',
    styleUrls    : ['./quick-chat.component.scss'],
    encapsulation: ViewEncapsulation.None,
    exportAs     : 'quickChat',
    standalone   : true,
    imports      : [
        CommonModule,
        FormsModule,
        NgTemplateOutlet,
        TextFieldModule,
        TranslocoModule,

        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,

        FuseScrollbarDirective,

        ChatListComponent,
        ChatMessageEditorComponent,
        ChatMessageListComponent,
        EmojiPickerComponent,
        ExternalChatListComponent,
        ImageReviewPopupComponent,
        FileUploadComponent,

    ],
})
export class QuickChatComponent extends AbstractChatConversationComponent implements AfterViewInit
{
    _chatService = inject(QuickChatService);
    _externalChatService = inject(ExternalChatService);

    chat$ = this._chatService.chat$;
    chats$ = this._chatService.chats$
        .pipe(
            map(chats => chats.slice(0, 10))
        );

    quickUnreadCount$: Observable<number>;
    externalUnreadCount$: Observable<number>;

    private _mutationObserver: MutationObserver;
    private _scrollStrategy: ScrollStrategy = this._scrollStrategyOptions.block();
    private _overlay: HTMLElement;

    get isOpened(): boolean
    {
        return this.opened || this.openedExternal;
    }
    get opened(): boolean
    {
        return this._chatService.opened;
    }

    get openedExternal(): boolean
    {
        return this._externalChatService.opened;
    }

    get selectedChat(): Chat
    {
        return this._chatService.chat;
    }

    get isMessengerChatType()
    {
        return this.selectedChat.type === ChatType.messenger;
    }

    get isInternalChat(): boolean
    {
        return [ChatType.single, ChatType.messenger].includes(this.selectedChat.type);
    }

    get lastEditDisabled(): boolean
    {
        return this.isInternalChat && !!this.originalMessage;
    }

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _elementRef: ElementRef,
        private _renderer2: Renderer2,
        private _chatWsService: ChatWsService,
        private _audioCallService: AudioCallService,
        private _scrollStrategyOptions: ScrollStrategyOptions,
        private _changeDetection: ChangeDetectorRef,
    )
    {
        super();
        this.quickUnreadCount$ = this._chatService.unreadCounter.unreadCount$.pipe(shareReplay(1));
        this.externalUnreadCount$ = this._externalChatService.unreadCounter.unreadCount$.pipe(shareReplay(1));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Decorated methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any
    {
        return {
            'quick-chat-opened': this.isOpened,
        };
    }

    /**
     * Resize on 'input' and 'ngModelChange' events
     *
     * @private
     */
/*     @HostListener('input')
    @HostListener('ngModelChange')
    private _resizeMessageInput(): void
    {
        // This doesn't need to trigger Angular's change detection by itself
        this._ngZone.runOutsideAngular(() =>
        {
            setTimeout(() =>
            {
                // Set the height to 'auto' so we can correctly read the scrollHeight
                this.messageInput.nativeElement.style.height = 'auto';

                // Get the scrollHeight and subtract the vertical padding
                this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;
            });
        });
    } */

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
/*     ngOnInit(): void
    {
        this._chatService.chat$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async chat =>
            {
                if(chat)
                {
                    const messages = await firstValueFrom(this._chatService.getMessages(chat.id));
                    this._chatService.chat = { ...chat, messages };
                    this.selectedChat.unreadCount &&
                        await this._chatService.updateMessagesStatus(this.selectedChat);
                }
            });
    } */

    /**
     * After view init
     */
    ngAfterViewInit(): void
    {
        // Fix for Firefox.
        //
        // Because 'position: sticky' doesn't work correctly inside a 'position: fixed' parent,
        // adding the '.cdk-global-scrollblock' to the html element breaks the navigation's position.
        // This fixes the problem by reading the 'top' value from the html element and adding it as a
        // 'marginTop' to the navigation itself.
        this._mutationObserver = new MutationObserver((mutations) =>
        {
            mutations.forEach((mutation) =>
            {
                const mutationTarget = mutation.target as HTMLElement;
                if ( mutation.attributeName === 'class' )
                {
                    if ( mutationTarget.classList.contains('cdk-global-scrollblock') )
                    {
                        const top = parseInt(mutationTarget.style.top, 10);
                        this._renderer2.setStyle(this._elementRef.nativeElement, 'margin-top', `${Math.abs(top)}px`);
                    }
                    else
                    {
                        this._renderer2.setStyle(this._elementRef.nativeElement, 'margin-top', null);
                    }
                }
            });
        });
        this._mutationObserver.observe(this._document.documentElement, {
            attributes     : true,
            attributeFilter: ['class'],
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        console.log('Quick Chat - ngOnDestroy');

        super.ngOnDestroy();

        // Disconnect the mutation observer
        this._mutationObserver.disconnect();
    }

    isImage(fileType: string): boolean
    {
        return fileType && fileType.split('/')[0] === 'image';
    }

    toggleExternal(open?: boolean): void
    {
        const state = open ?? !this.openedExternal;
        if(state !== this._externalChatService.opened)
        {
            this._externalChatService.opened = state;
            this._toggleOverlay(state);
        }
    }

    toggle(open?: boolean): void
    {
        const state = open ?? !this.opened;
        if(state !== this._chatService.opened)
        {
            this._chatService.opened = state;
            this._toggleOverlay(state);
        }
    }

    makeCall(e: Event, phoneNumber: string): void
    {
        e.stopPropagation()
        this._audioCallService.openCallDialog(phoneNumber)
    }

    selectChat(chat: Chat)
    {

        // Open the panel
        this.toggle(true);

        // Get the chat data
        this._chatService.chat = chat;
        this._chatWsService.updateChatMessages(this._chatService);

    }

    selectExternalChat(chat: Chat)
    {
        // Open the panel
        this.toggleExternal(true);

        // Get the chat data
        this._externalChatService.chat = chat;
        this._chatWsService.updateChatMessages(this._externalChatService);
    }

    /**
     * Create message
     */
/*     async sendMessage(chatMessage: HTMLTextAreaElement)
    {
        const messages = await firstValueFrom(this._quickChatService.sendMessage(
            this.selectedChat, chatMessage.value, this.file?.base64Data
        ));
        chatMessage.value = '';
        this.selectedChat.messages = messages;
        this._changeDetectorRef.markForCheck();
} */

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

/*     onMessageEdit(message: ChatMessage)
    {
        this.originalMessage = cloneDeep(message);
        this.messageInput.nativeElement.value = message.value;
        this.messageInput.nativeElement.focus();
    } */

/*     private clearMessageState()
    {
        this.originalMessage = null;
        this.file = null;
        this.messageInput.nativeElement.value = null;
    } */

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the backdrop
     *
     * @private
     */
    private _showOverlay(): void
    {
        // Try hiding the overlay in case there is one already opened
        this._hideOverlay();

        this._changeDetectorRef.markForCheck();

        setTimeout(() => {
            // Create the backdrop element
            this._overlay = this._renderer2.createElement('div');

            // Return if overlay couldn't be create for some reason
            if ( !this._overlay )
            {
                return;
            }

            // Add a class to the backdrop element
            this._overlay.classList.add('quick-chat-overlay');

            // Append the backdrop to the parent of the panel
            this._renderer2.appendChild(this._elementRef.nativeElement.parentElement, this._overlay);

            // Enable block scroll strategy
            this._scrollStrategy.enable();

            // Add an event listener to the overlay
            this._overlay.addEventListener('click', () =>
            {
                this.toggle(false);
            });
        });
    }

    /**
     * Hide the backdrop
     *
     * @private
     */
    private _hideOverlay(): void
    {
        if ( !this._overlay )
        {
            return;
        }

        // If the backdrop still exists...
        if ( this._overlay )
        {
            // Remove the backdrop
            this._overlay.parentNode.removeChild(this._overlay);
            this._overlay = null;
        }

        // Disable block scroll strategy
        this._scrollStrategy.disable();
    }

/*     private _toggleOpened(open: boolean): void
    {
        // Set the opened
        this._chatService.opened = open;

        this._toggleOverlay(open);

    } */

    private _toggleOverlay(open: boolean)
    {
        setTimeout(() => {

            // If the panel opens, show the overlay
            if ( open )
            {
                this._showOverlay();
            }
            // Otherwise, hide the overlay
            else
            {
                this._hideOverlay();
            }
        });
    }
}
