import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientService } from 'app/modules/share/services/http-client.service';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { ProjectStatistic, ProjectType } from './project.types';

@Injectable({ providedIn: 'root' })
export class ProjectService {
    private _data$ = new BehaviorSubject<any>(null);
    private _statistic$ = new BehaviorSubject<ProjectStatistic[]>(null);
    private _selectedProjectStatistic$ = new BehaviorSubject<ProjectStatistic>(null);
    private _projects$ = new BehaviorSubject<ProjectType[]>([]);

    /**
     * Constructor
     */
    constructor(
        private _http: HttpClient,
        private _httpService: HttpClientService,
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any> {
        return this._data$.asObservable();
    }

    get statistic(): ProjectStatistic[] {
        return this._statistic$.getValue();
    }

    get statistic$(): Observable<ProjectStatistic[]> {
        return this._statistic$.asObservable();
    }

    get selectedProjectStatistic(): ProjectStatistic {
        return this._selectedProjectStatistic$.getValue();
    }

    get selectedProjectStatistic$(): Observable<ProjectStatistic> {
        return this._selectedProjectStatistic$.asObservable();
    }

    get projects(): ProjectType[] {
        return this._projects$.getValue();
    }

    get projects$(): Observable<ProjectType[]> {
        return this._projects$.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(): Observable<any> {
        return this._http.get('api/dashboards/project').pipe(tap(response => this._data$.next(response)));
    }

    getStatistic(): Observable<ProjectStatistic[]> {
        return this._httpService.get<ProjectStatistic[]>('/api/GetStatistic').pipe(
            tap(response => {
                this._statistic$.next(response);
                this._selectedProjectStatistic$.next(response[0]);
            }),
        );
    }

    cancelSending(jobId: string): Observable<{ message: string }> {
        return this._httpService.post<{ message: string }>('/api/CancelSendMail', { jobId });
    }

    getProjects(): Observable<ProjectType[]> {
        return this._httpService.get<ProjectType[]>('/api/GetProjects').pipe(tap(response => this._projects$.next(response)));
    }

    createProject(project: ProjectType): Observable<ProjectStatistic[]> {
        return this._httpService
            .post<ProjectStatistic[]>('/api/createProject', { project })
            .pipe
            // tap(response => this._projects$.next(response)),
            ();
    }

    updateProject(project: ProjectType): Observable<ProjectStatistic[]> {
        return this._httpService
            .post<ProjectStatistic[]>('/api/updateProject', { project })
            .pipe
            // tap(response => this._projects$.next(response)),
            ();
    }

    selectProject(id?: number) {
        if (id) {
            this._selectedProjectStatistic$.next(this.statistic.find(item => item.projectId === id));
        } else {
            this._selectedProjectStatistic$.next(this.statistic[0]);
        }
    }
}
