import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';

@Component({
    selector: 'app-terms-dialog',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,

        MatDialogModule,
        MatButtonModule,
        MatDividerModule,

    ],
    templateUrl: './terms-dialog.component.html',
    styleUrls: ['terms-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsDialogComponent
{
}
