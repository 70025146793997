import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { PopupComponent } from '../popup.component';
import { ConnectedPosition, OverlayConfig } from '@angular/cdk/overlay';

@Component({
    selector: 'app-abstract-popup',
    standalone: true,
    imports: [],
    template: '',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class AbstractPopupComponent
{
    @ViewChild('popup', {static: true}) popup: PopupComponent;
    @Input() connectedTo: ElementRef = {} as ElementRef;
    @Input() data: any;

    protected _withPositions: ConnectedPosition;
    protected _config: OverlayConfig;
    protected _changeDetector = inject(ChangeDetectorRef);

    beforeOpen(){}

    openConnected(connectedTo?: ElementRef, data?: any)
    {
        connectedTo && (this.connectedTo = connectedTo);
        this.open(data);
    }

    open(data?: any)
    {
        data && (this.data = data);

        this.beforeOpen();

        this.popup.open(this.connectedTo, this._config, this._withPositions);
        this._changeDetector.markForCheck();
    }

    close()
    {
        this.data = null;
        this.popup.close();
    }
}
