import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ChatListComponent } from 'app/modules/share/components/chats/chat-list/chat-list.component';
import { Chat } from 'app/modules/admin/apps/chat/chat.types';
import { Observable, shareReplay } from 'rxjs';
import { ExternalChatService } from '../external-chat.service';
import { ChatWsService } from 'app/modules/admin/apps/chat/chat-ws.service';
import { AbstractChatConversationComponent } from 'app/modules/admin/apps/chat/conversation/abstract-conversation.component';
import { ChatMessageEditorComponent } from 'app/modules/share/components/chats/chat-message-editor/chat-message-editor.component';
import { ImageReviewPopupComponent } from 'app/modules/share/components/popup/image-review-popup/image-review-popup.component';
import { MatIconModule } from '@angular/material/icon';
import { ChatMessageListComponent } from 'app/modules/share/components/chats/chat-message-list/chat-message-list.component';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-external-chat-list',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,

        MatIconModule,

        ChatListComponent,
        ChatMessageEditorComponent,
        ChatMessageListComponent,
        ImageReviewPopupComponent,

        FuseScrollbarDirective,
    ],
    templateUrl: './external-chat-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalChatListComponent extends AbstractChatConversationComponent
{
    chats$: Observable<Chat[]>;
    chat$: Observable<Chat>;

    protected _chatService = inject(ExternalChatService);

    constructor(
        private _chatWsService: ChatWsService,
    )
    {
        super();
        this.chat$ = this._chatService.chat$.pipe(shareReplay(1));
        this.chats$ = this._chatService.chats$.pipe(shareReplay(1));
    }

    selectChat(chat: Chat)
    {
        this._chatService.chat = chat;
        this._chatWsService.updateChatMessages(this._chatService);
    }

}
