import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { Contact } from 'app/modules/admin/apps/contacts/contacts.types';
import { EmployeesBrieflyService } from 'app/modules/share/services/employees-briefly.service';
import { Dictionary, cloneDeep, keyBy, mapValues } from 'lodash';
import { EmployeeBriefly } from '../../../global.types';
import { BasePickerComponent } from '../base-picker.component';

type ContactCheck = Contact & {
    checked: boolean;
}
@Component({
    selector: 'app-contact-picker',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,

        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
    ],
    templateUrl: './contact-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactPickerComponent extends BasePickerComponent implements OnInit
{
    @Input() multiselect = true;
    @Input() contacts: ContactCheck[];
    @Input() contactsSelected: ContactCheck[] = [];
    @Output() selectEvent = new EventEmitter<ContactCheck | ContactCheck[]>();
    @Output() closeEvent = new EventEmitter<void>();

    @ViewChild('contactsPanel') private _contactsPanelRef: TemplateRef<any>;

    currentContactIdx = 0;
    contactsSelectedMap: Dictionary<EmployeeBriefly>;
    filterText: string;

    constructor(
        private _employees: EmployeesBrieflyService,
    ) {
        super();
    }

    get focusOnInput()
    {
        return true;
    }

    get pickerPanelRef(): TemplateRef<any>
    {
        return this._contactsPanelRef;
    }

    get contactsList(): ContactCheck[]
    {
        return (!!this.filterText
            ? this.contacts.filter(contact =>
                    contact.fullName && contact.fullName.toLowerCase().includes(this.filterText.toLowerCase()))
            : this.contacts);
    }

    ngOnInit()
    {
        this._initContacts();
    }

    onChange(contact: ContactCheck)
    {
        if(this.multiselect)
        {
            contact.checked = !contact.checked;
            this.selectEvent.emit(this.contacts.filter(contact => contact.checked));
        }
        else
        {
            this.selectEvent.emit(contact);
            this.close();
        }
    }

    close()
    {
        this.closeEvent.emit();
        super.close();
    }

    onKeyDown(event: KeyboardEvent): void
    {
        if (event.key === 'Enter')
        {
            this.onChange(this.contacts[this.currentContactIdx]);
        }
        else if (event.key === 'ArrowUp')
        {
            this.currentContactIdx > 0 && this.currentContactIdx--;
        }
        else if (event.key === 'ArrowDown')
        {
            this.currentContactIdx < this.contactsList.length - 1 && this.currentContactIdx++;
        }
        else if (event.key === 'Escape')
        {
            this.close();
        }
        else
        {
            this.currentContactIdx = 0;
        }
    }

    private _initContacts()
    {

        if(!this.contacts)
        {
            this.contacts = this._employees.employees as ContactCheck[];
        }

        if(this.multiselect)
        {
            this.contactsSelectedMap = mapValues(keyBy(this.contactsSelected, 'id'));
            this.contacts = cloneDeep(this.contacts);
            this.contacts.forEach(contact => contact.checked = !!this.contactsSelectedMap[contact.id]);
        }
    }
}
