import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TranslocoModule } from '@ngneat/transloco';
import { Chat, ChatType } from 'app/modules/admin/apps/chat/chat.types';
import { CHAT_NOTIFICATION_BADGE_CLASS, DATE_TIME_FORMAT } from 'app/modules/share/global.constants';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

@Component({
    selector: 'app-chat-list',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,

        FuseScrollbarDirective,
    ],
    templateUrl: './chat-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatListComponent
{
    @Input() chats$: Observable<Chat[]>;
    @Input() chat$: Observable<Chat>;
    @Input() hideAvatars = true;
    @Input() onlyAvatars = false;
    @Output() selected = new EventEmitter();

    selectedChat: Chat;
    chatsFiltered$: Observable<Chat[]>;
    filter$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    CHAT_NOTIFICATION_BADGE_CLASS = CHAT_NOTIFICATION_BADGE_CLASS;
    DATE_TIME_FORMAT = DATE_TIME_FORMAT;
    ChatType = ChatType;

    private _destroyRef = inject(DestroyRef);

    constructor(){}

    ngOnInit(): void
    {

        this.chat$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(chat => this.selectedChat = chat);

        this.chatsFiltered$ = combineLatest([
            this.chats$,
            this.filter$,
        ], (chats, query) =>
            chats.filter(chat =>
                {
                    const chatName = (chat.type === ChatType.single ? chat.contact?.fullName : chat.name) || '';
                    return chatName.toLowerCase().includes(query.toLowerCase());
                })
        );
    }

    selectChat(chat: Chat)
    {
        this.selected.emit()
    }
}
