import { Injectable } from '@angular/core';
import { ChatService } from 'app/modules/admin/apps/chat/chat.service';
import { EmployeeBriefly } from 'app/modules/share/global.types';
import { BehaviorSubject, map, Observable, of, tap, throwError } from 'rxjs';
import { Chat, ChatType } from "../../../modules/admin/apps/chat/chat.types";
import { IQuickChatService } from './IQuickChatService';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({providedIn: 'root'})
export class QuickChatService extends ChatService implements IQuickChatService
{
    private _opened$ = new BehaviorSubject(false);

    constructor(
        private _chatService: ChatService,
    )
    {
        super();

        this._chatService.allChats$
            .pipe(
                takeUntilDestroyed(),
                map(chats => chats.filter(this.chatFilter)),
                tap(chats => this.chats = chats)
            ).subscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get opened(): boolean
    {
        return this._opened$.getValue();
    }

    set opened(opened: boolean)
    {
        this._opened$.next(opened);
    }

}
