<!-- Chats -->
<div class="flex-auto overflow-y-auto">
    <ng-container *ngIf="chatsFiltered$ | async as chatsFiltered; else noChats">
        <ng-container *ngFor="let chat of chatsFiltered; trackBy: trackByFn">
<!--                  <a
                class="z-20 flex items-center py-5 px-8 cursor-pointer border-b"
                [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedChat || selectedChat.id !== chat.id,
                            'bg-primary-50 dark:bg-hover': selectedChat && selectedChat.id === chat.id}"
                [routerLink]="[chat.id]" [state]="{ pageNavigation: true }"
        > -->
            <div
                class="flex items-center py-4 px-4 cursor-pointer border-b"
                [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedChat || selectedChat.id !== chat.id,
                        'bg-primary-50 dark:bg-hover': selectedChat && selectedChat.id === chat.id}"
                (click)="selected.emit(chat)"
            >
                <div
                    *ngIf="!hideAvatars || onlyAvatars"
                    class="relative flex flex-0 items-center justify-center w-8 h-8"
                >
                    <ng-container *ngIf="chat?.contact?.avatar">
                        <img
                            class="w-full h-full rounded-full object-cover"
                            [src]="chat?.contact?.avatar"
                            alt="Contact avatar"/>
                    </ng-container>
                    <ng-container *ngIf="!chat?.contact?.avatar">
                        <div class="flex items-center justify-center w-full h-full rounded-full text-lg pt-1
                            uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                        >
                            {{ chat.name?.charAt(0) }}
                        </div>
                    </ng-container>
                </div>

                <ng-container *ngIf="!onlyAvatars">
                    <div class="min-w-0 ml-6">
                        <div class="font-medium leading-5 truncate">

                            {{ chat.name }}

                            <span *ngIf="chat.type === ChatType.group" class="text-sm">
                                {{ 'chats.members' | transloco }}: {{ chat.contacts?.length + 1}}
                            </span>
                        </div>
                        <div
                            class="leading-5 truncate text-secondary"
                            [class.text-primary]="chat.unreadCount > 0"
                            [class.dark:text-primary-500]="chat.unreadCount > 0"
                            [innerHTML]="chat?.lastMessage"
                            >
                        </div>
                    </div>
                    <div class="flex flex-col items-end self-start ml-auto pl-2 pr-4">
                        <div class="text-sm leading-5 text-secondary text-right">
                            {{ chat?.lastMessageAt | date: DATE_TIME_FORMAT }}
                        </div>
                        <ng-container *ngIf="chat?.muted">
                            <mat-icon
                                class="icon-size-5 text-hint"
                                [svgIcon]="'heroicons_solid:speaker-x-mark'"></mat-icon>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="chat.unreadCount > 0">
                    <div class="fuse-vertical-navigation-item-badge z-10 -ml-4 -mt-6">
                        <div class="fuse-vertical-navigation-item-badge-content {{ CHAT_NOTIFICATION_BADGE_CLASS }}">
                            {{ chat.unreadCount }}
                        </div>
                    </div>
                    <!-- <div
                        class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                        [class.ring-primary-50]="selectedChat && selectedChat.id === chat.id"></div> -->
                </ng-container>

            </div>
        </ng-container>
    </ng-container>
</div>

<!-- No chats template -->
<ng-template #noChats>
    <div class="flex flex-auto flex-col items-center justify-center h-full">
        <mat-icon
            class="icon-size-24"
            [svgIcon]="'heroicons_outline:chat-bubble-oval-left-ellipsis'"></mat-icon>
        <div class="mt-4 text-2xl font-semibold tracking-tight text-secondary">
            {{ 'chats.noChats' | transloco }}
        </div>
    </div>
</ng-template>
