import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseLoadingService } from '@fuse/services/loading';
import { TranslocoModule } from '@ngneat/transloco';
import { CHAT_MAX_FILE_SIZE_MB } from 'app/modules/share/global.constants';
import { EmployeeBriefly } from 'app/modules/share/global.types';
import { IMAGE_MIME_TYPES } from 'app/modules/share/mime-types';
import { FileUploadComponent } from '../../file-upload/file-upload.component';
import { UploadFileData } from '../../file-upload/file-upload.type';
import { MessageEdit } from '../../messages/messages.types';
import { ContactPickerComponent } from '../../picker/contact-picker/contact-picker.component';
import { EmojiPickerComponent } from '../../picker/emoji-picker/emoji-picker.component';
import { ImageReviewPopupComponent } from '../../popup/image-review-popup/image-review-popup.component';

@Component({
    selector: 'app-chat-message-editor',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslocoModule,

        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,

        ContactPickerComponent,
        EmojiPickerComponent,
        ImageReviewPopupComponent,
        FileUploadComponent,
    ],
    templateUrl: './chat-message-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageEditorComponent implements AfterViewInit
{
    @ViewChild('messageTextArea') messageTextAreaRef: ElementRef;
    @ViewChild('contactPicker') contactPicker: ContactPickerComponent;

    //@Input() chat: Chat;
    @Input() messageText = '';
    @Input() parentMessage: any;
    @Input() editMode = false;
    @Input() quickMode = false;
    @Input() contacts: EmployeeBriefly[];
    @Input() canTagEmployee = false;

    @Output() send = new EventEmitter<MessageEdit>();
    @Output() cancel = new EventEmitter();

    file: UploadFileData;
    members: EmployeeBriefly[] = [];

    CHAT_MAX_FILE_SIZE_MB = CHAT_MAX_FILE_SIZE_MB;

    private _fuseLoadingService = inject(FuseLoadingService);
    loading$ = this._fuseLoadingService.show$;

    get isMessageFileImage(): boolean
    {
        return !!this.file && IMAGE_MIME_TYPES.includes(this.file.file.type);
    }

    ngAfterViewInit() {
        // this.messageTextAreaRef.nativeElement.focus();
    }

    addEmojiToMessage(emoji: string)
    {
        const content = this.messageText.split('');
        content.splice(this.messageTextAreaRef.nativeElement.selectionStart, 0, emoji);
        this.messageText = content.join('');
    }

    onContactSelected(contact: EmployeeBriefly): void
    {
        this.members.push(contact);

        const content = this.messageText.split('');
        const { selectionStart } = this.messageTextAreaRef.nativeElement;
        content.splice(selectionStart - 1, 1, ' ' + contact.fullName + ' ');
        this.messageText = content.join('');
    }

    onContactPickerClose()
    {
        setTimeout(() => {
            this.messageTextAreaRef.nativeElement.focus();
        });
    }

    onKeyDown(event: KeyboardEvent): void
    {
        if (event.key === 'Enter') {
            if(!event.ctrlKey && !event.shiftKey)
            {
                this.sendMessage();
            }
        }
        else if (event.key === '@')
        {
            if(this.canTagEmployee)
            {
                setTimeout(_ => this.contactPicker.open(), 0);
            }
        }
        else if (event.key === 'Escape')
        {
            this.contactPicker.close();
            this.clearMessageState();
        }
    }

    onPaste(event: ClipboardEvent)
    {
        const clipboardData = event.clipboardData || window['clipboardData'];
        const file: File = clipboardData.files[0];
        if (!!file)
        {
            this._fuseLoadingService.show();
            const reader = new FileReader();
            reader.onerror = () => this._fuseLoadingService.hide();
            reader.onload = (e: any) => {
                this.setFile({
                    file: file,
                    base64Data: e.target.result,
                });
                this._fuseLoadingService.hide();
            };
            reader.readAsDataURL(file);
        }
    }

    setFile(file: UploadFileData)
    {
        this.file = file;
    }

    sendMessage()
    {
        this.messageTextAreaRef.nativeElement.blur();

        this.send.emit({
            text: this.messageText,
            file: this.file,
            members: this.members,
        });

        this.clearMessageState();
    }

    clearMessageState()
    {
        this.file = null;
        this.messageText = '';
        this.cancel.emit();
    }

    scrollToComment(id: string)
    {
        const element = document.getElementById(`item-${id}`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

}
