import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from 'app/core/user/user.service';
import { HttpClientService } from 'app/modules/share/services/http-client.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguagesService
{

    private _localeMap = {
        'ua': 'uk',
        'en': 'en'
    }

    private _countryMap = {
        'ua': 'ua',
        'en': 'us'
    }

    constructor(
        private _httpClient: HttpClientService,
        private _translocoService: TranslocoService,
        private _userService: UserService) { }

    async setNLS(language: string)
    {
        await firstValueFrom(
            this._httpClient.post('/api/setNLS', {
                language,
                email: this._userService.user.email
            })
        );

    }

    get currentLocate(): string
    {
        return this.getLocale(this._translocoService.getActiveLang());
    }

    getLocale(lang: string): string
    {
        return this._localeMap[lang];
    }

    getISOCountry(lang: string): string
    {
        return this._countryMap[lang];
    }

}
