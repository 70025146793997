import { ChangeDetectionStrategy, Component, Inject, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snack-bar',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,

        MatButtonModule,
        MatIconModule,
    ],
    templateUrl: './snack-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackBarComponent
{
    template: TemplateRef<any>;

    constructor(
        public snackBarRef: MatSnackBarRef<SnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: { template: TemplateRef<any> }
    )
    {
        this.template = data.template;
    }
}
