<div class="">
    <input
        #fileUpload
        accept="accept"
        type="file"
        class="hidden"
        [multiple]="multiple"
        (change)="onFileChange($event)"
    >
    <div class="flex" *ngIf="!hideButton" [matTooltip]="'selectFile' | transloco">
        <button mat-icon-button class="flex items-center" (click)="fileUpload.click()">
            <!-- [svgIcon]="attach_file" -->
            <mat-icon [class]="iconClass" [svgIcon]="icon || 'heroicons_outline:paper-clip'"></mat-icon>
        </button>
    </div>
</div>
