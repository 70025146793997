<button
    #emojiPickerButton
    mat-icon-button
    (click)="open()"
>
    <mat-icon [class]="iconClass" [svgIcon]="'heroicons_outline:face-smile'"></mat-icon>
</button>

<ng-template #emojiPickerPanel>

    <div class="emoji-container relative">
        <div class="emoji-panel flex flex-wrap gap-2 p-3 border max-w-50 bg-accent-50">
            <span
                class="emoji-icon text-2xl cursor-pointer"
                *ngFor="let emoji of emojis"
                (click)="onEmojiSelect.emit(emoji)"
            >
                {{ emoji }}
            </span>
        </div>
    </div>

</ng-template>
