import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmployeeBriefly } from 'app/modules/share/global.types';
import { debounceTime, map, Observable, of } from 'rxjs';
import { IntegrationsService } from '../../integrations.service';
import { Integration, IntegrationSubType } from '../../integrations.types';
import { isArray } from 'lodash';

@Component({
    selector: 'app-base-dialog',
    standalone: true,
    imports: [CommonModule],
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class BaseDialogComponent<T>
{
    form: UntypedFormGroup;
    readonly data: any = inject(MAT_DIALOG_DATA);

    protected _dialogRef = inject(MatDialogRef<T>);
    protected _integrations = inject(IntegrationsService);
    protected _formBuilder = inject(UntypedFormBuilder);

    abstract get integrationSubType(): IntegrationSubType;

    get isNew(): boolean
    {
        return this.data.isNew;
    }

    get f()
    {
        return this.form.controls;
    }

    nameUniqValidator (control: AbstractControl): Observable<ValidationErrors>
    {
        if(control.value === this.data.name)
        {
            return of({});
        }
        return this._integrations.isNameUniq(control.value, this.integrationSubType)
            .pipe(
                debounceTime(1000),
                map((integrations: Integration[]) => {
                    return integrations.length
                        ? { 'nameIsNotUniq' : true }
                        : null
                })
            );
    }

    save(access?: EmployeeBriefly[])
    {
        const integrationType = this.data.isNew
            ? this._integrations.getIntegrationType(this.integrationSubType)
            : this.data.integrationType;

        this._dialogRef.close(
        {
            ...this.data,
            ...this.form.value,
            integrationType,
            integrationSubType: this.integrationSubType,
            access,
        });
    }

    protected withRequiredValidator(validators: ValidatorFn | ValidatorFn[] = []): ValidatorFn | ValidatorFn[]
    {
        if(!this.isNew)
        {
            return  validators;
        }
        return [].concat(validators, Validators.required);
    }




}
