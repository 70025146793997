import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientService } from 'app/modules/share/services/http-client.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
@Injectable({providedIn: 'root'})

export class ProjectService
{
    private _data$ = new BehaviorSubject<any>(null);
    private _statistic$ = new BehaviorSubject<any>(null);

    /**
     * Constructor
     */
    constructor(
        private _http: HttpClient,
        private _httpService: HttpClientService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any>
    {
        return this._data$.asObservable();
    }

    get statistic(): any
    {
        return this._statistic$.getValue();
    }

    get statistic$(): Observable<any>
    {
        return this._statistic$.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(): Observable<any>
    {
        return this._http.get('api/dashboards/project')
            .pipe(
                tap(response => this._data$.next(response)),
            );
    }

    getStatistic(): Observable<any>
    {
        return this._httpService.get('/api/GetStatistic')
            .pipe(
                tap(response => this._statistic$.next(response)),
            );
    }

    cancelSending(jobId: string): Observable<{ message: string }>
    {
        return this._httpService.post<{ message: string }>('/api/CancelSendMail', { jobId });
    }
}
