<div class="w-full">

    <h1 class="font-medium text-xl py-4">
        {{ 'companyInit.title' | transloco }}
    </h1>
    <p class="my-4">
        {{ 'companyInit.text' | transloco}}
    </p>

    <ng-container *ngIf="!!companyInitData then created; else init"></ng-container>

    <ng-template #created>
        <div class="mat-horizontal-content-container">
            <div class="my-4 text-lg font-bold">
                {{ 'companyInit.companyCrated' |  transloco : { companyName: companyInitData.companyName } }}
            </div>

            <mat-divider class="mb-6"></mat-divider>

            <div class="font-medium my-2">
                {{ 'companyInit.email' | transloco }}
            </div>
            <div class="italic">
                {{ companyInitData.email }}
            </div>
            <div class="font-medium my-2">
                {{ 'companyInit.subdomainName' | transloco }}
            </div>
            <div class="italic">
                {{ companyInitData.companyDomain }}
            </div>

            <mat-divider class="my-6"></mat-divider>

        </div>
    </ng-template>

    <ng-template #init>

        <mat-stepper
            #stepper
            linear
            class="register-form-tab-group-xxx"
            [orientation]="isScreenSmall ? 'vertical' : 'horizontal'"
        >
            <mat-step [stepControl]="firstFormGroup" label="{{ 'companyInit.step1' | transloco }}">
                <form [formGroup]="firstFormGroup" class="p-4">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>
                            {{ 'companyInit.email' | transloco }}
                        </mat-label>
                        <input matInput formControlName="email" type="email">
                        <mat-error *ngIf="first.email.hasError('email')">
                            {{ 'error.invalidEmail' | transloco }}
                        </mat-error>
                        <mat-error *ngIf="first.email.hasError('required')">
                            {{ 'companyInit.emailError' | transloco }}
                        </mat-error>
                        <mat-error *ngIf="first.email.hasError('usernameAlreadyExists')">
                            {{ 'companyInit.emailExistsError' | transloco }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-full mb-4">
                        <mat-label>
                            {{ 'password' | transloco }}
                        </mat-label>
                        <input matInput formControlName="password" type="password" #passwordField>
                        <button
                            mat-icon-button
                            type="button"
                            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                            matSuffix>
                            <mat-icon
                                class="icon-size-5"
                                *ngIf="passwordField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon
                                class="icon-size-5"
                                *ngIf="passwordField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                        </button>
                        <mat-error *ngIf="first.password.invalid">
                            {{ 'error.passwordError' | transloco }}
                        </mat-error>
                    </mat-form-field>

                    <!-- ToS and PP -->
                    <div class="w-full mb-4">
                        <div class="inline-flex items-end w-full mt-1.5">
                            <mat-checkbox
                                [ngClass]="{ 'border-b border-warn': first.agreements.invalid && submitted }"
                                class="-ml-2"
                                color="primary"
                                formControlName="agreements">
                                <span>
                                    <span>
                                        {{ 'auth.agreeWith' | transloco }}
                                    </span>
                                    <a (click)="openPolicyTermsDialog('terms')" class="ml-1 text-primary-500 hover:underline">
                                        {{ 'auth.terms' | transloco }}
                                    </a>
                                    <span>
                                        {{ 'and' | transloco }}
                                    </span>
                                    <a class="ml-1 text-primary-500 hover:underline" (click)="openPolicyTermsDialog('policy')">
                                        {{ 'auth.privacyPolicy' | transloco }}
                                    </a>
                                </span>
                            </mat-checkbox>
                        </div>
                        <mat-error
                            class="text-sm font-medium"
                            [ngStyle]="{'visibility': first.agreements.invalid && submitted ? 'visible' : 'hidden'}"
                        >
                            {{ 'companyInit.policyTermsError' | transloco }}
                        </mat-error>
                    </div>

                    <div class="text-right">
                        <button mat-raised-button color="primary" matStepperNext (click)="submitted=true">
                            {{ 'companyInit.next' | transloco }}
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" label="{{ 'companyInit.step2' | transloco }}">
                <form [formGroup]="secondFormGroup" class="p-4 mb-4">
                    <mat-form-field class="w-full mb-4">
                        <mat-label>
                            {{ 'companyInit.companyName' | transloco }}
                        </mat-label>
                        <input matInput formControlName="companyName">
                        <mat-error *ngIf="second.companyName.invalid">
                            {{ 'companyInit.companyNameError' | transloco }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-full pb-4">
                        <mat-label>
                            {{ 'companyInit.subdomainName' | transloco }}
                        </mat-label>
                        <input matInput formControlName="companyDomain">
                        <mat-hint>{{ 'companyInit.subdomainNameHint' | transloco }}</mat-hint>
                        <mat-error *ngIf="second.companyDomain.invalid">
                            {{ 'companyInit.subdomainNameError' | transloco }}
                        </mat-error>
                    </mat-form-field>
                    <div class="pt-10 lg:p-4">
                        {{ 'companyInit.finalUrl' | transloco }}: <b>{{ second.companyDomain.value }}</b>.{{ hostname }}
                    </div>

                    <div class="text-right pt-2">
                        <button mat-raised-button matStepperPrevious>
                            {{ 'companyInit.back' | transloco }}
                        </button>
                        <button class="ml-2" mat-raised-button color="primary" matStepperNext>
                            {{ 'companyInit.next' | transloco }}
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step label="{{ 'companyInit.step3' | transloco }}" class="p-4">
                <div class="p-4">

                    <div class="text-lg py-4">
                        {{ 'companyInit.step3Message' | transloco }}
                    </div>

                    <mat-divider class="mb-6"></mat-divider>

                    <div class="font-medium my-2">
                        {{ 'companyInit.email' | transloco }}&colon;
                    </div>
                    <div class="italic">
                        {{ first.email.value }}
                    </div>
                    <div class="font-medium my-2">
                        {{ 'password' | transloco }}&colon;
                    </div>
                    <div class="italic">
                        {{ first.password.value }}
                    </div>
                    <div class="font-medium my-2">
                        {{ 'companyInit.companyName' | transloco }}&colon;
                    </div>
                    <div class="italic">
                        {{ second.companyName.value }}
                    </div>
                    <div class="font-medium my-2">
                        {{ 'companyInit.subdomainName' | transloco }}&colon;
                    </div>
                    <div class="italic">
                        {{ second.companyDomain.value }}
                    </div>

                    <mat-divider class="my-6"></mat-divider>

                    <ng-container *ngIf="!showLoader">
                        <div class="mt-4 gap-y-1 gap-x-2 flex flex-col lg:flex-row lg:justify-end">
                            <button mat-raised-button matStepperPrevious>
                                {{ 'companyInit.back' | transloco }}
                            </button>
                            <button mat-raised-button (click)="stepper.reset()">
                                {{ 'companyInit.reset' | transloco }}
                            </button>
                            <button mat-raised-button [color]="'primary'" (click)="createCompany()" matStepperNext>
                                {{ 'companyInit.create' | transloco }}
                            </button>
                        </div>
                    </ng-container>

                </div>
            </mat-step>
        </mat-stepper>
    </ng-template>

<!--     <div class="text-center">
        <span>
            {{ 'auth.alreadyRegistered' | transloco }}&quest;&nbsp;
            <a class="text-primary-500 hover:underline mat-raised-button" [routerLink]="['/sign-in']">
                {{ 'auth.signIn' | transloco }}
            </a>
        </span>
    </div> -->
</div>
