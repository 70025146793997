import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { REGEX_URL_PATTERN } from 'app/modules/share/global.constants';
import { IntegrationSubType, MessengerType } from '../../integrations.types';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DialogTemplateComponent } from "../dialog-template/dialog-template.component";

@Component({
    selector: 'app-amio-dialog',
    standalone: true,
    templateUrl: './amio-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        ReactiveFormsModule,

        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,

        DialogTemplateComponent
    ]
})
export class AmioDialogComponent extends BaseDialogComponent<AmioDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.amio;
    }
    messengerTypes = Object.values(MessengerType);

    constructor()
    {
        super();

        this.form = this._formBuilder.group({
            name         : [
                this.data?.name,
                this.withRequiredValidator(),
                this.nameUniqValidator.bind(this)
            ],
            apiKey       : [this.data?.apiKey, this.withRequiredValidator()],
            apiUrl       : [this.data?.apiURL, this.withRequiredValidator(Validators.pattern(REGEX_URL_PATTERN))],
            channel      : [this.data?.channel, this.withRequiredValidator(Validators.pattern(/^[0-9]*$/))],
            messengerType: [ this.data?.messengerType || MessengerType.viber, this.withRequiredValidator()],
        });
    }

}
