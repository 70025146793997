import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

export const initCompanyRoutes: Route[] = [

    // Routes for new company
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'auth-init',
        },
        providers: [
            {
                provide: TRANSLOCO_SCOPE,
                useValue:
                    {
                        scope: 'company-init',
                        alias: 'companyInit',
                    },
                multi: true
            },
        ],
        children: [
            { path: '', pathMatch : 'full', redirectTo: 'init' },
            { path: 'init', component: RegisterComponent },
            { path: 'sign-in', component: LoginComponent }
        ]
    },

    { path: '**', pathMatch : 'full', redirectTo: 'init' },
]
