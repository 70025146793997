<div
    class="flex-0 w-100 h-full overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
    fuseScrollbar
    [fuseScrollbarOptions]="{wheelPropagation: false}"
>
    <app-chat-list
        [chat$]="chat$"
        [chats$]="chats$"
        (selected)="selectChat($event)"
    ></app-chat-list>
</div>

<!-- Message field -->
<div class="h-full flex flex-col flex-auto border-l bg-gray-50 dark:bg-transparent items-center">

    <ng-container *ngIf="chat$ | async as chat; else selectChatOrStartNew">

        <div class="w-full flex-auto shrink py-4 px-8 overflow-y-auto">
            <app-chat-message-list
                [canEditLast]="true"
                [messages]="chat.messages"
                [lastEditDisabled]="!!originalMessage"
                (onMessageEdit)="onMessageEdit($event)"
                (onMessageDelete)="onMessageDelete($event)"
            ></app-chat-message-list>
        </div>

        <div class="w-full p-4 border-t bg-gray-50 dark:bg-transparent">
            <app-chat-message-editor
                [messageText]="messageText"
                [editMode]="!!originalMessage"
                (send)="onSendMessage($event)"
                (cancel)="clearMessageState()"
            ></app-chat-message-editor>
        </div>
    </ng-container>

</div>

<!-- Select chat or start new template -->
<ng-template #selectChatOrStartNew>
    <div class="flex flex-col flex-auto items-center justify-center w-full h-full p-4">
        <mat-icon
            class="icon-size-24"
            [svgIcon]="'heroicons_outline:chat-bubble-bottom-center-text'"></mat-icon>
        <div class="mt-4 text-xl text-center font-medium tracking-tight text-secondary">
            {{ 'chats.selectChat' | transloco }}
        </div>
    </div>
</ng-template>

<app-image-review-popup #imagePopup></app-image-review-popup>
