import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { firstValueFrom, map, Observable, shareReplay, timer } from 'rxjs';
import { CrmWorkTimeService } from '../crm-work-time.service';
import { FuseLoadingService } from '@fuse/services/loading';

@Component({
    selector: 'app-start-work-widget',
    standalone: true,
    imports: [
        CommonModule,
        TranslocoModule,

        MatButtonModule,
        MatIconModule,
    ],
    templateUrl: './start-work-widget.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartWorkWidgetComponent implements OnInit
{
    inProgress$: Observable<boolean>;
    timeStr$ = timer(0, 1000).pipe(map(() => new Date() ));
    $loading = inject(FuseLoadingService).show$;

    constructor(
        private _workTimeService: CrmWorkTimeService,
    )
    {
        this.inProgress$ = this._workTimeService.inProgress$.pipe(shareReplay(1));
    }

    async ngOnInit()
    {
        await firstValueFrom(this._workTimeService.getWorkStatus());
    }

    async toggleWorkProgress()
    {
        await firstValueFrom(this._workTimeService.toggleWorkProgress());
        firstValueFrom(this._workTimeService.getWorkTimeList());
    }
}
