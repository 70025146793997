import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { MAX_PORT_NUMBER } from 'app/modules/share/global.constants';
import { IntegrationSubType } from '../../integrations.types';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';

@Component({
    selector: 'app-gmail-dialog',
    standalone: true,
    imports: [
        CommonModule, TranslocoModule, DialogTemplateComponent, FormsModule, ReactiveFormsModule,
        MatFormFieldModule, MatInputModule, MatIconModule
    ],
    templateUrl: './gmail-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GmailDialogComponent extends BaseDialogComponent<GmailDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.gmail;
    }

    constructor()
    {
        super();

        this.form = this._formBuilder.group({
            name     : [this.data.name, this.withRequiredValidator()],
            login    : [this.data.login, this.withRequiredValidator(Validators.email)],
            password : [this.data.password, this.withRequiredValidator()],
            host     : [this.data.host, this.withRequiredValidator()],
            SMTPport : [this.data.SMTPport, this.withRequiredValidator(Validators.max(MAX_PORT_NUMBER))],
            IMAPport : [this.data.IMAPport, this.withRequiredValidator(Validators.max(MAX_PORT_NUMBER))],
        });
    }

}
