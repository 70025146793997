import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { MAX_PORT_NUMBER } from 'app/modules/share/global.constants';
import { IntegrationSubType } from '../../integrations.types';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';

@Component({
    selector: 'app-asterisk-dialog',
    standalone: true,
    imports: [
      CommonModule,
      TranslocoModule,
      FormsModule,
      ReactiveFormsModule,

      MatFormFieldModule,
      MatInputModule,
      MatIconModule,

      DialogTemplateComponent
    ],
    templateUrl: './asterisk-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsteriskDialogComponent extends BaseDialogComponent<AsteriskDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.asterisk;
    }

    constructor()
    {
        super();

        this.form = this._formBuilder.group({
            name        : [this.data?.name, this.withRequiredValidator()],
            userName    : [this.data?.userName, this.withRequiredValidator()],
            password    : [this.data?.password, this.withRequiredValidator()],
            host        : [this.data?.host,this.withRequiredValidator()],
            sshPort     : [this.data?.sshPort, this.withRequiredValidator(Validators.max(MAX_PORT_NUMBER))],
            channel     : [this.data?.channel, this.withRequiredValidator()],
            port        : [this.data?.port, this.withRequiredValidator(Validators.max(MAX_PORT_NUMBER))],
            sshPassword : [this.data?.password, this.withRequiredValidator()],
        });
    }

}
