import { inject } from '@angular/core';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { Observable, forkJoin, of, take, tap } from 'rxjs';
import { ChatService } from './modules/admin/apps/chat/chat.service';
import { CompanyService } from './modules/admin/pages/settings/company/company.service';
import { EmployeesBrieflyService } from './modules/share/services/employees-briefly.service';
import { CalendarReminderService } from './modules/share/services/calendar-reminder.service';
import { Router } from '@angular/router';
import { IntegrationsService } from './modules/admin/apps/developers/integrations/integrations.service';

export const initialDataResolver = () =>
{
    const notificationsService = inject(NotificationsService);

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
        notificationsService.getAll(),
        inject(EmployeesBrieflyService).getEmployeesBriefly(),
        inject(ChatService).getChats(),
        inject(CompanyService).loadCompanyInfo(),
        inject(IntegrationsService).getIntegrationList(),
        inject(CalendarReminderService).init(),
    ])
    .pipe(take(1),
        tap(() => notificationsService.initAudio()));
};

export const resolveRoute = (observer: Observable<any>) =>
{
    const currentNavigation = inject(Router).getCurrentNavigation();
    const pageNavigation = currentNavigation.extras.state?.pageNavigation;
    return pageNavigation && !!currentNavigation.previousNavigation ? of(null) : observer;
}
