<app-dialog-template
    class="h-full"
    [canSave]="form.valid"
    [dialogType]="integrationSubType"
    [skipDialogContent]="true"
    [queueForm]="queueForm"
    [data]="data"
    (save)="save($event)"
>

    <form [formGroup]="form">
        <div class="grid mx-0 sm:grid-cols-2 gap-6 w-full mt-4">
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{ 'name' | transloco }}</mat-label>
                    <mat-icon matPrefix class="icon-size-5" svgIcon="heroicons_solid:user-circle"></mat-icon>
                    <input matInput formControlName="name">
                    <mat-error *ngIf="f.name.hasError('required')">
                        {{ 'requiredFieldError' | transloco }}
                    </mat-error>
                    <mat-error *ngIf="f.name.hasError('nameIsNotUniq')">
                        {{ 'integrations.nameUniqError' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>

    <div *ngIf="!isNew">

        <div class="whitespace-pre-wrap border bg-accent-50 flex-auto my-4 overflow-x-auto p-2">
            {{ scriptCode }}
        </div>

        <button
            mat-raised-button
            [cdkCopyToClipboard]="scriptCode"
        >
            {{ 'integrations.external.copyScript' | transloco }}
        </button>
    </div>

</app-dialog-template>
