import { ChangeDetectionStrategy, Component, ElementRef, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatePortal } from '@angular/cdk/portal';
import { OverlayRef, Overlay, OverlayConfig, ConnectedPosition } from '@angular/cdk/overlay';

@Component({
    selector: 'app-popup',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './popup.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupComponent {
    @ViewChild('popupPanel') private _popupPanel: TemplateRef<any>;
    @Input() connectedTo: ElementRef;
    private _panelOverlayRef: OverlayRef;
    private _templatePortal: TemplatePortal;

    constructor(
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef
    ) {}

    open(connectedTo?: ElementRef, config: OverlayConfig = {}, withPositions?: ConnectedPosition): void
    {
        connectedTo && (this.connectedTo = connectedTo);

        // Create the overlay
        this._panelOverlayRef = this._overlay.create({
                backdropClass   : '',
                hasBackdrop     : true,
                scrollStrategy  : this._overlay.scrollStrategies.block(),
                positionStrategy: this._overlay.position()
                    .flexibleConnectedTo(this.connectedTo)
                    .withFlexibleDimensions(true)
                    .withViewportMargin(64)
                    .withLockedPosition(true)
                    .withPositions([
                        (withPositions ||
                        {
                            originX : 'start',
                            originY : 'bottom',
                            overlayX: 'start',
                            overlayY: 'top',
                        }),
                    ]),
                ...config
        });

        // Create a portal from the template
        this._templatePortal = new TemplatePortal(this._popupPanel, this._viewContainerRef);

        // Attach the portal to the overlay
        this._panelOverlayRef.attach(this._templatePortal);
        // Subscribe to the backdrop click
        this._panelOverlayRef.backdropClick().subscribe(() =>
        {
            this.close();
        });
    }

    close()
    {
        // If overlay exists and attached...
        if ( this._panelOverlayRef && this._panelOverlayRef.hasAttached() )
        {
            // Detach it
            this._panelOverlayRef.detach();
        }

        // If template portal exists and attached...
        if ( this._templatePortal && this._templatePortal.isAttached )
        {
            // Detach it
            this._templatePortal.detach();
        }
    }
}
