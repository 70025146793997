import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractPopupComponent } from '../abstract-popup/abstract-popup.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PopupComponent } from '../popup.component';
import { ConnectedPosition } from '@angular/cdk/overlay';

type DataType =
{
    data: string,
    isUrl: boolean
}

@Component({
    selector: 'app-image-review-popup',
    standalone: true,
    imports: [
        CommonModule,

        MatButtonModule,
        MatIconModule,

        PopupComponent,
    ],
    templateUrl: './image-review-popup.component.html',
    styleUrls: ['image-review-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageReviewPopupComponent extends AbstractPopupComponent
{
    _withPositions = {
        originX : 'center',
        originY : 'center',
        overlayX: 'center',
        overlayY: 'center',
    } as ConnectedPosition;

    _config = {
        height: '100%',
        width: '100%',
        backdropClass   : ['bg-gray-100', 'opacity-50'],
    }

}
