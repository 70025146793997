import { NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy, Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { forkJoin, Observable, Subject, take } from 'rxjs';
import { MB } from '../../global.constants';
import { HelperService } from '../../services/helper.service';
import { UploadFileData } from "./file-upload.type";
import { MatButtonModule } from '@angular/material/button';
import { each } from 'lodash';

@Component({
    selector: 'app-file-upload',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        TranslocoModule,

        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
    ],
    templateUrl: "./file-upload.component.html",
})
export class FileUploadComponent implements OnInit
{
    @Input() accept: string;
    @Input() hideButton = false;
    @Input() iconClass = '';
    @Input() icon = '';
    @Input() openFile$?: Subject<any>;
    @Input() maxFileSizeMb: number;
    @Input()
    multiple = false;
    @Output() onFileSelected = new EventEmitter<UploadFileData | UploadFileData[]>();
    @ViewChild('fileUpload') private _fileUpload: ElementRef;

    private _destroyRef = inject(DestroyRef);

    constructor(
        private _helper: HelperService,
        private _transloco: TranslocoService,
    )
    {

    }

    ngOnInit(): void
    {
        this.openFile$?.pipe(
            takeUntilDestroyed(this._destroyRef)
        ).subscribe(_ =>
            this._fileUpload.nativeElement.click()
        );
    }

    onFileChange(event: Event)
    {
        const file = (event.target as HTMLInputElement).files[0];
        if (file)
        {
            if(this.maxFileSizeMb && (file.size / MB ) > this.maxFileSizeMb)
            {
                const message =
                    this._transloco.translate('fileSizeError', { value: this.maxFileSizeMb }) + ' '
                        + this._transloco.translate('mb');
                this._helper.showMessage(message);
            }
            else
            {
                const reader = new FileReader();
                reader.onload = (e: any) =>
                {
                    this.onFileSelected.emit({
                        file: file,
                        base64Data: e.target.result,
                    });
                    this._fileUpload.nativeElement.value = null;
                };
                reader.readAsDataURL(file);
            }
        }
    }

    onFileChangeMultiple(event: Event)
    {
        const selectedFiles = (event.target as HTMLInputElement).files;

        if (selectedFiles && selectedFiles.length)
        {

            const filesObservables: Observable<UploadFileData>[] = [];
            each(selectedFiles, file =>
            {
                if(this.maxFileSizeMb && (file.size / MB ) > this.maxFileSizeMb)
                {
                    const message =
                        this._transloco.translate('fileSizeError', { value: this.maxFileSizeMb }) + ' '
                            + this._transloco.translate('mb');
                    this._helper.showMessage(message);

                    filesObservables.length = 0;
                    return false;
                }
                else
                {
                    const subject$ = new Subject<UploadFileData>();
                    filesObservables.push(subject$);
                    const reader = new FileReader();
                    reader.onload = (e: any) =>
                    {
                        subject$.next({
                            file: file,
                            base64Data: e.target.result,
                        });
                        subject$.complete();

                    };
                    reader.readAsDataURL(file);
                }
            });

            forkJoin(filesObservables)
                .pipe(take(1))
                .subscribe(files => {
                    //this.onFileSelected.emit(files);
                    console.log('forkJoin', files);
                    this._fileUpload.nativeElement.value = null;
                });

        }
    }

}
