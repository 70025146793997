import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/core/user/user.service';
import { IChatService } from 'app/modules/admin/apps/chat/IChatService';
import { Chat, ChatMessage, ChatType, Profile } from 'app/modules/admin/apps/chat/chat.types';
import { UploadFileData } from 'app/modules/share/components/file-upload/file-upload.type';
import { EmployeeBriefly, OracleResponse } from 'app/modules/share/global.types';
import { EmployeesBrieflyService } from 'app/modules/share/services/employees-briefly.service';
import { HttpClientService } from 'app/modules/share/services/http-client.service';
import { Dictionary, keyBy, mapValues } from 'lodash';
import { BehaviorSubject, firstValueFrom, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ChatDetailsDialogComponent } from './chat-details-dialog/chat-details-dialog.component';
import { MessageHelperService } from 'app/modules/share/services/message-helper.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ChatUnreadCounter } from './chat-unread-counter.service';

@Injectable({providedIn: 'root'})
export class ChatService implements IChatService
{
    private readonly _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
    private readonly _chats: BehaviorSubject<Chat[]> = new BehaviorSubject([]);
    private readonly _allChats: BehaviorSubject<Chat[]> = new BehaviorSubject([]);
    protected readonly _contactsIdMap: BehaviorSubject<Dictionary<EmployeeBriefly>> = new BehaviorSubject({});
    private readonly _profile: BehaviorSubject<Profile> = new BehaviorSubject({});
    private readonly _unreadCounter = new ChatUnreadCounter();

    private readonly _http = inject(HttpClientService);
    private readonly _employeesService = inject(EmployeesBrieflyService);
    private readonly _userService = inject(UserService);
    private readonly _dialog = inject(MatDialog);
    private readonly _messageHelper = inject(MessageHelperService);

    /**
     * Constructor
     */
    constructor(
    )
    {
        this._employeesService.employees$
            .subscribe(contacts =>
            {
                //this._contacts.next(contacts);
                this._contactsIdMap.next(
                    mapValues(keyBy(contacts, 'id'))
                );
            });

        this.allChats$
            .pipe(
                takeUntilDestroyed(),
                map(chats => chats.filter(this.chatFilter)),
                tap(chats => this._chats.next(chats))
            ).subscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    get chat(): Chat
    {
        return this._chat.getValue();
    }

    set chat(chat: Chat)
    {
        this._chat.next(chat);
    }

    get unreadCounter()
    {
        return this._unreadCounter;
    }

    /**
     * Getter for chat
     */
    get chat$(): Observable<Chat>
    {
        return this._chat.asObservable();
    }

    /**
     * Getter for chats
     */
    get chats$(): Observable<Chat[]>
    {
        return this._chats.asObservable()
    }

    get chats(): Chat[]
    {
        return this._chats.getValue();
    }

    set chats(chats: Chat[])
    {
        this._chats.next(chats);
    }

    get allChats$(): Observable<Chat[]>
    {
        return this._allChats.asObservable();
    }

    get allChats(): Chat[]
    {
        return this._allChats.getValue();
    }

    set allChats(chats: Chat[])
    {
        this._allChats.next(chats);
    }

    get opened(): boolean
    {
        return false;
    }

    /**
     * Getter for profile
     */
    get profile$(): Observable<Profile>
    {
        return this._profile.asObservable();
    }

    refreshChat(): void
    {
        this._chat.next(this._chat.getValue());
    }

    refreshChats(): void
    {
        this._chats.next(this._chats.getValue());
    }

    sortChats(): void
    {
        this._chats.getValue().sort(this._chatsComparator);
        this.refreshChats();
    }

    chatFilter(chat: Chat): boolean
    {
        return chat.type !== ChatType.helper;
    }

    getChats(): Observable<Chat[]>
    {
        return this._http.get<Chat[]>('/api/getChats',
        {
            login: this._userService.login
        }
        ).pipe(
            map(chats => chats.map(chat =>
            {
                chat.contact = {};
                chat.lastMessage = this._messageHelper.processMessage(chat.lastMessage)

                if([ChatType.single, ChatType.messenger, ChatType.helper].includes(chat.type))
                {
                    chat.contact = chat.contacts[0] || {};
                    chat.name = chat.contact.fullName || '';
                }
                return chat;
            })),
            tap(chats => {
                chats.sort(this._chatsComparator);
                this._allChats.next(chats);
            }),
        );
    }

    getProfile(): Observable<any>
    {
        return this._http.get<Profile>('/api/getProfile',
            {
                login: localStorage.getItem('email')
            })
            .pipe(
                tap((response: Profile) => this._profile.next(response))
        );
    }

    generateNewChat(options: Chat = {})
    {
        return {
            id:  uuidv4(),
            isNew: true,
            muted: false,
            //lastMessageAt: (new Date()).toISOString(),
            ...options
       }
    }

    /**
     * New Chat
     */
    createChat(contacts: EmployeeBriefly[], type = ChatType.single, name?: string) : Observable<Chat>
    {
        const contact = contacts ? contacts[0] : null;
        const newChat =
            type === ChatType.single
                ? this.generateNewChat({
                    type,
                    contact
                })
                : this.generateNewChat({
                    type,
                    name,
                    contacts,
                });

        return this._http.post('/api/newChat', newChat)
            .pipe(
                switchMap(() => of(newChat))
            );
    }

    updateChat(chat: Chat): Observable<OracleResponse>
    {
        return this._http.post('/api/changeChat', chat)
            .pipe(take(1), tap(_ =>
                {
                    this._chat.next(chat);
                    this.refreshChats();
                }
            ));
    }

    removeChat(chatId: string): Observable<OracleResponse>
    {
        return this._http.post('/api/ChatRemove', {
            chatId,
            contactId: this._userService.user.id
        });
    }

    /**Messages
     *
     * Message service
     */
    sendMessage(chat: Chat, message: any = '', file?: UploadFileData): Observable<ChatMessage[]>
    {
        if(!message && !file)
        {
            return;
        }

        return this._http.post<ChatMessage[]>('/api/sendMessage',
        {
            login: this._userService.login,
            id: chat.id,
            chatType: chat.type,
            message,
            file: file?.base64Data,
            fileName: file?.file.name,
        })
        .pipe(
            map(messages => this._mapMessages(messages)),
            switchMap(messages => of(messages))
        );
    }

    updateMessage(id: string, message: string = ''): Observable<ChatMessage[]>
    {
        return this._http.post<ChatMessage[]>('/api/updateMessage', {
                id,
                login: this._userService.login,
                chatId: this.chat.id,
                message,
            })
            .pipe(
                //tap(m => console.log('m', m)),
                map(messages => this._mapMessages(messages)),
                switchMap(messages => of(messages))
        );
    }

    removeMessage(id: string): Observable<ChatMessage[]>
    {
        return this._http.post<ChatMessage[]>('/api/removeMessage', {
            id,
            chatId: this.chat.id
        });
    }

    getMessages(chatId: string): Observable<ChatMessage[]>
    {
        return this._http.get<ChatMessage[]>('/api/getMessages',
        {
            login: this._userService.login,
            id: chatId,
        }).pipe(
            map(messages => this._mapMessages(messages)),
            switchMap(messages => of(messages))
        );
    }
/*
                let idx = newMessages.length - 1;
                for (; idx >= 0; idx--) {
                    if (newMessages[idx].isMine) {
                        newMessages[idx].isLastMine = true;
                        break;
                    }
                }
                return newMessages;
 */

    setMessagesRead(chatId: string): Observable<ChatMessage[]>
    {
        return this._http.post<ChatMessage[]>('/api/setMessagesRead',
        {
            id: chatId
        });
    }

    /**
     * Get chat
     *
     * @param id
     */
    getChatById(id: string): Observable<Chat>
    {
        const chat = this._chats.getValue().find(chat => chat.id === id);

        if ( !chat )
        {
            return throwError('Could not found chat with id of ' + id + '!');
        }
        else {
            this.chat = chat;
        }
        return of(chat);
    }

    getChatByContact(id: string): Chat
    {
        const chat = this._chats.getValue().find(chat => String(chat.contact?.id) === String(id));
        return chat;
    }

    /**
     * Reset the selected chat
     */
    resetChat(): void
    {
        this._chat.next(null);
    }

    openChatDetailsDialog(chat?: Chat): void
    {
        const dialogRef = this._dialog.open(ChatDetailsDialogComponent, {
            data: {
                chat: chat || {
                    ...this.generateNewChat(),
                    type: ChatType.group
                }
            },
            panelClass: [
                'w-full',
                'sm:w-1/2',
            ]
        });

        dialogRef.afterClosed().subscribe(async (result: { chat: Chat }) =>
        {
            if(result && result.chat)
            {
                const chat = result.chat;
                if(chat.isNew)
                {
                    await firstValueFrom(this.createChat(chat.contacts, chat.type, chat.name));
                    await firstValueFrom(this.getChats());
                }
                else
                {
                    await firstValueFrom(this.updateChat(chat));
                }
            }
        });
    }

    private _mapMessages(messages: ChatMessage[]): ChatMessage[]
    {
        return messages?.map(message =>
            ({
                ...message,
                value: this._messageHelper.processMessage(message.value),
                contact: message.contact || this._contactsIdMap.getValue()[message.contactId]
            })
        );
    }

    private _chatsComparator(a: Chat, b: Chat): number
    {
        //console.log(a.lastMessageAt, b.lastMessageAt, a.lastMessageAt > b.lastMessageAt ? 1 : -1);
        return a.lastMessageAt > b.lastMessageAt || !b.lastMessageAt ? -1 : 1;
    }

}
