import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeBriefly } from '../../global.types';

@Component({
    selector: 'app-avatar-image',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './avatar-image.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarImageComponent
{
    @Input() contact: EmployeeBriefly = {};
    @Input() showName: boolean = false;
}

