<fuse-loading-bar></fuse-loading-bar>
<!-- Wrapper -->
<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">

    <div class="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
        <div [ngClass]="{'max-w-80 sm:w-80': !fullSize}" class="w-full mx-auto sm:mx-0">

            <div class="flex items-center ml-auto space-x-0.5 sm:space-x-2 justify-between">
                <img
                    class="w-20"
                    alt="Logo image"
                    appLogoImageResolve [logoType]="'mainLogoLight'"
                >
                <languages></languages>
            </div>
            <div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <div class="auth-right-section hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 dark:border-l">
        <!-- Content -->
        <div class="z-10 relative w-full max-w-2xl">
            <div class="text-7xl font-bold leading-none text-gray-100">
                <div>
                    {{ 'welcome.titleLine1' | transloco }}
                </div>
                <div>
                    {{ 'welcome.titleLine2' | transloco }}
                </div>
            </div>
            <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
                {{ 'welcome.message1' | transloco }}
            </div>
            <div class="flex items-center mt-8">
                <div class="flex flex-0 items-center -space-x-1.5">
                    <img
                        class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                        src="assets/images/avatars/female-18.jpg">
                    <img
                        class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                        src="assets/images/avatars/female-11.jpg">
                    <img
                        class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                        src="assets/images/avatars/male-09.jpg">
                    <img
                        class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                        src="assets/images/avatars/male-16.jpg">
                </div>
                <div class="ml-4 font-medium tracking-tight text-gray-400">
                    {{ 'welcome.message2' | transloco }}
                </div>
            </div>
        </div>
    </div>

</div>
