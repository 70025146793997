import { HttpStatusCode } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { ERROR_MESSAGE_DURATION_TIME, MESSAGE_DURATION_TIME } from 'app/modules/share/global.constants';
import { OracleResponse } from 'app/modules/share/global.types';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    translations = {
        'item already exists': 'error.itemAlreadyExists'
    }

    constructor(
        private _snackBar: MatSnackBar,
        private _translocoService: TranslocoService
    ) { }

    get responseHandler()
    {
        return {
            next: (response: OracleResponse) => this.successHandler(response),
            error: (error: OracleResponse) => this.showErrorMessage(error.Status)
        }
    }

    handler(response: OracleResponse, message: string)
    {
        response?.StatusCode === HttpStatusCode.Ok
            ? this.showInfoMessage(this.getTranslatedMessage(message))
            : this.showErrorMessage(response.Status);
    }

    successHandler(response: OracleResponse)
    {
        this.handler(response, this._translocoService.translate('savedSuccessfully'));
    }

    removeSuccessHandler(response: OracleResponse)
    {
        this.handler(response, this._translocoService.translate('removeSuccessfully'));
    }

    showInfoMessage(message: string): void
    {
        this.showMessage(this.getTranslatedMessage(message), { panelClass: 'green-snack-bar' } );
    }

    showErrorMessage(message: string): void
    {
        this.showMessage(this.getTranslatedMessage(message), { panelClass: 'red-snack-bar', duration: ERROR_MESSAGE_DURATION_TIME });
    }

    showMessage(message: string, options: MatSnackBarConfig = {}): void
    {
        this._snackBar.open(this.getTranslatedMessage(message), 'X', {
            verticalPosition: 'top',
            duration: options.duration ?? MESSAGE_DURATION_TIME,
            panelClass: options.panelClass
        });
    }

/*     showMessageFromTemplate(template: TemplateRef<any>, options: MatSnackBarConfig): void
    {
        this._snackBar.openFromTemplate(template, {
            verticalPosition: 'top',
            duration: options.duration ?? MESSAGE_DURATION_TIME,
            panelClass: options.panelClass ?? 'blue-snack-bar'
        });
    } */

    showMessageFromTemplate(template: TemplateRef<any>, options: MatSnackBarConfig): void
    {
        this._snackBar.openFromComponent(SnackBarComponent, {
            data: { template },
            verticalPosition: 'top',
            duration: options.duration ?? MESSAGE_DURATION_TIME,
            panelClass: options.panelClass ?? 'blue-snack-bar'
        });
    }

    getTranslatedMessage(message: string): string
    {
        const translationKey =  this.translations[message?.toLowerCase()]
        return translationKey ? this._translocoService.translate(translationKey) : message;
    }

}
