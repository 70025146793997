import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'app/modules/share/global.constants';
import { CommonComment, OracleResponse } from 'app/modules/share/global.types';
import { EmployeesBrieflyService } from 'app/modules/share/services/employees-briefly.service';
import { HttpClientService } from 'app/modules/share/services/http-client.service';
import { pick } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { EmployeeWorkTime, EmployeeWorkTimes, FormWorkTime, WorkTime } from './crm-work-time.types';

@Injectable({
    providedIn: 'root'
})
export class CrmWorkTimeService {

    private _workTimeList$ = new BehaviorSubject<EmployeeWorkTimes[]>(null);
    private _employeeWorkTime$ = new BehaviorSubject<EmployeeWorkTime>(null);
    private _inProgress$ = new BehaviorSubject<boolean>(false);
    private _currentDate = new BehaviorSubject<DateTime>(DateTime.now());

    get workTimeList$(): Observable<EmployeeWorkTimes[]>
    {
        return this._workTimeList$.asObservable();
    }

    get employeeWorkTime$(): Observable<EmployeeWorkTime>
    {
        return this._employeeWorkTime$.asObservable();
    }

    get employeeWorkTime(): EmployeeWorkTime
    {
        return this._employeeWorkTime$.getValue();
    }

    get inProgress$(): Observable<boolean>
    {
        return this._inProgress$.asObservable();
    }

    get currentDate(): DateTime
    {
        return this._currentDate.getValue();
    }

    constructor(
        private _http: HttpClientService,
        private _transloco: TranslocoService,
        private _employeesBriefly: EmployeesBrieflyService,
    )
    {
    }

    setEmployeeWorkTime(timePeriod: EmployeeWorkTime)
    {
        this._employeeWorkTime$.next(timePeriod);
    }

    setCurrentDate(date: DateTime): void
    {
        this._currentDate.next(date);
    }

    getWorkTimeList(): Observable<EmployeeWorkTimes[]>
    {
        return this._http.get<EmployeeWorkTimes[]>('/api/crm/getWorkTimeList', {
            startDateTime: this.currentDate.startOf('month').toFormat(DATE_FORMAT),
            endDateTime: this.currentDate.endOf('month').toFormat(DATE_FORMAT)
        })
            .pipe(
                tap(workTime => {
                    this._workTimeList$.next(workTime)
                })
            );
    }

    resolveWorkTime(employeeId: string, workTimeKey: string): EmployeeWorkTime
    {
        const employeeWorkTime = this._workTimeList$.getValue().find(item => item.employee.id === employeeId);
        const workTime = employeeWorkTime.workTime[workTimeKey];
        const selectedWorkTimePeriod = {
            ...employeeWorkTime,

            workTime: {
                ...workTime,
                key: workTimeKey,
                duration: this.getDuration(workTime)
            },
        };

        this.setEmployeeWorkTime(selectedWorkTimePeriod);
        return selectedWorkTimePeriod;
    }

    startWork(): Observable<OracleResponse>
    {
        return this._http.post('/api/crm/startWork')
            .pipe(
                tap(() => this._inProgress$.next(true))
            );
    }

    stopWork(): Observable<OracleResponse>
    {
        return this._http.post('/api/crm/stopWork')
            .pipe(
                tap(() => this._inProgress$.next(false))
            );
    }

    getWorkStatus(): Observable<boolean>
    {
        return this._http.get<boolean>('/api/crm/workStatus')
            .pipe(
                tap(value => this._inProgress$.next(value))
            );
    }

    toggleWorkProgress(): Observable<OracleResponse>
    {
        return this._inProgress$.getValue() ? this.stopWork() : this.startWork();
    }

    sendComment(text: string): Observable<OracleResponse>
    {
        console.log('sendComment', text, this.employeeWorkTime.workTime);
        return this._http.post('/api/crm/addComment', {
            id: this.employeeWorkTime.workTime.id,
            text
        }).pipe(
            tap(() => {
                const currentValue = this.employeeWorkTime.workTime;
                this._employeeWorkTime$.next(
                    {
                        ...this.employeeWorkTime,
                        workTime: {
                            ...currentValue,
                            comments: [
                                {
                                    avatar: this.employeeWorkTime.employee.avatar,
                                    //date: DateTime.now().toFormat(DATE_TIME_FORMAT),
                                    owner: this.employeeWorkTime.employee.fullName,
                                    text: text
                                } as CommonComment,
                                ...currentValue.comments
                            ]
                        }
                    }
                );

            })
        )
    }

    updateWorkTime(): Observable<OracleResponse>
    {
        const workTime = this.employeeWorkTime.workTime;
        return this._http.post('/api/crm/updateWorkTime',
            {
                ...pick(workTime, ['id', 'startDateTime', 'endDateTime', 'breakTime']),
                //breakTime: workTime.breakTime
            }
        );
    }

    getDateMinsDiff(workTime: WorkTime): string
    {
        const start = DateTime.fromFormat(workTime.startDateTime, DATE_TIME_FORMAT);
        const end = workTime.endDateTime ? DateTime.fromFormat(workTime.endDateTime, DATE_TIME_FORMAT) : DateTime.now();
        const breakTotalMins = workTime.breakTime;

        return end.diff(start, ['hours', 'minutes']).minus({minutes: breakTotalMins}).toFormat('hh:mm');
    }

    getDuration(workTime: WorkTime): string
    {
        return this.getDateMinsDiff(workTime);
    }

    minsToTimeString(minutes: number, withSign = false): string
    {
        const minsAbs = Math.abs(minutes);
        return (withSign ? minutes > 0 ? '+' : '-' : '')
            + String(Math.floor(minsAbs / 60)).padStart(2, '0') + ':' + String(minsAbs % 60).padStart(2, '0');
    }

    timeStrToMins(timeStr: string): number
    {
        if(!timeStr) {
            return 0;
        }
        const [breakHours, breakMins] = timeStr.split(':');
        return Number(breakHours) * 60 + Number(breakMins);
    }

    fromToWorkTime(formData: FormWorkTime): WorkTime
    {
        const workTime: WorkTime = {
            breakTime: this.timeStrToMins(formData.breakTime),
            startDateTime: this.makeDateTimeStr(formData.startDay.toFormat(DATE_FORMAT), formData.startTime),
            endDateTime: this.makeDateTimeStr(formData.endDay.toFormat(DATE_FORMAT), formData.endTime),
        };
        workTime.duration = this.getDateMinsDiff(workTime);
        return workTime;
    }

    makeDateTimeStr(dateStr: string, timeStr: string): string
    {
        //return dateStr + ' ' + timeStr + this.minsToTimeString(DateTime.local().offset, true);
        return dateStr + ' ' + timeStr;
    }


}
