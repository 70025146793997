import { NgForOf, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BasePickerComponent } from '../base-picker.component';

@Component({
    selector: 'app-emoji-picker',
    standalone: true,
    imports: [MatIconModule, MatButtonModule, NgIf, NgForOf],
    templateUrl: './emoji-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiPickerComponent extends BasePickerComponent implements AfterViewInit
{
    @Input() iconClass = '';
    @Output() onEmojiSelect = new EventEmitter<string>();

    @ViewChild('emojiPickerPanel') private _contactsPanelRef: TemplateRef<any>;
    @ViewChild('emojiPickerButton') private _emojiPickerButtonRef: MatButton;

    emojis = ['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇','🤔','🥰','👌','👍'];

    constructor(
    ) {
        super();
    }

    get pickerPanelRef(): TemplateRef<any>
    {
        return this._contactsPanelRef;
    }

    ngAfterViewInit(): void
    {
        this.connectedTo = this.connectedTo || this._emojiPickerButtonRef._elementRef;
    }

}
