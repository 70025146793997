<div class="px-18">
    <div mat-dialog-title class="text-2xl">
        {{ 'auth.termsLicenseAgreement' | transloco }}
    </div>

    <mat-divider class="my-2"></mat-divider>

    <div mat-dialog-content>
        <div>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>Ці Умови доступу та користування (далі – «Ліцензійний договір» або «Договір») є публічною офертою щодо укладення ліцензійного договору між </span><span style="-aw-sdt-tag:'goog_rdk_0'"></span><span>Malva CRM (Ліцензіар) та будь-якою фізичною особою, фізичною особою-підприємцем або юридичною особою (далі — Клієнт). Разом з Політикою конфіденційності, цей договір регулює порядок доступу та використання Клієнтом програмного забезпечення (Сервісу) Ліцензіара та надання відповідних послуг, передбачених цим Договором. Основною метою цього Договору є надання Клієнту доступу та права користування Сервісом, який забезпечує автоматизоване адміністрування та оптимізацію процесів обробки замовлень в Інтернеті, за умови дотримання всіх обмежень та умов використання, визначених цим Договором.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>Цей Договір відповідно до ст. 634 Цивільного кодексу України є договором приєднання. Після прийняття всіх умов і оплати послуг, Клієнт стає особою, яка прийняла умови цієї оферти (акцептувала оферту), що є рівноцінним укладенню цього Договору на умовах, що ним передбачені (офертою). Якщо будь-які умови, зазначені в цьому Договорі, не приймаються особою, яка має намір використовувати Сервіс, така особа не має права використовувати Сервіс, а Договір вважається не укладеним.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>Зареєструвавшись для використання Сервісу, Клієнт зобов’язується дотримуватись умов цього Договору та забезпечити їх дотримання з боку інших осіб (зокрема, Адміністратор, Користувач), яким він надасть право доступу до його облікового запису.</span></p>
            <p style="margin-top:14pt; margin-bottom:4pt; line-height:115%; font-size:11pt"><span>Визначення термінів</span></p>
            <p style="margin-top:12pt; margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:10.15pt"><span style="-aw-import:ignore"><span style="font-size:13pt"><span>●</span></span><span style="width:10.15pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>Сервіс – послуги та продукти (програмне забезпечення), що надаються через веб-сайт за адресою:</span><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="color:#000000; -aw-import:spaces">&#xa0;</span></a><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="color:#000000">http://malva.zpf.company/#function</span></a><span>.</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:10.15pt"><span style="-aw-import:ignore"><span style="font-size:13pt"><span>●</span></span><span style="width:10.15pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>Користувач – фізична особа, яка отримала доступ до Сервісу від Клієнта або Адміністратора.</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:10.15pt"><span style="-aw-import:ignore"><span style="font-size:13pt"><span>●</span></span><span style="width:10.15pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>Клієнт – фізична особа, самозайнята особа, підприємець або юридична особа, яка оплачує послуги та отримує доступ до Сервісу через обліковий запис.</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:10.15pt"><span style="-aw-import:ignore"><span style="font-size:13pt"><span>●</span></span><span style="width:10.15pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>Адміністратор – фізична особа, що призначена Клієнтом як основний адміністративний контакт для прийняття рішень щодо управління Сервісом, відключення від Сервісу, вирішення технічних питань, та яка уповноважена Клієнтом на адміністрування доступу до Сервісу та призначення додаткових Адміністраторів/Користувачів. Перша особа, зареєстрована Клієнтом у Сервісі, призначається Адміністратором.</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:10.15pt"><span style="-aw-import:ignore"><span style="font-size:13pt"><span>●</span></span><span style="width:10.15pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>Дані облікового запису – інформація, яку зберігає Ліцензіар і яка дозволяє ідентифікувати Клієнта, Користувачів, Адміністраторів та необхідна для керування та використання Сервісу. Дані облікового запису не включають відомостей, що завантажуються Клієнтом/Адміністраторами/Користувачами і стосуються їх контактів, проєктів, завдань, подій або інших подібних даних.</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:10.15pt"><span style="-aw-import:ignore"><span style="font-size:13pt"><span>●</span></span><span style="width:10.15pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>Контент – будь-яка інформація та матеріали, які Клієнт (через Адміністраторів/Користувачів) завантажує або публікує в Сервісі.</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:10.15pt"><span style="-aw-import:ignore"><span style="font-size:13pt"><span>●</span></span><span style="width:10.15pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>Конфіденційна інформація – будь-які відомості, передані Клієнтом, його Адміністраторами та/або Користувачами, і доступ до яких обмежено цими особами, а також інформація Ліцензіара, яка вважається його власністю.</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:10.15pt"><span style="-aw-import:ignore"><span style="font-size:13pt"><span>●</span></span><span style="width:10.15pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><a name="_heading_h.30j0zll"></a><span>Обліковий запис – запис у Сервісі, який зберігає дані для ідентифікації Адміністраторів/Користувачів.</span></p>
            <p style="margin-left:36pt; margin-bottom:12pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:10.15pt"><span style="-aw-import:ignore"><span style="font-size:13pt"><span>●</span></span><span style="width:10.15pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><a name="_heading_h.1fob9te"></a><span>Технічна підтримка – комплекс заходів, які здійснює Ліцензіар у встановлених межах для забезпечення функціонування Сервісу, включаючи інформаційно-консультаційну підтримку з питань використання Сервісу.</span></p>
            <p style="margin-top:14pt; margin-bottom:4pt; line-height:115%; font-size:11pt"><span style="-aw-import:ignore">&#xa0;</span></p>
            <p style="margin-top:14pt; margin-bottom:4pt; line-height:115%; font-size:11pt"><a name="_heading_h.3znysh7"></a><span>1. Умови використання Сервісу. Безпека та доступ</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.1. Сервіс доступний тільки зареєстрованим Клієнтам. Для отримання доступу необхідно надати своє повне ім'я (найменування), актуальну електронну адресу та іншу інформацію, необхідну для реєстрації.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.2. При реєстрації Клієнт зобов'язується надати правдиву та актуальну інформацію про себе та Адміністраторів/Користувачів, яка зазначена у формі реєстрації. Під час реєстрації Клієнт також вказує свою назву латиницею, яка буде використовуватися для доступу до Сервісу (наприклад, назва "Name" забезпечує доступ через посилання</span><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="color:#000000; -aw-import:spaces">&#xa0;</span></a><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="text-decoration:underline; color:#1155cc">http://malva.zpf.company/#function</span></a><span>). У разі надання неповної або неправдивої інформації, Ліцензіар залишає за собою право на свій розсуд заблокувати або видалити обліковий запис відповідного Клієнта/Адміністратора/Користувача і заборонити йому доступ до Сервісу.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.3. Ліцензіар має право в будь-який момент вимагати від будь-якого Клієнта/Адміністратора підтвердження зазначених при реєстрації даних. Ненадання відповідних документів є підставою для блокування або видалення облікового запису.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.4. Під час реєстрації Клієнт зазначає свою електронну адресу, яка буде використовуватися як логін для доступу до Сервісу, а також вибирає унікальний пароль. Ліцензіар має право встановлювати вимоги до логіну та паролю, а також забороняти використання певних логінів.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.5. Кожен Клієнт/Адміністратор/Користувач несе відповідальність за безпеку обраного пароля та зобов'язується забезпечувати його конфіденційність.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.6. Кожному Клієнту надається унікальний ідентифікатор для доступу до Сервісу. Клієнт зобов’язується забезпечити, щоб ім'я Адміністратора або Користувача використовувалося виключно в його діяльності і не передавалося іншим особам.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.7. Адміністратор має повноваження керувати Сервісом від імені Клієнта, та може надавати доступ додатковим Адміністраторам/Користувачам, а також обмежувати доступ окремих Користувачів за необхідності.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.8. У разі припинення використання Сервісу Ліцензіар зв'язується з Клієнтом для видалення відповідних даних облікового запису та надання можливості збереження Контенту.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.9. Ліцензіар надає доступ до Сервісу виключно через веб-інтерфейс, відповідно до положень цього Договору.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.10. Ліцензіар забезпечує безпеку аутентифікації та доступу до Сервісу шляхом:</span></p>
            <p style="margin-top:12pt; margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:11.36pt"><span style="-aw-import:ignore"><span><span>●</span></span><span style="width:11.36pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>управління паролями Користувачів і захисту паролів за допомогою відповідних програм;</span></p>
            <p style="margin-left:36pt; margin-bottom:12pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:11.36pt"><span style="-aw-import:ignore"><span><span>●</span></span><span style="width:11.36pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>передачі паролів у зашифрованому вигляді.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.11. Клієнт відповідає за захист імен Користувачів, паролів та інших кодів, пов'язаних із Сервісом, а також за дотримання законодавства під час роботи з Контентом.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.12. Клієнт зобов'язується дотримуватися політики безпеки та негайно повідомляти Ліцензіара про можливу втрату паролів або їх несанкціоноване використання третіми особами.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.13. Ліцензіар повідомляє Клієнта про будь-які дії або події, що можуть призвести до несанкціонованого доступу, розкриття або пошкодження Контенту.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.14. У разі порушення безпеки доступу до Сервісу Ліцензіар співпрацює з Клієнтом для встановлення причин порушення, виявлення зміненого Контенту та надає допомогу у розслідуванні та запобіганні повторним порушенням.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.15. Ліцензіар вживає заходів для захисту Контенту та забезпечення його надійного збереження з урахуванням технологічного розвитку та вартості реалізації таких заходів.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.16. Клієнт, Адміністратори, Користувачі зобов'язуються не здійснювати та не дозволяти іншим особам:</span></p>
            <p style="margin-top:12pt; margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:11.36pt"><span style="-aw-import:ignore"><span><span>●</span></span><span style="width:11.36pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>надавати доступ до Сервісу в оренду або тимчасове користування;</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:11.36pt"><span style="-aw-import:ignore"><span><span>●</span></span><span style="width:11.36pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>транслювати Сервіс на інтернет-сайтах без згоди Ліцензіара;</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:11.36pt"><span style="-aw-import:ignore"><span><span>●</span></span><span style="width:11.36pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>змінювати Сервіс, його дизайн або зовнішній вигляд;</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:11.36pt"><span style="-aw-import:ignore"><span><span>●</span></span><span style="width:11.36pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>надавати доступ до Сервісу особам, які не мають на це права;</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:11.36pt"><span style="-aw-import:ignore"><span><span>●</span></span><span style="width:11.36pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>публікувати фрагменти Сервісу або надавати іншим особам можливість їх копіювання;</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:11.36pt"><span style="-aw-import:ignore"><span><span>●</span></span><span style="width:11.36pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>обходити технічні обмеження Сервісу;</span></p>
            <p style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:11.36pt"><span style="-aw-import:ignore"><span><span>●</span></span><span style="width:11.36pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>передавати права на використання Сервісу третім особам;</span></p>
            <p style="margin-left:36pt; margin-bottom:12pt; text-indent:-18pt; line-height:115%; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'●'; -aw-list-number-styles:'bullet'; -aw-list-padding-sml:11.36pt"><span style="-aw-import:ignore"><span><span>●</span></span><span style="width:11.36pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span></span><span>будь-яким чином змінювати, спотворювати, розкривати вміст програмного (вихідного) коду Сервісу іншим особам частково або повністю без дозволу Ліцензіара.Malva CRM</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>1.17. Користування Сервісом дозволено тільки особам, які досягли 18 років. Якщо користувач молодше 18 років, Адміністратор має право обмежити його доступ до Сервісу.</span></p>
            <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
               <li style="margin-top:12pt; margin-left:32.17pt; margin-bottom:12pt; line-height:115%; padding-left:3.83pt; font-size:11pt"><span style="font-weight:bold">Ліцензійні умови</span></li>
            </ol>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.1. Сервіс є результатом інтелектуальної діяльності та охороняється авторськими правами, що належать Ліцензіару відповідно до чинного законодавства України та міжнародного права.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.2. Алгоритми роботи Сервісу та його вихідний код (у тому числі окремі частини) є комерційною таємницею Ліцензіара. Будь-яке використання їх з порушенням умов цього Договору розглядається як порушення прав Ліцензіара і може призвести до позбавлення Клієнта наданих за цим Договором прав.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.3. Ліцензіар гарантує, що має всі необхідні права для надання Клієнту доступу до Сервісу, включаючи відповідну документацію.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.4. Клієнту надається невиключна, невідчужувана, обмежена ліцензія на доступ та використання Сервісу. Це право включає розміщення власного Контенту та використання функціональних можливостей Сервісу відповідно до обраного тарифного плану.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.5. Ліцензіар не переглядає та не перевіряє Контент, розміщений Клієнтом, і не набуває жодних прав, включаючи права інтелектуальної власності, на цей Контент.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.6. Клієнт зобов'язується ознайомити Адміністраторів/Користувачів зі змістом цього Договору та забезпечити дотримання ними його положень. Клієнт несе відповідальність за дії або бездіяльність Адміністраторів/Користувачів під час використання Сервісу, включаючи розкриття/видалення Контенту та розкриття/поширення Конфіденційної інформації.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.7. Клієнту заборонено копіювати, відтворювати надавати права на використання Сервісу, а також вчинювати інші дії, що не передбачені цим Договором. Клієнт, Адміністратори та Користувачі зобов'язані використовувати Сервіс виключно для власної внутрішньої діяльності Клієнта.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.8. Цей Договір не надає Клієнту, Адміністраторам, Користувачам жодного права на використання будь-яких товарних знаків, логотипів, доменних імен та інших об'єктів виняткових прав, що використовуються Ліцензіаром. Клієнт зобов'язується використовувати Сервіс так, щоб не поширювати неправдиву інформацію або таку, яка може створити хибне уявлення про Сервіс або послуги та продукти, що надаються Ліцензіаром через веб-сайт</span><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="color:#000000; -aw-import:spaces">&#xa0;</span></a><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="text-decoration:underline; color:#1155cc">http://malva.zpf.company/#function</span></a><span>.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.9. Клієнту заборонено завантажувати, публікувати, передавати, зберігати чи поширювати через Сервіс (або безпосередньо на Сервіс) віруси, комп'ютерні програми, які можуть самовідтворюватися, або будь-яке інше шкідливе програмне забезпечення.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.10. За винятком прав, наданих за цим Договором, Клієнт визнає та погоджується, що всі права власності та інші права на Сервіс належать Ліцензіару. Клієнт має право використовувати Сервіс виключно через сайт</span><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="color:#000000; -aw-import:spaces">&#xa0;</span></a><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="text-decoration:underline; color:#1155cc">http://malva.zpf.company/#function</span></a><span>.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.11. Ліцензіар залишає за собою право в будь-який час, на свій розсуд, вживати заходи щодо Контенту, який порушує умови цього Договору, включаючи його видалення.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.12. Ліцензіар має право додавати, змінювати або припиняти надання будь-яких функціональних можливостей Сервісу в будь-який час, повідомляючи про це Клієнта або без такого повідомлення, за винятком випадків, коли зміни суттєво знижують функціональність Сервісу. У таких випадках Ліцензіар повідомляє Клієнта за 30 днів, і Клієнт матиме право розірвати цей Договір.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span style="background-color:#ffffff">2.13. Ліцензіар залишає за собою право тимчасово призупинити доступ до Сервісу для проведення обслуговування, ремонту або оновлень. Повідомлення про це буде надіслано не менш ніж за 3 робочі дні до призупинення доступу. В екстрених випадках, які вимагають невідкладних дій для захисту Сервісу, Ліцензіар має право тимчасово призупинити доступ без попереднього повідомлення, але застосує всі розумні заходи для мінімізації впливу на користувачів.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>2.14. Клієнт має право регулярно зберігати свій Контент на сторонніх або самостійно обраних сервісах (хмарних, локальних тощо), використовуючи відповідні технічні засоби, не порушуючи при цьому прав інтелектуальної власності Ліцензіара.</span></p>
            <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
               <li style="margin-top:12pt; margin-left:32.17pt; margin-bottom:12pt; line-height:115%; padding-left:3.83pt; font-size:11pt"><span style="font-weight:bold">Оплата, зміна умов доступу та користування Сервісом</span></li>
            </ol>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.1. Клієнт зобов'язується оплачувати користування Сервісом відповідно до умов, викладених нижче.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.2. Усі платежі включають ПДВ, якщо це зазначено у рахунку-фактурі.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.3. Оплата за доступ та право користування Сервісом здійснюється безготівковим розрахунком через особистий кабінет.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.4. Якщо Клієнт (платник) є фізичною або самозайнятою особою, оплата здійснюється через платіжні системи на сайті</span><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="color:#000000; -aw-import:spaces">&#xa0;</span></a><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="text-decoration:underline; color:#1155cc">http://malva.zpf.company/#function</span></a><span>. Для підприємців або юридичних осіб оплата проводиться на підставі рахунку-фактури або інвойсу, що виставляється Malva CRM за 5 (п'ять) днів до завершення ознайомчого періоду або поточного оплаченого періоду в 30 (тридцять) днів. Деякі способи оплати можуть включати комісію за переказ коштів.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.5. Вартість доступу та користування Сервісом залежить від обсягу послуг згідно з тарифами, вказаними на сайті</span><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="color:#000000; -aw-import:spaces">&#xa0;</span></a><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="text-decoration:underline; color:#1155cc">http://malva.zpf.company/#function</span></a><span>.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.6. Плата за користування Сервісом нараховується щомісяця, 1-го числа кожного місяця. Усі платежі є безповоротними, за винятком випадків, зазначених у п. 3.10 цього Договору.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.7. У разі несплати за користування Сервісом протягом 10 (десяти) календарних днів після утворення негативного балансу, Ліцензіар має право заблокувати доступ до Сервісу до моменту отримання оплати. Моментом оплати вважається зарахування коштів на рахунок Ліцензіара.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.8. Окрім оплати за користування Сервісом, Клієнт може нести додаткові витрати, пов'язані з доступом до Інтернету, роумінгом даних тощо.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.9. Ліцензіар має право в односторонньому порядку змінювати вартість тарифів за доступ та користування Сервісом, публікуючи зміни на сайті</span><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="color:#000000; -aw-import:spaces">&#xa0;</span></a><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="text-decoration:underline; color:#1155cc">http://malva.zpf.company/#function</span></a><span>. Такі зміни набирають чинності з моменту їх публікації, якщо не зазначено інший порядок. У разі зміни вартості тарифів, Ліцензіар повідомить чинних Клієнтів за 30 (тридцять) днів до набрання змінами чинності, надіславши відповідне повідомлення електронною поштою.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.10. Якщо Сервіс стає недоступним з вини Ліцензіара, плата за користування Сервісом (щомісячна або щорічна) буде перерахована, а Клієнту надано додатковий період користування, пропорційно до часу недоступності Сервісу.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>3.11. За дострокове припинення користування Сервісом платежі не стягуються. Права доступу та користування Сервісом, скасовані до завершення поточного платіжного періоду, не будуть перенесені на наступний період.</span></p>
            <ol start="4" type="1" style="margin:0pt; padding-left:0pt">
               <li style="margin-top:12pt; margin-left:32.17pt; margin-bottom:12pt; line-height:115%; padding-left:3.83pt; font-size:11pt"><span style="font-weight:bold">Строк дії Договору та припинення права доступу до Сервісу</span></li>
            </ol>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>4.1. Цей Договір набирає чинності з моменту акцепту оферти та залишається дійсним до моменту припинення використання Сервісу наприкінці відповідного періоду або з інших причин, передбачених Договором.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>4.2. Будь-яка сторона має право розірвати цей Договір у будь-який час, надіславши письмове повідомлення іншій стороні у разі неодноразового та суттєвого порушення умов Договору.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>4.3. Ліцензіар має право в односторонньому порядку розірвати цей Договір у випадку порушення Клієнтом істотних умов Договору, повідомивши</span><span style="background-color:#ffffff"> про це Клієнта за один робочий день до передбачуваної дати розірвання, без зобов'язання виплати будь-якої компенсації.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>4.4. Клієнт або Адміністратор може скасувати право доступу до Сервісу або анулювати обліковий запис у будь-який час, використовуючи відповідні функції Сервісу. Інші способи припинення доступу до Сервісу не допускаються.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>4.5. Перед припиненням дії цього Договору Клієнт зобов'язується видалити весь свій Контент із Сервісу. Ліцензіар видалить або знищить весь Контент через 14 (чотирнадцять) календарних днів після припинення Договору або права доступу до Сервісу. Ліцензіар не зобов'язаний повідомляти Клієнта про видалення Контенту після припинення Договору і не несе відповідальності за видалення Контенту відповідно до цього Договору.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>4.6. У разі припинення доступу до Сервісу з будь-якої причини, Клієнт матиме 14 (чотирнадцять) днів для перенесення Контенту до іншого місця перед його видаленням. Ліцензіар може надавати доступ до Контенту після припинення Договору або доступу до Сервісу на платній основі.</span></p>
            <ol start="5" type="1" style="margin:0pt; padding-left:0pt">
               <li style="margin-top:12pt; margin-left:32.17pt; margin-bottom:12pt; line-height:115%; padding-left:3.83pt; font-size:11pt"><span style="font-weight:bold">Конфіденційна інформація та захист персональних даних</span></li>
            </ol>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>5.1. Обидві сторони зобов’язуються розглядати всю конфіденційну інформацію як таку, що підлягає захисту, і не розкривати її, за винятком випадків, коли це необхідно для виконання цього Договору.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>5.2. Клієнт, який згідно із Законом України «Про захист персональних даних» є Власником персональних даних, Одержувачем або Розпорядником щодо контактних даних (співробітників, клієнтів, замовників тощо) – суб'єктів персональних даних, а також Ліцензіар зобов’язуються дотримуватись положень цього Закону під час обробки персональних даних, зокрема за допомогою інформаційних (автоматизованих) систем.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>5.3. Погоджуючись із цим Договором, Клієнт підтверджує, що має всі законні підстави для збору, реєстрації, накопичення, зберігання, адаптації, зміни, оновлення, використання та поширення (публікації, передачі), знеособлення, знищення персональних даних та іншої інформації, що становить зміст Контенту, з використанням Сервісу.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>5.4. Ліцензіар зобов’язується вживати заходів для забезпечення захисту персональних даних від несанкціонованого доступу, обробки, втрати або знищення, і буде діяти виключно за вказівками Клієнта щодо обробки персональних даних, які були передані Ліцензіару.</span></p>
            <ol start="6" type="1" style="margin:0pt; padding-left:0pt">
               <li style="margin-top:12pt; margin-left:32.17pt; margin-bottom:12pt; line-height:115%; padding-left:3.83pt; font-size:11pt"><span style="font-weight:bold">Відповідальність</span></li>
            </ol>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>6.1. Відповідальність за порушення умов цього Договору визначається чинним законодавством України.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>6.2. Ліцензіар не несе відповідальності перед Клієнтом/Адміністраторами/Користувачами за будь-які збитки, втрати доходів, прибутку, інформації чи заощаджень, що виникли внаслідок використання або неможливості використання Сервісу, навіть якщо Клієнт/Адміністратори/Користувачі були попереджені про можливість таких втрат, або за будь-якими претензіями третьої сторони.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>6.3. Ліцензіар звільняється від відповідальності за невиконання або неналежне виконання цього Договору, якщо це сталося через зовнішні обставини або дії (бездіяльність) третіх осіб, що знаходяться поза контролем Ліцензіара і не виникли з його вини.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>6.4. Клієнт зобов’язується компенсувати Ліцензіару будь-які збитки, які можуть виникнути внаслідок порушення Клієнтом (його Адміністраторами або Користувачами) зобов'язань за цим Договором. Ліцензіар негайно інформує Клієнта про будь-які претензії чи збитки, які виникають у зв'язку з такими порушеннями. Клієнт зобов'язується всебічно сприяти Ліцензіару у захисті його інтересів від претензій чи позовів.</span></p>
            <ol start="7" type="1" style="margin:0pt; padding-left:0pt">
               <li style="margin-top:12pt; margin-left:32.17pt; margin-bottom:12pt; line-height:115%; padding-left:3.83pt; font-size:11pt"><span style="font-weight:bold">Технічна підтримка</span></li>
            </ol>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>7.1. Ліцензіар надає технічну підтримку Клієнтам, Адміністраторам і Користувачам, зокрема, з питань, що стосуються функціонування та використання Сервісу на стандартних конфігураціях підтримуваних операційних систем.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>7.2. Клієнти, Адміністратори та Користувачі можуть звертатися до служби технічної підтримки Ліцензіара без додаткової оплати.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>7.3. Для надання технічної підтримки Ліцензіар має право запитувати у Клієнтів, Адміністраторів або Користувачів інформацію про обліковий запис і/або дані від інтегрованих із Сервісом сторонніх ресурсів.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>7.4. Технічна підтримка надається Клієнтам із активним доступом до Сервісу через чат у системі, телефоном, електронною поштою на адресу </span><span style="line-height:115%; font-size:10.5pt">office@malva-group.com</span></p>
            <ol start="8" type="1" style="margin:0pt; padding-left:0pt">
               <li style="margin-top:12pt; margin-left:32.17pt; margin-bottom:12pt; line-height:115%; padding-left:3.83pt; font-size:11pt"><span style="font-weight:bold">Інші умови</span></li>
            </ol>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>8.1. Обидві сторони зобов’язуються дотримуватися цього Договору та норм чинного законодавства України. Усі суперечки, що можуть виникнути з цього Договору, вирішуються відповідно до законодавства України.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span style="background-color:#ffffff">8.2. Сервіс може бути несумісним із певними комп’ютерами або обладнанням Клієнта. Ліцензіар не гарантує досягнення певних результатів, яких Клієнт може прагнути, використовуючи Сервіс.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>8.3. Цей Договір є остаточною домовленістю між Ліцензіаром та Клієнтом, що регулює використання Сервісу та замінює всі попередні домовленості між Сторонами (включно з попередніми версіями Договору).</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>8.4. Ліцензіар має право залучати третіх осіб для забезпечення працездатності Сервісу, при цьому несе відповідальність перед Клієнтом за дії цих осіб у межах цього Договору.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>8.5. Жодна зі Сторін цього Договору не може передавати всі свої права чи обов'язки третім особам без попередньої письмової згоди іншої сторони, за винятком випадків злиття або реорганізації, коли покупець або новий суб’єкт погоджується на дотримання умов цього Договору.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>8.6. Ліцензіар може надавати можливість інтеграції Сервісу з продуктами сторонніх постачальників на вибір і ризик Клієнта. У цьому випадку Ліцензіар не несе відповідальності за наслідки такої інтеграції і залишає за собою право змінювати або припиняти інтеграцію Сервісу з іншими продуктами без попереднього повідомлення Клієнта.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>8.7. Недоступність Сервісу (у значенні п. 3.10 цього Договору) означає будь-який проміжок часу, що </span><span style="background-color:#ffffff">перевищує 60 хвилин, </span><span>протягом якого Клієнт, Адміністратор або Користувач не може отримати доступ до Сервісу через його відмову, за винятком запланованих простоїв.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>8.8. У всіх питаннях, не врегульованих цим Договором, сторони керуються чинним законодавством України.</span></p>
            <p style="margin-top:12pt; margin-bottom:12pt; line-height:115%; font-size:11pt"><span>8.9. Ліцензіар залишає за собою право вносити зміни до умов цього Договору в односторонньому порядку, публікуючи змінений текст на веб-сайті</span><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="color:#000000; -aw-import:spaces">&#xa0;</span></a><a href="http://malva.zpf.company/#function" style="text-decoration:none"><span style="text-decoration:underline; color:#1155cc">http://malva.zpf.company/#function</span></a><span>. Зміни набирають чинності з моменту публікації, якщо не вказано інше.</span></p>
            <p style="line-height:115%; font-size:11pt"><span style="-aw-import:ignore">&#xa0;</span></p>
         </div>
    </div>

    <mat-divider class="my-2"></mat-divider>

    <div mat-dialog-actions class="flex justify-end">
        <button mat-raised-button mat-dialog-close>
            {{ 'close' | transloco}}
        </button>
    </div>
</div>
