import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, map, Observable, of,switchMap,tap  } from "rxjs";
import { environment } from '../../../../../../environments/environment';

interface AuthResponse {
    isAuthenticated: boolean;
    status: string;
    tokenData?: {
        access_token: string;
        expiry_date: number;
    };
}

@Injectable({
    providedIn: 'root'
})



export class GoogleCalendarService {
    private auth : boolean = true;
    private _token: string | null = null;
     isAutorizated : string = '';
    constructor(private http: HttpClient) { }


    authenticate(): Observable<{ authUrl?: string, access_token?: string }> {
        if (this.auth) {

        return this.http.get<{ authUrl?: string, access_token?: string }>(`${environment.apiBaseUrl}/auth`)
            .pipe(
                tap(response => {
                    console.log('Response from /auth:', response);
                    if (response.authUrl) {

                       //   window.location.href = response.authUrl;
                          this.auth  = false;
                        //   window.location.href = response.authUrl;
                    }
                })
            );
        }
    }


    getEvents(): Observable<{ today: Event[], tomorrow: Event[] }> {
        return this.http.get<{ today: Event[], tomorrow: Event[] }>(`${environment.apiBaseUrl}/events`, { withCredentials: true });
    }

    isUserAuthenticated(): Observable<boolean> {
        return this.http.get<{ isAuthenticated: boolean }>(`${environment.apiBaseUrl}/auth/status`).pipe(
            tap(response => {
                console.log('Authentication status response:', response);
            }),
            map(response => response.isAuthenticated),
            catchError(error => {
                console.error('Error checking authentication status:', error);
                return of(false);
            })
        );
    }
}
