<!-- <app-smooth-height [trigger]="selectedTabIndex"> -->
<div mat-dialog-content class="p-0 sm:px-1">

    <div class="flex w-full gap-2 items-center sm:pl-2 py-2 my-1">
        <img class="w-28 h-28 -ml-2 sm:-ml-3 mt-1 ms:mr-4 sm:-mt-3" [src]="dialogData.logoSrc"/>
        <div>
            <span class="text-xl my-2">
                {{ dialogData.title }}
            </span>
            <span class="block">
                {{ dialogData.titleSecondary }}
            </span>
        </div>
    </div>

    <!-- isAdmin -->
    <div *ngIf="isAdmin" class="mb-4">
        <app-contacts-panel
            class="w-full"
            labelText="{{ 'integrations.access' | transloco }}"
            [contactsSelected]="data.access"
            (onSelect)="data.access = $event"
        ></app-contacts-panel>
    </div>

    <div class="grid grid-cols-3 mx-0 space-between gap-1 w-full" *ngIf="!skipDialogContent">
        <img [src]="dialogData.image1Src">
        <img [src]="dialogData.image2Src">
        <img [src]="dialogData.image3Src">
    </div>

    <mat-divider class="my-5"></mat-divider>

    <div>

        <mat-tab-group preserveContent *ngIf="isMessenger; else content">
            <mat-tab [label]="'integrations.connectionParams' | transloco">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </mat-tab>
            <mat-tab [label]="'integrations.queue' | transloco">
                <app-response-queue
                    [queueForm]="queueForm"
                    [data]="data"
                ></app-response-queue>
            </mat-tab>
        </mat-tab-group>

    </div>

    <div mat-dialog-actions class="justify-end">
        <button mat-raised-button matDialogClose>
            {{ 'cancel' | transloco }}
        </button>
        <button mat-raised-button matDialogClose
            color="primary"
            (click)="save.emit(data.access)"
            [disabled]="!canSave || queueForm?.invalid"
        >
            {{ 'save' | transloco }}
        </button>
    </div>

</div>

<ng-template #content>

    <div *ngIf="!skipDialogContent">

        <div class="text-xl mb-2">
            {{ 'integrations.description' | transloco }}
        </div>

        <div [ngClass]="{'relative h-32 overflow-hidden bg-gradient-to-b from-slate-0 to-state-400': isDescriptionCollapsed}">

            <p *ngIf="dialogData.text1">{{ dialogData.text1 }}</p>
            <p *ngIf="dialogData.text2">{{ dialogData.text2 }}</p>
            <ol *ngIf="dialogData.liText1">
                <li>{{ dialogData.liText1 }}</li>
                <li *ngIf="dialogData.liText2">{{ dialogData.liText2 }}</li>
                <li *ngIf="dialogData.liText3">{{ dialogData.liText3 }}</li>
                <li *ngIf="dialogData.liText4">{{ dialogData.liText4 }}</li>
            </ol>
            <p *ngIf="dialogData.text3">{{ dialogData.text3 }}</p>
            <p *ngIf="dialogData.text4">{{ dialogData.text4 }}</p>

            <div *ngIf="isDescriptionCollapsed" class="h-20 bg-gradient-to-b from-transparent to-zinc-100 absolute bottom-0 right-0 left-0"></div>

        </div>

        <div *ngIf="isDescriptionCollapsed" class="border-t-2 border-slate-400 border-solid">
            <a href="" (click)="isDescriptionCollapsed = false" class="text-neutral-600 p-2 flex justify-end -mt-2">
                <span class="pr-4">
                    {{ 'readMore' | transloco}}
                </span>
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:chevron-double-down'"></mat-icon>
            </a>
        </div>
    </div>

    <mat-divider class="my-5"></mat-divider>

    <div class="text-xl" [ngClass]="{'-mt-4': isDescriptionCollapsed}">
        {{ 'integrations.setup' | transloco }}
    </div>
    <div class="text-lg mt-2 -mb-2">
        {{ 'integrations.setupSecondary' | transloco }}
    </div>

    <ng-content></ng-content>

</ng-template>
