import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { IntegrationSubType } from '../../integrations.types';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';

@Component({
    selector: 'app-aws-dialog',
    standalone: true,
    imports: [
      CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule,
      MatFormFieldModule, MatInputModule, MatIconModule,
      DialogTemplateComponent
    ],
    templateUrl: './aws-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AwsDialogComponent extends BaseDialogComponent<AwsDialogComponent>
{
    EMAIL_PATTERN = /^(?:"[^"]+"\s<)?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}>?$/;

    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.aws;
    }

    constructor()
    {
        super();

        this.form = this._formBuilder.group({
            name     : [
                this.data?.name,
                this.withRequiredValidator(),
                this.nameUniqValidator.bind(this)
            ],
            login    : [this.data?.login, this.withRequiredValidator()],
            password : [this.data?.password, this.withRequiredValidator()],
            region   : [this.data?.region, this.withRequiredValidator()],
            sender   : [this.data?.sender,
                this.withRequiredValidator(Validators.pattern(this.EMAIL_PATTERN))],
        });
    }
}
