import { Injectable } from '@angular/core';
import { EmployeesBrieflyService } from './employees-briefly.service';
import { keyBy, mapValues } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class MessageHelperService {

    constructor(
        private _employeesBrieflyService: EmployeesBrieflyService
    ) {}

    processMessage(message: string = ''): string
    {
        const contactsIdMap = mapValues(keyBy(this._employeesBrieflyService.employees, 'id'));

        return message
            .replace(/\[USER\](\d+)\[\/USER\]/g,
                (val, id) => {
                    const fullName = contactsIdMap[id]?.fullName;
                    return !!fullName ? '<b class="mentionedUser">' + contactsIdMap[id].fullName + '</b>' : val;
                })
            .replace( /(https?:\/\/[^\s]+)/g,
                '<a href="$1" target="_blank" class="text-primary-700">$1</a>');
    }
}
