import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class CustomPaginatorIntl extends MatPaginatorIntl
{

    constructor(
    private _transloco: TranslocoService
    ) {

        super();

        this.itemsPerPageLabel = this._transloco.translate('itemsPerPage');

    }
}
