import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseLoadingService } from '@fuse/services/loading';
import { ChatMessage } from 'app/modules/admin/apps/chat/chat.types';
import { FilesHelperService } from 'app/modules/admin/apps/tasks/files-helper.service';
import { AvatarImageComponent } from 'app/modules/share/components/avatar/avatar-image.component';
import { ImageReviewPopupComponent } from 'app/modules/share/components/popup/image-review-popup/image-review-popup.component';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-chat-message-list',
    standalone: true,
    imports: [
        CommonModule,

        MatButtonModule,
        MatIconModule,

        AvatarImageComponent,
        ImageReviewPopupComponent,
    ],
    templateUrl: './chat-message-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageListComponent
{
    @Input() messages: ChatMessage[];
    @Input() canEditLast: boolean;
    @Input() lastEditDisabled: boolean;
    @Output() onMessageEdit = new EventEmitter<ChatMessage>();
    @Output() onMessageDelete = new EventEmitter<void>();

    loading$: Observable<boolean>;

    constructor(
        private _fuseLoading: FuseLoadingService,
        private _fileHelper: FilesHelperService,
    )
    {
        this.loading$ = this._fuseLoading.show$;
    }

    isImage(fileType: string): boolean
    {
        return fileType && fileType.split('/')[0] === 'image';
    }

    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    download(fileUrl: string, fileName: string)
    {
        this._fileHelper.download(fileUrl, fileName);
    }
}
