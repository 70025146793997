import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { MIME_TYPES } from '../../../share/mime-types';
import { resolveFileUrl } from './attachment-resolver';
import { FuseLoadingService } from '@fuse/services/loading';

@Injectable({
  providedIn: 'root'
})
export class FilesHelperService
{
    constructor(
        private _loading: FuseLoadingService,
        private _http: HttpClient,
    ) {}

    async openFileInNewTab(filePath: string)
    {
        let fileUrl = resolveFileUrl(filePath);
        let type = this._resolveBlobType(filePath);

        if(!!type)
        {
            const blob = await firstValueFrom(this._http.get(fileUrl, { responseType: 'blob' }));
            const pdfBlob = new Blob([blob], { type });
            fileUrl = window.URL.createObjectURL(pdfBlob);
        }

        window.open(fileUrl, '_blank');
    }

    async download(fileUrl: string, fileName: string)
{
        this._loading.show();

        const outsideRes = await fetch(fileUrl);
        const blob = await outsideRes.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();

        this._loading.hide();
    }

    private _isPdf(fileName: string): boolean
    {
        return this._getExtension(fileName) === 'pdf';
    }

    private _isText(fileName: string): boolean
    {
        return this._getExtension(fileName) === 'txt';
    }

    private _getExtension(filePath: string): string
    {
        return filePath.split('.').pop()?.toLowerCase();
    }

    private _resolveBlobType(filePath: string): string | null
    {
        const type = MIME_TYPES[this._getExtension(filePath)];
        return this._isPdf(filePath)
            ? type : this._isText(filePath) ? type + ';charset=utf-8' : null;
    }

}
