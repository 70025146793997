<div class="px-18">
    <div mat-dialog-title class="text-2xl">
        Політика конфіденційності
    </div>

    <mat-divider class="my-2"></mat-divider>

    <div mat-dialog-content class="text-black text-md">

        <div class="mb-3 ml-3">
            Malva CRM з повагою ставиться до конфіденційності персональних даних кожного Клієнта, який реєструється на сайті: <a href="http://malva.zpf.company/price/">http://malva.zpf.company/price/</a>. Будь ласка, уважно ознайомтеся з цією політикою конфіденційності.
        </div>
        <div class="mb-3 ml-3">
            Ця Політика конфіденційності регулює відносини між Malva CRM та будь-якою фізичною чи юридичною особою (далі – Клієнт) щодо обробки персональних даних під час використання сервісу Malva CRM. Політика поширюється на всі персональні дані, отримані від Клієнта під час користування сервісом. Якщо будь-яка умова цієї Політики не приймається, Клієнт не має права використовувати сервіс.
        </div>

        <ol>
            <li>
                Загальні положення
                <ol>
                    <li>
                        Політика конфіденційності розроблена на основі чинного законодавства України, зокрема Закону України «Про захист персональних даних».
                    </li>
                    <li>
                        Ця Політика діє відповідно до умов Ліцензійного договору (__посилання__)
                    </li>
                    <li>
                        Ознайомлення та згода з Політикою конфіденційності є обов'язковою для отримання права на використання сервісу Malva CRM.
                    </li>
                    <li>
                        Реєстрація у Malva CRM підтверджує, що Клієнт ознайомлений з усіма документами, згаданими в Політиці, та надає згоду на обробку своїх персональних даних.
                    </li>
                    <li>
                        Клієнт несе відповідальність за поширення даних для доступу до сервісу, внесення персональних даних інших осіб та за зміст контенту, зокрема, відповідність його вимогам чинного законодавства.
                    </li>
                    <li>
                        Під час реєстрації автоматично створюється обліковий запис, який містить персональні дані. Клієнт є відповідальним за достовірність, повноту та актуальність цих персональних даних.
                    </li>
                    <li>
                        Malva CRM не несе відповідальності за надійність пароля та наслідки його передачі третім особам, за його втрату або умисне чи випадкове розголошення, розкриття навмисно чи випадково. Malva CRM може встановлювати вимоги щодо формування пароля, шифрувати паролі та використовувати програми з управління паролем.
                    </li>
                    <li>
                        У випадку виявлення неповної або неправдивої інформації, Malva CRM має право заблокувати або видалити обліковий запис Клієнта.
                    </li>
                </ol>
            </li>
            <li>
                Типи інформації та мета її обробки
                <ol>
                    <li>
                        Інформація, яку обробляє Malva CRM, може включати:
                        <ul class="list-disc pl-4">
                            <li>
                                Персональні дані (ім'я, адреса, електронна пошта, телефон, інформацію про банківські рахунки, IP-адреса, тип браузера, інтереси, дати та час входу/виходу, операційна система);
                            </li>
                            <li>
                                Інформацію, завантажену з третіх сервісів під час інтеграцій;
                            </li>
                            <li>
                                Контент, завантажений користувачем;
                            </li>
                            <li>
                                Інформацію з інших офіційних джерел.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Клієнт гарантує, що отримав необхідні дозволи на розміщення персональної інформації третіх осіб.
                    </li>
                    <li>
                        Malva CRM використовує файли cookie відповідно до Політики використання файлів Cookie.
                    </li>
                    <li>
                        Обробка персональних даних може здійснюватися з метою надання послуг, вдосконалення сервісу, виконання маркетингових завдань, забезпечення технічної підтримки, врегулювання конфліктів та захисту прав користувачів.
                    </li>
                    <li>
                        Malva CRM не контролює достовірність наданої інформації, але має право вимагати підтвердження.
                    </li>
                    <li>
                        Malva CRM може інтегрувати сервіс із продуктами сторонніх постачальників та розміщувати посилання на інші веб-сайти.
                    </li>
                    <li>
                        Malva CRM не несе відповідальності за передачу Клієнтом токенів доступу до API третім особам.
                    </li>
                    <li>
                        Malva CRM має право публікувати особисті відгуки користувачів на своєму сайті.
                    </li>
                </ol>
            </li>
            <li>
                Передача персональних даних
                <ol>
                    <li>
                        Персональні дані можуть бути передані за згодою Клієнта, зокрема, для підключення сторонніх програмних продуктів.
                    </li>
                    <li>
                        Персональні дані можуть бути використані для захисту прав власника або адміністратора сервісу або третіх осіб у випадках порушення їх прав або законних інтерсів.
                    </li>
                    <li>
                        Персональні дані можуть бути передані на вимогу судових, правоохоронних та інших державних органів у передбачених законом випадках
                    </li>
                </ol>
            </li>
            <li>
                Права Клієнтів
                <ol>
                    <li>
                        Клієнти мають право:
                        <ul class="list-disc pl-4">
                            <li>
                                На захист своїх персональних даних відповідно до чинного законодавства;
                            </li>
                            <li>
                                Редагувати свій обліковий запис;
                            </li>
                            <li>
                                Вимагати видалення своїх персональних даних при припиненні користування сервісом;
                            </li>
                            <li>
                                Завантажувати та вивантажувати контент у зручний для них час;
                            </li>
                            <li>
                                Залишати відгуки про роботу Malva CRM;
                            </li>
                            <li>
                                Вимагати не використовувати їхні персональні дані для маркетингу.
                            </li>
                        </ul>
                    </li>

                </ol>
            </li>
            <li>
                Безпека інформації
                <ol>
                    <li>
                        Malva CRM вживає необхідних заходів для захисту персональних даних від неправомірного доступу, знищення, зміни та інших неправомірних дій.
                    </li>
                </ol>
            </li>
            <li>
                Заключні положення
                <ol>
                    <li>
                        Політика конфіденційності може бути змінена в односторонньому порядку шляхом розміщення нової редакції на сайті Malva CRM.
                    </li>
                    <li>
                        Клієнтам рекомендується періодично перевіряти зміни в Політиці конфіденційності.
                    </li>
                    <li>
                        Користування сервісом регламентується Ліцензійним договором.
                    </li>
                    <li>
                        У разі розбіжностей між цією Політикою та Ліцензійним договором, перевага надається Ліцензійному договору.
                    </li>
                </ol>
            </li>
        </ol>

    </div>

    <mat-divider class="my-2"></mat-divider>

    <div mat-dialog-actions class="flex justify-end">
        <button mat-raised-button mat-dialog-close>
            {{ 'close' | transloco}}
        </button>
    </div>

</div>
