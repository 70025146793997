import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { IntegrationSubType } from '../../integrations.types';
import { BaseMessengerDialogComponent } from '../base-messenger-dialog/base-messenger-dialog.component';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';

@Component({
    selector: 'app-telegram-dialog',
    standalone: true,
    imports: [
        CommonModule, TranslocoModule, DialogTemplateComponent, FormsModule, ReactiveFormsModule,
        MatFormFieldModule, MatInputModule, MatIconModule,
    ],
    templateUrl: './telegram-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelegramDialogComponent extends BaseMessengerDialogComponent<TelegramDialogComponent>
{
    get integrationSubType(): IntegrationSubType
    {
        return IntegrationSubType.telegram;
    }

    constructor()
    {
        super();

        this.form = this._formBuilder.group({
            name      : [this.data.name, this.withRequiredValidator(), this.nameUniqValidator.bind(this)],
            apiKey    : [this.data.apiKey, this.withRequiredValidator()],
        });

    }

}
