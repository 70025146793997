<button
    mat-stroked-button
    class="p-4 min-w-56 h-full flex justify-center items-center"
    (click)="toggleWorkProgress()"
    [disabled]="$loading | async"
>
    <div class="flex justify-between items-center mt-3 gap-3">
        <mat-icon
            [ngClass]="{
                'text-green-600': !(inProgress$ | async),
                'text-red-600': !!(inProgress$ | async)
            }"
            class="icon-size-8 "
            [svgIcon]="'heroicons_outline:' + ((inProgress$ | async) ? 'stop-circle' : 'play-circle')"
        ></mat-icon>

        <span>
            {{ 'workTime.' + ((inProgress$ | async) ? 'working' : 'start' ) | transloco }}
        </span>

        <span class="text-2xl">
            {{ (timeStr$ | async) | date : 'HH:mm' }}
        </span>
    </div>
</button>
