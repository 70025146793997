import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
    selector: 'app-base-picker',
    standalone: true,
    imports: [],
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class BasePickerComponent
{
    @Input() connectedTo: ElementRef;
    private _panelOverlayRef: OverlayRef;
    private _templatePortal: TemplatePortal;
    protected _overlay = inject(Overlay);
    protected _viewContainerRef = inject(ViewContainerRef);

    constructor(
    ) {}

    abstract get pickerPanelRef(): TemplateRef<any>;

    get focusOnInput(): boolean
    {
        return false;
    }

    open(): void
    {
        // Create the overlay
        this._panelOverlayRef = this._overlay.create({
            backdropClass   : '',
            hasBackdrop     : true,
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this.connectedTo)
                .withFlexibleDimensions(true)
                .withViewportMargin(64)
                .withLockedPosition(true)
                .withPositions([
                    {
                        originX : 'start',
                        originY : 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                ]),
        });
        // Subscribe to the attachments observable
        this._panelOverlayRef.attachments().subscribe(() =>
        {
            // Focus to the search input once the overlay has been attached
            if(this.focusOnInput)
            {
                this._panelOverlayRef.overlayElement.querySelector('input').focus();
            }
        });
        // Create a portal from the template
        this._templatePortal = new TemplatePortal(this.pickerPanelRef, this._viewContainerRef);

        // Attach the portal to the overlay
        this._panelOverlayRef.attach(this._templatePortal);
        // Subscribe to the backdrop click
        this._panelOverlayRef.backdropClick().subscribe(() =>
        {
            this.close();
        });
    }

    close()
    {
        // If overlay exists and attached...
        if ( this._panelOverlayRef && this._panelOverlayRef.hasAttached() )
        {
            // Detach it
            this._panelOverlayRef.detach();
        }

        // If template portal exists and attached...
        if ( this._templatePortal && this._templatePortal.isAttached )
        {
            // Detach it
            this._templatePortal.detach();
        }
    }
}
