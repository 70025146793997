import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/core/user/user.service';
import { Chat, ChatMessage, ChatType, IChatService, Profile } from 'app/modules/admin/apps/chat/chat.types';
import { EmployeeBriefly } from 'app/modules/share/global.types';
import { EmployeesBrieflyService } from 'app/modules/share/services/employees-briefly.service';
import { HttpClientService } from 'app/modules/share/services/http-client.service';
import { Dictionary, keyBy, mapValues } from 'lodash';
import { BehaviorSubject, Observable, firstValueFrom, map, of, switchMap, take, tap, throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ChatDetailsDialogComponent } from './chat-details-dialog/chat-details-dialog.component';

@Injectable({providedIn: 'root'})
export class ChatService implements IChatService
{
    private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
    private _chats: BehaviorSubject<Chat[]> = new BehaviorSubject([]);
    private _contact: BehaviorSubject<EmployeeBriefly> = new BehaviorSubject({});
    private _contacts: BehaviorSubject<EmployeeBriefly[]> = new BehaviorSubject([]);
    private _contactsIdMap: BehaviorSubject<Dictionary<EmployeeBriefly>> = new BehaviorSubject({});
    private _profile: BehaviorSubject<Profile> = new BehaviorSubject({});

    /**
     * Constructor
     */
    constructor(
        private _http: HttpClientService,
        private _employeesService: EmployeesBrieflyService,
        private _userService: UserService,
        private _dialog: MatDialog,
    )
    {
         this._employeesService.employees$
            .subscribe(contacts =>
            {
                this._contacts.next(contacts);
                this._contactsIdMap.next(
                    mapValues(keyBy(contacts, 'id'))
                );
            });

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    get chat(): Chat
    {
        return this._chat.getValue();
    }

    set chat(chat: Chat)
    {
        this._chat.next(chat);
    }

    /**
     * Getter for chat
     */
    get chat$(): Observable<Chat>
    {
        return this._chat.asObservable();
    }

    /**
     * Getter for chats
     */
    get chats$(): Observable<Chat[]>
    {
        return this._chats.asObservable();
    }

    get chats(): Chat[]
    {
        return this._chats.getValue();
    }
    set chats(chats: Chat[])
    {
        this._chats.next(chats);
    }

    /**
     * Getter for contact
     */
    get contact$(): Observable<EmployeeBriefly>
    {
        return this._contact.asObservable();
    }

    /**
     * Getter for contacts
     */
    get contacts(): EmployeeBriefly[]
    {
        return this._contacts.getValue();
    }

    get contacts$(): Observable<EmployeeBriefly[]>
    {
        return this._contacts.asObservable();
    }

    /**
     * Getter for profile
     */
    get profile$(): Observable<Profile>
    {
        return this._profile.asObservable();
    }

    refreshChat(): void
    {
        this._chat.next(this._chat.getValue());
    }

    refreshChats(): void
    {
        this._chats.next(this._chats.getValue());
    }

    sortChats(): void
    {
        this._chats.getValue().sort(this._chatsComparator);
        this.refreshChats();
    }

    getChats(): Observable<Chat[]>
    {
        return this._http.get<Chat[]>('/api/getChats',
        {
            login: this._userService.login
        }
        ).pipe(
            map(chats => chats.map(chat =>
            {
                chat.contact = {};
                chat.lastMessage = this._processMessage(chat.lastMessage)

                if([ChatType.single, ChatType.messenger].includes(chat.type))
                {
                    chat.contact = chat.contacts[0] || {};
                    chat.name = chat.contact.fullName || '';
                }
                return chat;
            })),
            tap(chats => {
                chats.sort(this._chatsComparator);
                this._chats.next(chats);
            }),
        );
    }

    getProfile(): Observable<any>
    {
        return this._http.get<Profile>('/api/getProfile',
            {
                login: localStorage.getItem('email')
            })
            .pipe(
                tap((response: Profile) => this._profile.next(response))
        );
    }

    generateNewChat(options: Chat = {})
    {
        return {
            id:  uuidv4(),
            isNew: true,
            muted: false,
            //lastMessageAt: (new Date()).toISOString(),
            ...options
       }
    }

    /**
     * New Chat
     */
    createChat(contacts: EmployeeBriefly[], type = ChatType.single, name?: string) : Observable<Chat>
    {
        const contact = contacts ? contacts[0] : null;
        const newChat =
            type === ChatType.single
                ? this.generateNewChat({
                    type,
                    contact
                })
                : this.generateNewChat({
                    type,
                    name,
                    contacts,
                });

        return this._http.post('/api/newChat', newChat)
            .pipe(
                switchMap(() => of(newChat))
            );
    }

    updateChat(chat: Chat)
    {
        return this._http.post('/api/changeChat', chat)
            .pipe(take(1), tap(_ =>
                {
                    this._chat.next(chat);
                    this.refreshChats();
                }
            ));
    }

    removeChat(chatId: string)
    {
        return this._http.post('/api/ChatRemove', {
            chatId,
            contactId: this._userService.user.id
        });
    }

    /**Messages
     *
     * Message service
     */
    sendMessage(chat: Chat, message: any = '', messageImage?: ArrayBufferLike): Observable<ChatMessage[]>
    {
        return this._http.post<ChatMessage[]>('/api/sendMessage',
        {
            login: this._userService.login,
            id: chat.id,
            chatId: chat.id,
            chatType: chat.type,
            message,
            messageImage
        })
        .pipe(
            map(messages =>
                messages.map(message =>
                ({
                    ...message,
                    value: this._processMessage(message.value),
                    contact: message.contact || this._contactsIdMap.getValue()[message.contactId]
                })
            )),

        );
    }

    updateMessage(id: string, message: string = ''): Observable<ChatMessage[]>
    {
        return this._http.post<ChatMessage[]>('/api/updateMessage', {
                id,
                login: this._userService.login,
                chatId: this.chat.id,
                message,
            })
            .pipe(
                //tap(m => console.log('m', m)),
                //map(messages => this._mapMessages(messages)),
                switchMap(messages => of(messages))
        );
    }

    removeMessage(id: string): Observable<ChatMessage[]>
    {
        return this._http.post<ChatMessage[]>('/api/removeMessage', {
            id,
            chatId: this.chat.id
        }).pipe(
            //map(messages => this._mapMessages(messages)),
            switchMap(messages => of(messages))
            //tap(messages => this._chatWsService.updateChatMessages(this, messages))
        );
    }

    getMessages(chatId: string): Observable<ChatMessage[]>
    {
        return this._http.get<ChatMessage[]>('/api/getMessages',
        {
            login: this._userService.login,
            id: chatId,
        }).pipe(
            map(messages => this._mapMessages(messages)),
            switchMap(messages => of(messages))
        );
    }
/*
                let idx = newMessages.length - 1;
                for (; idx >= 0; idx--) {
                    if (newMessages[idx].isMine) {
                        newMessages[idx].isLastMine = true;
                        break;
                    }
                }
                return newMessages;
 */

    setMessagesRead(chatId: string): Observable<ChatMessage[]>
    {
        return this._http.post<ChatMessage[]>('/api/setMessagesRead',
        {
            id: chatId
        });
    }

    /**
     * Get chat
     *
     * @param id
     */
    getChatById(id: string): Observable<any>
    {
        const chat = this._chats.getValue().find(chat => chat.id === id);

        if ( !chat )
        {
            return throwError('Could not found chat with id of ' + id + '!');
        }
        else {
            this._chat.next(chat);
        }
        return of(chat);
    }

    /**
     * Reset the selected chat
     */
    resetChat(): void
    {
        this._chat.next(null);
    }

    openChatDetailsDialog(chat?: Chat)
    {
        const dialogRef = this._dialog.open(ChatDetailsDialogComponent, {
            data: {
                chat: chat || {
                    ...this.generateNewChat(),
                    type: ChatType.group
                }
            },
            panelClass: [
                'w-full',
                'sm:w-1/2',
            ]
        });

        dialogRef.afterClosed().subscribe(async (result: { chat: Chat }) =>
        {
            if(result && result.chat)
            {
                const chat = result.chat;
                if(chat.isNew)
                {
                    await firstValueFrom(this.createChat(chat.contacts, chat.type, chat.name));
                    await firstValueFrom(this.getChats());
                }
                else
                {
                    await firstValueFrom(this.updateChat(chat));
                }
            }
        });
    }

/*     setCharMessages(messages: ChatMessage)
    {
        const chats = this.chats;
        const idx = chats.findIndex(itm => itm.id === chat.id);

        this.unreadCount = this._unreadCount.getValue() - chat.unreadCount;

        chats[idx] = {
            ...this._mergeChat(chats[idx], chat),
            messages,
            unreadCount: 0,
        };
        this._chatService.chats = chats;
        chatService.chat = chats[idx];
    }
 */
    private _processMessage(pMessage: string): string
    {
        const contactsIdMap = this._contactsIdMap.getValue();
        let message = pMessage || '';

        message = message.replace(/\[USER\](\d+)\[\/USER\]/g, (val, id) =>
            '<b class="mentionedUser">' + contactsIdMap[id].fullName + '</b>'
        )

        return message;
    }

    private _mapMessages(messages: ChatMessage[]): ChatMessage[]
    {
        return messages?.map(message =>
            ({
                ...message,
                value: this._processMessage(message.value),
                contact: message.contact || this._contactsIdMap.getValue()[message.contactId]
            })
        );
    }

    private _chatsComparator(a: Chat, b: Chat): number
    {
        //console.log(a.lastMessageAt, b.lastMessageAt, a.lastMessageAt > b.lastMessageAt ? 1 : -1);
        return a.lastMessageAt > b.lastMessageAt || !b.lastMessageAt ? -1 : 1;
    }

}
