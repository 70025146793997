import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ADMIN_ROLE, User } from 'app/core/user/user.types';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { Router } from "@angular/router";
import { environment } from '../../../../environments/environment';
import { HttpClientService } from 'app/modules/share/services/http-client.service';

@Injectable({providedIn: 'root'})
export class UserService
{
    private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    private readonly _adminsNavMenuItemIds = { 'apps.fordev': true };

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: HttpClientService,
        private _router: Router)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(user: User)
    {
        localStorage.setItem('id', user.id);
        localStorage.setItem('name', user.name);
        localStorage.setItem('first_name', user.first_name);
        localStorage.setItem('email', user.email);
        localStorage.setItem('avatar', user.avatar);
        localStorage.setItem('status', user.status);
        localStorage.setItem('userRole', user.userRole);
        this._user.next(user);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    get login(): string
    {
        return this._user.getValue().email;
    }

    get user(): User
    {
        return this._user.getValue();
    }

    get isAdmin(): boolean
    {
        return this.user.userRole === ADMIN_ROLE;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User>
    {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) =>
            {
                this._user.next(user);
            }),
        );
    }

    isMenuItemAvailable(menuItemId: string): boolean
    {
        return this.isAdmin || !this._adminsNavMenuItemIds[menuItemId];
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>('api/common/user', { user })
            .pipe(
                tap((response) =>
                {
                    this._user.next(response);
                }),
            );
    }

    getUser(login: string): Observable<User>
    {
        return this._http.get<User>('/api/getUser', { login })
            .pipe(
                tap((response) =>
                {
                    this._user.next(response);
                }),
            );
    ;
    }

    restoreUser()
    {
        // Store the value
        if(!localStorage.getItem('id'))  {
            // Store the user on the user service
            const user = {
                id: localStorage.getItem('id'),
                name: localStorage.getItem('name'),
                email: localStorage.getItem('email'),
                avatar: localStorage.getItem('avatar'),
                status:  localStorage.getItem('status'),
                first_name: localStorage.getItem('first_name'),
                userRole: localStorage.getItem('userRole'),
            };
            this._user.next(user);
        }
        else
        {
            this._router.navigate(['/sign-in']);
        }
    }

}
